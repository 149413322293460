<template>
  <div
    v-if="loading"
    class="loading"
  >
    <div class="lds-dual-ring" />
  </div>
</template>
<script>
export default {
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
	},
};
</script>
<style lang="scss">
.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.lds-dual-ring:before {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #4f7d15 transparent #78be20 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring:after {
      content: 'Loading';
      font-weight: bold;
}

@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
</style>
