<template>
  <div class="ap__section">
    <div class="ap__title">
      SSL Cloudflare
    </div>
    <div class="ap__item">
      <v-tabs color="white">
        <v-tab
          v-for="tab in tabs"
          :key="tab.label"
          ripple
          @click="changeActiveTab(tab)"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <div class="ap__tab__content">
        <keep-alive>
          <component :is="activeTab" />
        </keep-alive>
      </div>
    </div>

    <div
      class="ap__item"
      style="position: relative;"
    >
      <ap-loading :loading="table.loading" />
      <v-card>
        <div class="ap__nav">
          <div>Last Updated By Cron: <strong>{{ lastUpdated }}</strong></div>
          <div>Total Domains: <strong>{{ newSslDomains.length }}</strong></div>
          <ap-response :json="output" />
        </div>
        <div />
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
          <ap-button
            :disabled="table.loading"
            value="Refresh"
            @clicked="refresh"
          />
          <ap-button
            v-if="table.filter.group === 'pending'"
            :disabled="table.loading"
            value="Update All"
            @clicked="cloudflare({method: 'get', url: '/cloudflare/trigger-cron'})"
          />
          <v-spacer />
          <label style="margin: 0 5px;">Group</label>
          <v-select
            v-model="table.filter.group"
            :options="cloudflareGroups"
            label="name"
          />
        </v-card-title>
        <v-data-table
          :headers="domainHeaders"
          :items="filtered"
          :search="search"
        >
          <template
            v-slot:body="{ items }"
          >
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.name"
              >
                <td>
                    <v-icon small :color=getFinalDomainStatus(item) >fa fa-circle </v-icon>
                </td>
                <td>
					<div v-if="!item.beingEdited">
						{{ item.accountId || 'N/A' }} 
						<i class="fa fa-pencil-alt" @click="editField(item.name, 'beingEdited', true)"></i>
					</div>
					<div v-else>
						<input type="text" placeholder="New Account ID" class="form-control" v-model="newAccountId" />
						<button class="ap__button" @click="updateDomain(item.name)">
							<i class="fa fa-check"></i>
						</button>
						<button class="ap__button red" @click="editField(item.name, 'beingEdited', false)">
							<i class="fa fa-times"></i>
						</button>

					</div>
				</td>
                <td>{{ item.name }}</td>
                <td>{{ getNetworkName( item ) }}</td>
                <td>{{ getName('apexSSL', item) }}</td>
                <td>{{ getValue('apexSSL', item) }}</td>
                <td :class="getStatusClass( 'apexSSL', item) + '--text'">
                    {{ getStatus('apexSSL', item) }}
                </td>
                <td>{{ getName('wwwSSL', item) }}</td>
                <td>{{ getValue('wwwSSL', item) }}</td>
                <td :class="getStatusClass( 'wwwSSL', item) + '--text'">
                    {{ getStatus('wwwSSL', item) }}
                </td>
                <td>{{ getName('ownershipVerification', item) }}</td>
                <td>{{ getValue('ownershipVerification', item) }}</td>
                <td :class="getStatusClass( 'ownershipVerification', item) + '--text'">
                    {{ getStatus('ownershipVerification', item) }}
                </td>
                <td>{{ getCloudflareCustomOrigin(item) }}</td>
                <td>
                    <i
                        class="fa fa-minus-circle"
                        aria-hidden="true"
                        @click.prevent="deleteDomain('/cloudflare/delete-domain', item)"
                    />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div class="ap__table__footer">
          <ap-button
            :disabled="loading"
            value="Download CSV"
            @clicked="csv"
          />
        </div>
      </v-card>
      <div
        class="ap__notification"
        :class="updated"
      >
        {{ message }}
      </div>
    </div>
	<vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import format from 'date-fns/format';
import { mapGetters } from 'vuex';
import download from '../../js/mixins/download';
import Migrate from './sslCloudflare/Migrate.vue';
import Request from './sslCloudflare/Request.vue';

export default {
	components: {
		Migrate,
		Request
	},
	mixins: [download],
	data() {
		return {
			activeTab: 'Request',
			updated: '',
			message: '',
			newAccountId: null,
			loading: false,
			tabs: [{
				label: 'Request SSL',
				component: 'Request',
			}, {
				label: 'Change Network',
				component: 'Migrate',
			}],
			search: '',
			domainHeaders: [
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                },
                {
                    text: 'Account ID',
                    value: 'accountId',
                    align: 'center',
                },
                {
                    text: 'Domain',
                    value: 'name',
                    align: 'center',
                },
                {
                    text: 'Network',
                    value: 'network',
                    align: 'center',
                },
                {
                    text: 'apexSSL Name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'apexSSL Value',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Apex Status',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: 'wwwSSL Name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'wwwSSL Value',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'www Status',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: 'Validation Name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Validation Value',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Validation Status',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: 'Cloudflare',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Delete',
                    align: 'center',
                    sortable: false,
                },
			],
			output: {},
			status: {
				PENDING_VALIDATION: 'warning',
				SUCCESS: 'success',
				TIMED_OUT: 'error',
			},
			table: {
				loading: false,
				filter: {
					group: 'all'
				},
			},
            cloudflareGroups: [ 'all', 'pending' ]
		};
	},
	computed: {
		...mapGetters( {
			albs: 'allALBs',
			newSslDomains: 'newSslDomains',
			cloudflareLogs: 'cloudflareLogs',
		} ),
		lastUpdated() {
			if ( this.cloudflareLogs.length > 0 ) {
				return format( this.cloudflareLogs[this.cloudflareLogs.length - 1].created, 'MMM DD hh:mmA' );
			}
			return 'N/A';
		},
		filtered() {
			let filtered = [];

			// Filter domains.
			if ( this.table.filter.group === 'all' ) {
				filtered = this.newSslDomains;
			} else {
				filtered = this.newSslDomains.filter( ( d ) => {
                    if ( !['valid', 'success', 'active'].includes(d.finalSslStatus) || !['valid', 'success', 'active'].includes(d.finalOwnershipStatus) ) {
                        return true;
                    }

                    return false;
                    
                } );
			}
			
            return filtered;
		}
	},
	methods: {
		csv() {
			if ( this.filtered.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			this.updated = '';
			this.message = '';
			this.loading = true;
			const results = this.filtered.map( ( f ) => ( {
				status: ( f.finalSslStatus == 'active' && f.finalOwnershipStatus == 'active' ) ? 'Active' : 'Pending' ,
				accountId: f.accountId || 'N/A',
				domain: f.name || 'N/A',
				network: f.network.network || 'N/A',
				apexName: f.apexSSL.name || 'N/A',
				apexValue: f.apexSSL.value || 'N/A',
				apexStatus: f.apexSSL.status || 'N/A',
				wwwName: f.wwwSSL.name || 'N/A',
				wwwValue: f.wwwSSL.value || 'N/A',
				wwwStatus: f.wwwSSL.status || 'N/A',
                ownershipVerificationName: f.ownershipVerification.name || 'N/A',
				ownershipVerificationValue: f.ownershipVerification.value || 'N/A',
				ownershipVerificationStatus: f.ownershipVerification.status || 'N/A',
				cloudflare: f.cloudflareCustomOrigin || 'N/A',
			} ) );
			const headers = this.domainHeaders.map( ( h ) => h.text ).slice( 0, -1 );
			const response = this.download( results, headers, 'SSL' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
		deleteDomain(url, item) {
			this.$confirm(
				{
				message: `Do you really want to delete ${(item.name || 'N/A')}?`,
				button: {
					no: 'No',
					yes: 'Yes'
				},
				/**
				 * Callback Function
				 * @param {Boolean} confirm 
				 */
				callback: confirm => {
					if (confirm) {
						this.cloudflare({url: url, method: 'delete', data: {domain: item}})
					}
				}
				}
			);
		},
		async refresh() {
			this.table.loading = true;
			this.output = {};
			const response = await this.$store.dispatch( 'updateNewDomains' );
			if ( response.pass === false ) {
				this.output = response.data;
			} else {
				this.output = 'Successfully updated domains table.';
			}
			this.table.loading = false;
		},

		async editField(domain_name, fieldName, fieldValue) {
			this.newAccountId = null;
			let required_index = this.filtered.findIndex(domain => domain.name === domain_name);
			this.$set(this.filtered[required_index], fieldName, fieldValue);
		},
	
		async updateDomain(domain_name) {
			this.table.loading = true;
			this.output = {};
			let newAccountId = this.newAccountId;
			
			let required_index = this.filtered.findIndex(domain => domain.name === domain_name);
			let updated_domain = this.filtered[required_index];

			const response = await axios( {
				url: "/cloudflare/update-domain",
				method: 'POST',
				data: {
					name : updated_domain.name,
					accountId : newAccountId,
					_id : updated_domain._id
				}
			} );

			if ( response.pass === true ) {
				this.$set(this.filtered[required_index], "accountId", newAccountId);
				this.$set(this.filtered[required_index], "beingEdited", false);
				this.newAccountId = null;
			}

			this.table.loading = false;
			this.output = response.data;
		},

		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
		getNetworkName( domain ) {
			if ( !( 'network' in domain ) || !( 'network' in domain.network ) ) {
				return 'N/A';
			}

            return domain.network.network.split( '-' )[0];
		},
		getName( type, domain ) {
			if ( !( type in domain ) ) {
				return 'N/A';
			}

			return domain[type].name;
		},
		getValue( type, domain ) {
			if ( !( type in domain ) ) {
				return 'N/A';
			}

			return domain[type].value;
		},
		getCloudflareCustomOrigin( domain ) {
			if ( !( `cloudflareCustomOrigin` in domain ) ) {
				return 'N/A';
			}

			return domain[`cloudflareCustomOrigin`];
		},
		getStatus( type, domain ) {
			if ( !( type in domain ) ) {
				return 'N/A';
			}

			// us-east-1 value.
			if ( domain[type].status != undefined ) {
				return domain[type].status;
			}

			return 'N/A';
		},
		getStatusClass( type, domain ) {
			
            if ( !( type in domain ) ) {
				return 'error';
			}
            
            if ( domain[type].status === 'valid' || domain[type].status === 'success' || domain[type].status === 'active' ) {
				return 'success';
			}
            
            if ( domain[type].status === 'pending' ) {
				return 'warning';
			}

			return 'error';
		},
		getFinalDomainStatus( domain ) {
			
            if ( ['valid', 'success', 'active'].includes(domain.finalSslStatus) && ['valid', 'success', 'active'].includes(domain.finalOwnershipStatus) ) {
				return 'success';
			}
            
            if ( ['pending', 'pending_validation'].includes(domain.finalSslStatus) || ['pending', 'pending_validation'].includes(domain.finalOwnershipStatus) ) {
				return 'warning';
			}

			return 'error';
		},
		async cloudflare( config ) {
			this.output = {};
			this.table.loading = true;
			try {
				const response = await axios( config );
				await this.$store.dispatch( 'updateNewDomains' );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.table.loading = false;
		}
	},
};
</script>
<style lang="scss" scoped>
	.ap__nav {
		padding: 15px;
		padding-bottom: 0;
	}

	.ap__button.red
	{
		background : #bb2222;
	}

	.last-updated,
	.total-filtered {
		font-size: 14px;
	}

	.nav-select {
		display: flex;
		align-items: center;

		label {
			padding-right: 15px;
		}
	}

	.unavailable {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #bebebe;
	}

	table {
		border-collapse: collapse;
		background: #f5f5f5;
		width: 100%;

		thead {
			background: #e4e4e4;
			box-shadow: 1px 1px 1px #bebebe;
		}

		th {
			padding: 0 10px;
			text-align: center;
		}

		td {
			position: relative;
			max-width: 200px;
			overflow-wrap: break-word;
			font-size: 14px;
			padding: 5px 10px;
			text-align: center;

			i {
				transition: all 0.2s;
				cursor: pointer;

				&.fa-paper-plane:hover,
				&.fa-database:hover {
					color: #78be20;
				}

				&.fa-minus-circle:hover {
					color: "red";
				}
			}

			&:last-child {
				max-width: 100px;
			}
		}
	}
</style>
