<template>
  <transition name="fade">
    <div
      class="ap-modal"
      @click="$emit('close')"
    >
      <div class="ap-modal__message">
        <slot name="message" />
      </div>
      <div class="ap-modal__close">
        <i class="fas fa-times-circle" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.ap-modal {
	position: fixed;
	top: 0;
	width: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	width: 100%;
	display: flex;
    justify-content: center;
	align-items: center;

	&__message {
		background-color: white;
		padding: 16px;
		border-radius: 3px;
		border-top: 3px solid #78be20;
	}

	&__close {
		position: absolute;
		top: 50px;
		right: 50px;
		font-size: 2rem;
		color: white;
		cursor: pointer;
		transition: color 0.3s ease-in;

		&:hover {
			color: #e3e3e3;
		}
	}
}
</style>
