<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Query Customizer in Networks
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        value="Clear All Networks"
        @clicked="query.networks = []"
      />
      <ap-button
        value="Select All Networks"
        @clicked="query.networks = albs"
      />
      <ap-button
        value="Select Prod Networks"
        @clicked="generateList('prod')"
      />
      <ap-button
        value="Select Dev Networks"
        @clicked="generateList('dev')"
      />
      <ap-button
        :disabled="query.disabled"
        value="Query"
        @clicked="queryNetworks"
      />
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="query.networks"
          :options="albs"
          label="network"
          multiple
        />
        <div class="ap__input__desc">
          Which network(s) to query.
        </div>
      </div>

      <div class="ap__input__option">
        <label>Option</label>
        <v-select
          v-model="query.options"
          label="label"
          :options="customizerOptions"
          taggable
          multiple
        />
        <div class="ap__input__desc">
          Options to query.
        </div>
      </div>

      <div class="ap__input">
        <label>Customizer Param</label>
        <v-select
          v-model="query.params"
          label="label"
          :options="customizerParams"
          taggable
          multiple
        />
        <div class="ap__input__desc">
          Customizer setting(s) to query.
        </div>
      </div>
    </div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="results"
        :search="search"
      >
        <template v-slot:item.account="{ item }">
          <a
            :href="'http://' + item.account"
            target="_blank"
          >
            {{ item.account }}
          </a>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Download CSV"
          @clicked="csv"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    />{{ message }}
  </div>
</template>
<script>
import axios from 'axios';
import { io } from 'socket.io-client';
import { mapGetters } from 'vuex';
import download from '../../../js/mixins/download';

export default {
	mixins: [download],
	data() {
		return {
			loading: false,
			search: '',
			message: '',
			updated: '',
			customizerOptions: [],
			headers: [],
			results: [],
			query: {
				disabled: false,
				networks: [],
				options: [],
				output: {},
			},
			notification: '',
			socket: null,
		};
	},
	computed: mapGetters( {
		albs: 'allALBs',
		possibleOptions: 'allOptions',
		customizerParams: 'allCustomizerParams',
	} ),
	async mounted() {
		if ( this.albs.length !== 0 ) {
			this.query.networks = [this.albs[0]];
		}
		this.grabCustomizerSettings();
		this.connectToSocket();
	},
	methods: {
		grabCustomizerSettings() {
			this.possibleOptions.forEach( ( option ) => {
				if ( option.label.includes( 'Customizer' ) ) {
					this.customizerOptions.push( option );
				}
			} );
		},
		async generateList( type ) {
			this.message = '';
			this.updated = '';
			const list = [];
			this.albs.forEach( ( alb ) => {
				switch ( type ) {
				case 'dev':
					if ( alb.network.includes( 'dev' ) || alb.network.includes( 'staging' ) || alb.network.includes( 'ninja' ) ) {
						list.push( alb );
					}
					break;
				default:
					if ( !alb.network.includes( 'dev' ) && !alb.network.includes( 'staging' ) && !alb.network.includes( 'ninja' ) ) {
						list.push( alb );
					}
				}
			} );
			this.query.networks = list;
		},
		async queryNetworks() {
			this.updated = '';
			this.message = '';
			this.headers = [];
			this.results = [];
			const message = [];
			const networks = [];

			for ( const network of this.query.networks ) {
				if ( network.auth.key === '' ) {
					this.updated = 'error';
					message.push( `Missing api key in db for ${network.domain}. Removed from list of queried networks.` );
					continue;
				}
				if ( network.auth.basic === '' ) {
					this.updated = 'error';
					message.push( `Missing basic header in db for ${network.domain}. Removed from list of queried networks.` );
					continue;
				}
				networks.push( network );
			}

			if ( message.length > 0 ) {
				this.message = message.join( '\n' );
			}

			this.query.networks = networks;
			if ( this.query.networks.length === 0 ) {
				return;
			}

			// Only allow theme mods to be queried
			this.query.options = this.query.options.filter( ( opt ) => ( ( 'value' in opt && opt.value.includes( 'theme_mods' ) ) || opt.label.includes( 'theme_mods' ) ) );

			if ( this.query.options.length < 1 ) {
				this.updated = 'error';
				this.message = 'Please enter at least one option.';
				return;
			}
			if ( this.query.params.length < 1 ) {
				this.updated = 'error';
				this.message = 'Please enter at least one customizer parameter.';
				return;
			}
			try {
				this.loading = true;

				const options = this.query.options.map( ( opt ) => ( 'value' in opt ? opt.value : opt.label ) ).join( ',' );
				this.socket.emit( 'options-networks-list', { networks: this.query.networks, options } );
			} catch ( e ) {
				this.updated = 'error';
				this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
			}
		},
		csv() {
			if ( this.results.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			this.updated = '';
			this.message = '';
			this.loading = true;
			const response = this.download( this.results, this.headers.map( ( h ) => h.text ), 'Query' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
		updateTable( data ) {
			const results = [];
			const headers = [{ text: 'Account', value: 'account' }];
			const queryParams = this.query.params.map( ( param ) => ( 'value' in param ? param.value : param.label ) );
			data = Object.entries( data );

			// Each site
			data.forEach( ( e ) => {
				// Each option
				Object.entries( e[1] ).forEach( ( en ) => {
					// Each customizer param
					Object.entries( en[1] ).forEach( ( param ) => {
						if ( queryParams.includes( param[0] ) ) {
							e[1][param[0]] = param[1];
						}
					} );
				} );

				// Remove theme_mods object
				Object.keys( e[1] ).forEach( ( key ) => {
					if ( key.includes( 'theme_mods' ) ) {
						delete e[1][key];
					}
				} );

				// Fill undefined params with empty values (column breaks otherwise)
				queryParams.forEach( ( p ) => {
					if ( e[1][p] === undefined ) {
						e[1][p] = 'undefined';
					}
				} );

				// Push result to table
				results.push( {
					account: e[0],
					...e[1],
				} );
			} );
			Object.keys( data[0][1] ).forEach( ( h ) => headers.push( { text: h, value: h } ) );
			this.headers = headers;
			this.results = results;
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'options-networks-list-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.data;
				} else {
					this.updateTable( response.data );
					if ( this.message === '' ) {
						this.updated = 'success';
						this.message = 'Successfully made query request.';
					}
				}
				this.loading = false;
			} );
		},
	},
};
</script>
