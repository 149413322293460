<template>
  <div style="position: relative;">
    <ap-loading :loading="loading" />
    <p>
      Please follow the instructions to switch site to a different network on <b>CloudFlare</b>.<br>
    </p>
    <ol>
      <li>Only sites that are fully active, will show here</li>
      <li>Select your desired site and network and then hit Switch Network button</li>
      <li>Portal will create new DNS and SSL entries on Cloudflare for selected domain and network</li>
      <li>You will see 2 entries for same site, one with existing network and other with new network</li>
      <li>Now update domain TXT records as per new Cloudflare values</li>
      <li>Once updated, portal cron will sync it and it will mark the new entry as green and remove previous entry from portal and also from Cloudflare</li>
    </ol>
    <div class="ap__input__wrap">
      <!-- <div class="ap__input">
                <label>Domain</label>
                <input type="text" v-model="domains">
                <div class="ap__input__desc">Domain to request ssl for.</div>
            </div> -->
      <div class="ap__input">
        <label>Domain</label>
        <v-select
          v-model="domain"
          :options="filteredDomains"
          label="name"
        />
        <div class="ap__input__desc">
          Which domains to transfer.
        </div>
      </div>

      <div class="ap__input">
        <label>Network</label>
        <v-select
          v-model="network"
          :options="networkOptions"
          label="network"
        />
        <div class="ap__input__desc">
          Which network is this domain transfering to.
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Switch Networks"
        @clicked="switchNetwork"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex'; 

export default {
	data() {
		return {
			loading: false,
			domain: {},
			output: {},
			network: {},
		};
	},
	computed: {
		...mapGetters( ['newSslDomains', 'prodALBs', 'stagingALBs' ] ),
        networkOptions() {
            return ( process.env.NODE_ENV === 'production' ) ? this.prodALBs : this.stagingALBs;
        },
		filteredDomains() {
			const domains = [];
			this.newSslDomains.forEach( ( d ) => {
				if ( d.finalSslStatus === 'active' && d.finalOwnershipStatus === 'active' ) {
					domains.push( d );
				}
			} );
			return domains;
		},
	},
	methods: {
		async switchNetwork() {

            this.output = {};

			if ( Object.keys( this.network ).length === 0 || !( 'network' in this.network ) ) {
				this.output = 'Please select a valid network';
				return;
			}

			if ( Object.keys( this.domain ).length === 0 ) {
				this.output = 'Please select a domain to transfer.';
				return;
			}

			// Check to see if domain is not in the same network as the switching one.
			if ( this.domain.network.network === this.network.network ) {
                this.output = `${this.domain.name} is already inside network ${this.network.network}`;
				return;
            }

			// Send request to transfer domains then output.
			this.loading = true;
			try {
				const response = await axios.post( '/cloudflare/migrate', { domain: this.domain, network: this.network } );
				this.output = response;
			} catch ( e ) {
				console.log( e );
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
	},
};
</script>
