<template>
  <div class="ap__section">
    <ap-loading :loading="loading" />
    <div class="ap__label">
      Mass Update Site Checks
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Clear All Networks"
        @clicked="selectedNetworks = []"
      />
      <ap-button
        :disabled="loading"
        value="Select All Networks"
        @clicked="selectedNetworks = networks"
      />
      <ap-button
        :disabled="loading"
        value="Refresh"
        @clicked="refresh"
      />
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="selectedNetworks"
          :options="networks"
          label="network"
          multiple
        />
        <div class="ap__input__desc">
          Which network(s) to update.
        </div>
      </div>
      <div class="ap__input">
        <label>Sites</label>
        <v-select
          v-model="selectedSites"
          :options="domains"
          label="name"
          multiple
        />
        <div class="ap__input__desc">
          Which site(s) to update.
        </div>
      </div>
      <div class="ap__input">
        <label>NodePing Profile</label>
        <v-select
          v-model="profile"
          :options="nodepings"
          label="name"
        />
        <div class="ap__input__desc">
          Which NodePing profile to change to.
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Update"
        @clicked="update"
      />
    </div>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			selectedNetworks: [],
			selectedSites: [],
			profile: {},
			loading: false,
			updated: '',
			message: '',
			output: {},
			headers: [],
		};
	},
	computed: {
		...mapGetters( {
			allDomains: 'sslDomains',
			nodepings: 'allNodePingProfiles',
		} ),
		domains() {
			return this.allDomains.filter( ( d ) => d.group !== 'pending' && d.npID !== undefined );
		},
		networks() {
			const networks = [];
			this.domains.forEach( ( domain ) => {
				if ( !networks.includes( domain.network ) ) {
					networks.push( domain.network );
				}
			} );

			return networks;
		},
		nodepingObj() {
			const obj = {};
			this.nodepings.forEach( ( np ) => {
				obj[np.name] = {
					interval: np.interval,
					threshold: np.threshold,
					sens: np.sens,
					follow: np.follow,
					enabled: np.enabled,
				};
			} );

			return obj;
		},
	},
	watch: {
		selectedNetworks() {
			this.populateSites();
		},
		selectedSites() {
			this.message = `${this.selectedSites.length} site${this.selectedSites.length === 1 ? '' : 's'} selected.`;
		},
	},
	mounted() {
		this.headers = [{
			text: 'Name',
			value: 'name',
		}, {
			text: 'Interval',
			value: 'interval',
		}, {
			text: 'Threshhold',
			value: 'threshold',
		}, {
			text: 'Sensitivity',
			value: 'sens',
		}, {
			text: 'Follow Redirects?',
			value: 'follow',
		}, {
			text: 'Enabled',
			value: 'enabled',
		}];
	},
	methods: {
		async refresh() {
			this.loading = true;
			await this.$store.dispatch( 'updateDomains' );
			await this.$store.dispatch( 'updateNodePingProfiles' );
			this.loading = false;
		},
		populateSites() {
			const selected = [];
			if ( this.selectedNetworks.length > 0 ) {
				this.domains.forEach( ( domain ) => {
					if ( this.selectedNetworks.includes( domain.network ) ) {
						selected.push( domain );
					}
				} );
				this.selectedSites = selected;
			} else {
				this.selectedSites = [];
			}
		},
		async update() {
			const domains = [];

			this.selectedSites.forEach( ( site ) => {
				domains.push( {
					name: site.name,
					npID: site.npID,
					npProfile: this.profile.name,
				} );
			} );

			this.output = await axios.post( '/node-ping/mass-update', { domains, profile: this.nodepingObj[this.profile.name] } );
		},
	},
};
</script>
<style lang="scss" scoped>
table {

	th {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}

	td {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}
}
</style>
