<template>
  <v-data-table
    :headers="headers"
    :items="activeItems"
    :search="search"
    class="v-data-table-bp"
    no-data-text="No data available"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="(item,index) in items"
          :key="index"
          :class="{'editable': !item.readonly}"
        >
          <td class="modeltd">
            <v-select
              v-model="item.model"
              :options="filteredModels( item.model )"
              :disabled="item.readonly"
              dense
            />
          </td>
          <td class="yeartd">
            <v-select
              v-model="item.year"
              :options="filteredYears( item.model, item.year )"
              label="Select Year"
              :disabled="item.readonly"
              multiple
              taggable
              sortable
            />
          </td>

          <td class="patterntd">
            <input
              v-model="item.pattern"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td>
            <input
              v-model="item.segment1"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td class="mappedmodeltd">
            <input
              v-model="item.mappedModel"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td>
            <input
              v-model="item.segment2"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td>
            <input
              v-model="item.dcIncentivesURL"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td class="dctypetd">
            <input
              v-model="item.dctype"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td>
            <input
              v-model="item.intcmp"
              type="text"
              :disabled="item.readonly"
            >
          </td>
          <td class="actiontd">
            <span>
              <i
                area="hidden"
                :class="['icon', item.readonly ? 'fa fa-pencil-alt' : 'fa fa-check']"
                title="Edit"
                @click="$emit('edit', item.id)"
              />
            </span>
            <span><i
              area="hidden"
              class="icon fa fa-minus-circle"
              title="Delete"
              @click="$emit('delete', item.id)"
            /></span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	props: {
		activeItems: {
			type: Array,
			required: true,
			default: () => [],
		},
		activeMake: {
			type: String,
			required: true,
		},
		search: {
			type: String,
			required: true,
		},
		makes: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			headers: [{
				text: 'Model *',
				value: 'model',
				align: 'center',
			},
			{
				text: 'Year *',
				value: 'year',
				align: 'center',
			},
			{
				text: 'Pattern *',
				value: 'pattern',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Segment1',
				value: 'segment1',
				align: 'center',
				sortable: false,
			},
			{
				text: 'mappedModel *',
				value: 'mappedModel',
				align: 'center',
			},
			{
				text: 'Segment2',
				value: 'segment2',
				align: 'center',
				sortable: false,
			},
			{
				text: '&dcIncentivesURL',
				value: 'dcIncentivesURL',
				align: 'center',
				sortable: false,
			},
			{
				text: '&dctype',
				value: 'dctype',
				align: 'center',
				sortable: false,
			},
			{
				text: '&intcmp',
				value: 'intcmp',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Actions',
				align: 'center',
				sortable: false,
			},
			],
		};
	},
	computed: {
		...mapGetters( {
			models: 'showroomModels',
		} ),
		modelsList() {
			if ( !Object.keys( this.models ).length || !this.models[this.activeMake] ) {
				return [];
			}
			return this.models[this.activeMake].map( ( model ) => model.model_name );
		},
	},
	async created() {
		await this.$store.dispatch( 'fetchShowroomModels', { makes: this.makes.join( ',' ) } );
	},
	methods: {
		...mapActions( {
			fetchShowroomModels: 'fetchShowroomModels',
		} ),
		filteredModels( selectedModel ) {
			return selectedModel ? this.modelsList.filter( ( model ) => model !== selectedModel ) : this.modelsList;
		},
		filteredYears( selectedModel, selectedYears ) {
			let filtered = [];
			if ( this.models[this.activeMake] ) {
				for ( const modelObj of this.models[this.activeMake] ) {
					if ( modelObj.model_name === selectedModel && modelObj.years.length ) {
						filtered = modelObj.years;
						if ( selectedYears.length ) {
							filtered = modelObj.years.filter( ( year ) => !selectedYears.map( Number ).includes( year ) );
						}
					}
				}
			}
			return filtered;
		},
	},
};
</script>
<style lang="scss">
	.v-data-table-bp.v-data-table {

		.v-data-table__wrapper {
			position: relative;

			table {
				border-spacing: 0 16px !important;

				th {
					background-color: #f5f5f5;
					font-size: 14px !important;
				}

				tbody {
					margin: 50px 0;

					tr.editable{
						background-color: #ececec;
					}

					tr:not(:last-child) td:not(.v-data-table__mobile-row) {
						border-bottom: none !important;
					}

					td {
						padding: 0 10px;
					}

					.patterntd {
						width: 15%;
					}

					.actiontd {
						display: table-cell;

						span:first-child {
							padding-right: 26px;
							text-align: left;
						}

						span:last-child {
							text-align: right;
						}
					}

					.mappedmodeltd input {
						min-width: 50px;
					}

					.v-select {
						.vs__dropdown-toggle {
							min-width: 180px;
						}

						&.vs--open .vs__dropdown-menu {
							position: relative;
							max-height: 110px !important;
						}
					}

					.icon {
						transition: transform 0.5s;

						&:hover {
							transform: scale(1.1);
							opacity: 0.7;
							cursor: pointer;
						}
					}

					.fa-check {
						color: #78be20;
					}
				}
			}
			.v-data-footer {
				margin-bottom: 20px;
			}
		}
	}
</style>
