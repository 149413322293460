<template>
  <div class="ap__item">
    <v-data-table
      :headers="headers"
      :items="sites"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ dateCreated( item.created ) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			headers: [{
				text: 'Account',
				value: 'account',
			},
			{
				text: 'Destination',
				value: 'destination.domain',
			},
			{
				text: 'Created',
				value: 'created',
			},
			{
				text: 'Errored at',
				value: 'progress.text',
			},
			{
				text: 'Error',
				value: 'error',
			}],
		};
	},
	computed: mapGetters( {
		sites: 'failedSites',
	} ),
	methods: {
		dateCreated( created ) {
			const date = new Date( created );
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const local = date.toLocaleString( 'en-US', { timeZone } );
			return local.replace( ', ', ' at ' );
		},
	},
};
</script>
