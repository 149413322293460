<template>
  <div class="ap__section">
    <div class="ap__title">
      Users
    </div>
    <div class="ap__item">
      <ap-loading :loading="createLoading" />
      <div class="ap__title--sub">
        User Creation
      </div>
      <div class="ap__input__wrap">
        <div class="ap__input">
          <label>Username</label>
          <input
            v-model="user.username"
            type="text"
          >
        </div>
        <div class="ap__input">
          <label>Password</label>
          <input
            v-model="user.password"
            type="password"
          >
        </div>
        <div class="ap__input">
          <label>Verify Password</label>
          <input
            v-model="verfiedPassword"
            type="password"
          >
        </div>
        <div class="ap__input">
          <label>User Email</label>
          <input
            v-model="user.email"
            type="text"
          >
        </div>
        <div class="ap__input">
          <label>User Role</label>
          <select v-model="user.role">
            <option
              v-for="role in roles"
              :key="role"
              :value="role"
            >
              {{ role }}
            </option>
          </select>
        </div>
      </div>
      <div class="error-group">
        <div
          v-for="error in errors"
          :key="error"
        >
          <span class="error">*</span> {{ error }}
        </div>
      </div>
      <div class="ap__wrap--btn">
        <ap-button
          value="Create User"
          :disabled="!valid"
          @clicked="createUser"
        />
        <ap-button
          value="Generate Password"
          :disabled="verfiedPassword && user.password"
          @clicked="generatePassword()"
        />
      </div>
    </div>
    <div class="ap__item">
      <div class="ap__title--sub">
        Modify Current Users
      </div>
      <v-card>
        <ap-loading :loading="userLoading" />
        <v-toolbar
          flat
          color="white"
        >
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              text
              icon
              color="#78be20"
              :disabled="userLoading"
              @click="updateUsers"
            >
              <v-icon>cached</v-icon>
            </v-btn>
          </v-card-title>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">Edit User</span>
              </v-card-title>
              <v-card-text>
                <div class="ap__input">
                  <label>Username</label>
                  <input
                    v-model="editedUser.username"
                    type="text"
                  >
                </div>
                <div class="ap__input">
                  <label>User Email</label>
                  <input
                    v-model="editedUser.email"
                    type="text"
                  >
                </div>
                <div class="ap__input">
                  <label>User Role</label>
                  <select v-model="editedUser.role">
                    <option
                      v-for="role in roles"
                      :key="role"
                      :value="role"
                    >
                      {{ role }}
                    </option>
                  </select>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <div
          class="notification"
          :class="nclass"
        >
          {{ notification }}
        </div>
        <v-data-table
          :headers="userHeaders"
          :items="users"
          :search="search"
        >
          <template v-slot:item.edit="{ item }">
            <v-icon
              small
              @click="editUser(item)"
            >
              fa fa-pencil-alt
            </v-icon>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-icon
              small
              @click="deleteUser(item)"
            >
              fa fa-trash
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <ap-modal
      v-if="modal"
      @close="modal = false"
    >
      <h2 slot="message">
        {{ modalMessage }}
      </h2>
    </ap-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			notification: '',
			nclass: '',
			createLoading: false,
			userLoading: false,
			errors: [],
			dialog: false,
			editedIndex: -1,
			originalUser: {},
			editedUser: {
				username: '',
				password: '',
				email: '',
				role: '',
			},
			defaultUser: {
				username: '',
				password: '',
				email: '',
				role: '',
			},
			user: {
				username: '',
				password: '',
				email: '',
				role: 'Trader',
			},
			users: [],
			roles: ['Trader', 'Administrator', 'Superadmin'],
			search: '',
			userHeaders: [{
				text: 'Username',
				value: 'username',
			}, {
				text: 'Email Address',
				value: 'email',
			}, {
				text: 'User Role',
				value: 'role',
			}, {
				text: 'Edit',
				value: 'edit',
				sortable: false,
				align: 'center',
			}, {
				text: 'Delete',
				value: 'delete',
				sortable: false,
				align: 'center',
			}],
			verfiedPassword: '',
			disabled: false,
			empty: null,
			pass: null,
			modal: false,
			modalMessage: '',
		};
	},
	computed: {
		valid() {
			const errors = [];
			let pass = true;

			// Check if any of the values are empty.
			Object.entries( this.user ).forEach( ( e ) => {
				if ( e[1].length === 0 ) {
					pass = false;
					errors.push( `${e[0]} field cannot be empty.` );
				}
			} );

			// Check if password & verified password are the same
			if ( this.user.password !== this.verfiedPassword ) {
				pass = false;
				errors.push( 'password and verified password do not match.' );
			}

			// Check email format.
			const regexEmail = /\S+@\S+\.\S+/;
			if ( !regexEmail.test( this.user.email ) ) {
				errors.push( 'email is incorrect. example email: convertus@convertus.com.' );
				pass = false;
			}

			// Check password format, and matches.
			const regexPassword = new RegExp( '^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})' );
			if ( !regexPassword.test( this.user.password ) ) {
				errors.push( 'password must contain 1 uppercase letter and one number.' );
				pass = false;
			}

			// Return Notification.
			this.errors = errors;
			return pass;
		},
	},
	watch: {
		dialog( val ) {
			val || this.close();
		},
	},
	mounted() {
		this.updateUsers();
	},
	methods: {
		async deleteUser( user ) {
			this.notification = '';
			this.nclass = '';
			// eslint-disable-next-line no-restricted-globals
			const pass = confirm( 'Are you sure you want to delete this user?' );
			if ( pass === true ) {
				const index = this.users.indexOf( user );
				this.users.splice( index, 1 );
				const response = await axios.delete( '/user', { data: { user } } );
				if ( response.pass === true ) {
					this.notification = `Successfully removed user ${user.username}.`;
					this.nclass = 'success';
				} else {
					this.notification = `Failed to remove user ${user.username}.`;
					this.nclass = 'error';
				}
			}
		},
		async save() {
			this.notification = '';
			this.nclass = '';
			// Should always be the case.
			if ( this.editedIndex > -1 ) {
				Object.assign( this.users[this.editedIndex], this.editedUser );
				const response = await axios.put( '/user', { originalUser: this.originalUser, editedUser: this.editedUser } );
				if ( response.pass === true ) {
					this.notification = `Successfully updated user ${this.editedUser.username}.`;
					this.nclass = 'success';
				} else {
					this.notification = `Failed to update user ${this.editedUser.username}.`;
					this.nclass = 'error';
				}
			}
			this.close();
		},
		close() {
			this.dialog = false;
			// So you dont see data switch while closing.
			setTimeout( () => {
				this.editedUser = { ...this.defaultUser };
				this.originalUser = { ...this.defaultUser };
				this.editedIndex = -1;
			}, 300 );
		},
		async editUser( user ) {
			this.editedIndex = this.users.indexOf( user );
			this.originalUser = { ...user };
			this.editedUser = { ...user };
			this.dialog = true;
		},
		async createUser() {
			if ( this.valid ) {
				this.createLoading = true;
				try {
					const response = await axios.post( '/user', this.user );
					if ( response.pass === true ) {
						this.user.username = '';
						this.user.password = '';
						this.user.email = '';
						this.modal = true;
						this.modalMessage = 'The new user was successfully created.';
						this.updateUsers(); // Get latest users in table.
					} else {
						this.modal = true;
						this.modalMessage = 'Something went wrong, the new user was not successfully created.';
					}
				} catch ( e ) {
					console.log( e );
				}
				this.createLoading = false;
			}
		},
		generatePassword() {
			const length = Math.floor( Math.random() * ( 10 - 6 ) ) + 6;
			const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let initialPassword = '';
			const randomNumber = Math.floor( Math.random() * 9 ) + 1;
			let finalPassword = '';

			for ( let i = 0, n = characters.length; i < length; i += 1 ) {
				initialPassword += characters.charAt( Math.floor( Math.random() * n ) );
			}

			finalPassword = initialPassword + randomNumber;
			this.user.password = finalPassword;
			this.verfiedPassword = finalPassword;
		},
		async updateUsers() {
			this.userLoading = true;
			try {
				const response = await axios.get( '/user' );
				if ( response.pass === true ) {
					this.users = response.data;
				} else {
					console.log( response.data );
				}
			} catch ( e ) {
				console.log( this.$store.state.axiosError );
			}
			this.userLoading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.notification {
	padding: 0px 10px;
	font-size: 14px;
}
.v-table {
	tbody tr td:nth-last-child(-n+2) {
		text-align: center;
		i:hover {
			cursor: pointer;
			transition: all 0.2s;
			color:#78be20;
		}
	}
}
.error-group {
	font-size: 14px;
	padding: 10px 0px;
}
.ap-modal {
	position: fixed;
	top: 0;
	width: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__message {
		background-color: white;
		padding: 16px;
		border-radius: 3px;
		border-top: 3px solid #78be20;
	}

	&__close {
		position: absolute;
		top: 50px;
		right: 50px;
		font-size: 2rem;
		color: white;
		cursor: pointer;
		transition: color 0.3s ease-in;

		&:hover {
			color: #e3e3e3;
		}
	}
}
</style>
