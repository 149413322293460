<template>
  <div style="position: relative;">
    <ap-loading :loading="loading" />
    <p>
      Manually renew group certificates here.
    </p>
    <ol>
      <li>Ensure the correct certificate is selected before running the renewal, as all domains within it will become pending.</li>
      <li>Domains that are pending for too long will be removed from the group. The certificate will then be re-requested automatically without these domains.</li>
    </ol>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>SSL Group</label>
        <v-select
          v-model="group"
          :options="filteredGroups"
        />
        <div class="ap__input__desc">
          Which group to renew.
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Renew Certificate"
        @clicked="renewGroupCert"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			loading: false,
			group: '',
			output: {},
			network: {},
		};
	},
	computed: {
		...mapGetters( ['sslGroups'] ),
		filteredGroups() {
			// Remove all and pending
			const exclude = ['all', 'pending'];
			const groups = [];
			this.sslGroups.forEach( ( g ) => {
				if ( !exclude.includes( g.name ) ) {
					groups.push( g );
				}
			} );
			return groups.map( ( g ) => g.name );
		},
	},
	methods: {
		async renewGroupCert() {
			this.output = {};

			if ( this.group === '' ) {
				this.output = 'Please select a group to renew.';
				return;
			}

			// Send request to renew certificate then output.
			this.loading = true;
			try {
				const response = await axios.post( '/aws/ssl/renew', { group: this.group } );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
	},
};
</script>
