<template>
  <div class="ap__section">
    <div class="ap__label">
      Add New Profile
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Name</label>
        <input
          v-model="profile.name"
          type="text"
        >
        <div class="ap__input__desc">
          Name of new check profile.
        </div>
      </div>

      <div class="ap__input">
        <label>Interval in Minutes</label>
        <input
          v-model.number="profile.interval"
          type="text"
        >
        <div class="ap__input__desc">
          How often this check is run.
        </div>
      </div>

      <div class="ap__input">
        <label>Threshold in Seconds</label>
        <input
          v-model.number="profile.threshold"
          type="text"
        >
        <div class="ap__input__desc">
          Timeout of check.
        </div>
      </div>

      <div class="ap__input">
        <label>Sensitivity</label>
        <input
          v-model.number="profile.sens"
          type="text"
        >
        <div class="ap__input__desc">
          # additional servers to check when status changes.
        </div>
      </div>
    </div>

    <div class="ap__input__wrap">
      <div class="ap__input">
        <v-checkbox
          v-model="profile.follow"
          hide-details
          color="#78be20"
          label="Follow redirects?"
        />
      </div>

      <div class="ap__input">
        <v-checkbox
          v-model="profile.enabled"
          hide-details
          color="#78be20"
          label="Enable?"
        />
      </div>
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Save Profile"
        @clicked="save"
      />
    </div>

    <v-card>
      <ap-loading :loading="loading" />

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="nodepings"
        :search="search"
      >
        <template v-slot:item.follow="{ item }">
          {{ booleanToText( item.follow ) }}
        </template>
        <template v-slot:item.sites="{ item }">
          {{ nodepingObj[item.name] }}
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Refresh"
          @clicked="update"
        />
      </div>
    </v-card>
    <div>
      <div class="ap__label pt-5">
        Field Definitions
      </div>
      <ul>
        <li><strong>Interval</strong> - Defaults to 15 - How often this check runs in minutes. Can be any integer starting at 1 for one minute checks. Once a day is 1440.</li>
        <li><strong>Threshold</strong> - The timeout for this check in seconds, defaults to 5 for a five second timeout. Can be any integer starting at 1.</li>
        <li><strong>Sensitivity</strong> - Number of rechecks before this check is considered 'down' or 'up'. Defaults to 2 (2 rechecks means it will be checked by 3 different servers). Rechecks are run immediately, not based on the interval and happen when a status change occurs.</li>
        <li><strong>Follow</strong> - If true, the check will follow up to four redirects.</li>
      </ul>
    </div>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			profile: {
				name: '',
				interval: 1,
				enabled: true,
				sens: 2,
				threshold: 30,
				follow: true,
			},
			search: '',
			loading: false,
			updated: '',
			message: '',
			headers: [],
		};
	},
	computed: {
		...mapGetters( {
			allDomains: 'sslDomains',
			nodepings: 'allNodePingProfiles',
		} ),
		domains() {
			return this.allDomains.filter( ( d ) => d.group !== 'pending' && d.npID !== undefined );
		},
		nodepingObj() {
			const obj = {};
			this.nodepings.forEach( ( np ) => {
				const sites = [];

				this.domains.forEach( ( domain ) => {
					if ( domain.npProfile === np.name ) {
						sites.push( domain );
					}
				} );

				obj[np.name] = sites.length;
			} );

			return obj;
		},
	},
	mounted() {
		this.headers = [{
			text: 'Name',
			value: 'name',
		}, {
			text: 'Interval',
			value: 'interval',
		}, {
			text: 'Threshhold',
			value: 'threshold',
		}, {
			text: 'Sensitivity',
			value: 'sens',
		}, {
			text: 'Follow Redirects?',
			value: 'follow',
		}, {
			text: 'Enabled',
			value: 'enabled',
		}, {
			text: '# Sites',
			value: 'sites',
		}];
	},
	methods: {
		booleanToText( bool ) {
			if ( bool === true ) {
				return 'true';
			}
			return 'false';
		},
		async save() {
			const profile = {
				...this.profile,
				enabled: this.booleanToText( this.profile.enabled ),
			};
			const response = await axios.post( '/node-ping', profile );

			if ( response.errors.length > 0 ) {
				this.message = response.errors.join( ', ' );
			}
		},
		async update() {
			this.loading = true;
			await this.$store.dispatch( 'updateNodePingProfiles' );
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
table {

	th {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}

	td {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}
}
</style>
