<template>
  <div class="version">
    <small>Version {{ releaseVersion }}</small>
  </div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Version',
	data() {
		return {
			releaseVersion: 'version1',
		};
	},
	created() {
		axios.get( '/api/config' ).then( ( response ) => {
			this.releaseVersion = response.release_version;
		} );
	},
};
</script>

<style scoped>
  .version {
    padding: 12px 15px;
    color: gray;
  }
</style>
