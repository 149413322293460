<template>
  <div class="ap__section">
    <ap-loading :loading="loading" />
    <div class="ap__title">
      Logging
    </div>
    <div class="ap__item">
      <div class="ap__title--sub">
        Log Hours
      </div>
      <ul>
        <li>This tool uses Jira's REST API to automatically log hours over a span of days or for a selected month.</li>
        <li>
          An email and API token must be provided to use this tool. API tokens can be generated by logging into Jira and creating a token <a
            href="https://id.atlassian.com/manage/api-tokens"
            target="_blank"
          >here</a>.
        </li>
        <li>The script will take existing logs into account, adding hours so that each day has a total of 7 hours logged.</li>
        <li>Weekends are skipped.</li>
        <li>May take a while if hours are being added to many days.</li>
      </ul>
      <div class="ap__input__wrap">
        <div class="ap__input">
          <label>Email</label>
          <input
            v-model="data.email"
            type="text"
          >
          <div class="ap__input__desc">
            Your Convertus email.
          </div>
        </div>

        <div class="ap__input">
          <label>API Token</label>
          <input
            v-model="data.token"
            type="password"
          >
          <div class="ap__input__desc">
            Your generated API Token.
          </div>
        </div>

        <div class="ap__input">
          <label>Type</label>
          <select v-model="selected">
            <option value="Month">
              Month
            </option>
            <option value="Day Range">
              Day Range
            </option>
          </select>
          <div class="ap__input__desc">
            How hours should be logged.
          </div>
        </div>
      </div>
      <div
        v-if="selected === 'Month'"
        class="ap__input__wrap"
      >
        <v-date-picker
          v-model="data.month"
          no-title
          min="2019-01"
          :show-current="false"
          :max="new Date().toISOString().substring( 0, 7 )"
          type="month"
          color="#78be20"
        />
      </div>
      <div
        v-else
        class="ap__input__wrap"
      >
        <div class="ap__input">
          <label>Start</label>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <input
                v-model="data.start"
                readonly
                v-on="on"
              >
            </template>
            <v-date-picker
              v-model="data.start"
              no-title
              :show-current="false"
              color="#78be20"
            />
          </v-menu>
        </div>

        <div class="ap__input">
          <label>End</label>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <input
                v-model="data.end"
                readonly
                v-on="on"
              >
            </template>
            <v-date-picker
              v-model="data.end"
              no-title
              :show-current="false"
              :min="data.start"
              color="#78be20"
            />
          </v-menu>
        </div>
      </div>
      <div class="ap__input__wrap">
        <div class="ap__input">
          <label>Task</label>
          <v-select
            v-model="task"
            :options="issues"
            label="label"
            taggable
          />
          <div class="ap__input__desc">
            Task to log hours into. Issues can be typed in.
          </div>
        </div>
      </div>
      <div
        class="ap__notification"
        :class="nclass"
      >
        {{ message }}
      </div>
      <div class="ap__wrap--btn">
        <ap-button
          :disabled="loading"
          value="Log Hours"
          @clicked="logHours"
        />
        <ap-button
          :disabled="loading"
          value="Refresh Tasks"
          @clicked="update"
        />
      </div> <ap-response :json="output" />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			selected: 'Month',
			data: {
				email: '',
				token: '',
				month: new Date().toISOString().substring( 0, 7 ),
				start: new Date().toISOString().substring( 0, 10 ),
				end: '',
				task: '',
			},
			task: '',
			nclass: '',
			message: '',
			loading: false,
			output: {},
		};
	},
	computed: mapGetters( { issues: 'allIssues' } ),
	async mounted() {
		if ( this.issues.length !== 0 ) {
			this.task = this.issues[0];
		}
	},
	methods: {
		async logHours() {
			this.loading = true;
			this.message = '';
			this.nclass = '';
			this.data.task = this.task.split( ' - ' )[0];
			if ( this.selected === 'Month' ) {
				try {
					this.output = {};
					const response = await axios.post( '/achilles/log-hours-month', this.data );
					this.output = response;
				} catch ( e ) {
					this.output = `${this.$store.state.axiosError} or restful routes not integrated.`;
				}
			} else {
				try {
					this.output = {};
					const response = await axios.post( '/achilles/log-hours-range', this.data );
					this.output = response;
				} catch ( e ) {
					this.output = `${this.$store.state.axiosError} or restful routes not integrated.`;
				}
			}
			this.loading = false;
		},
		async update() {
			this.loading = true;
			await this.$store.dispatch( 'updateIssues' );
			this.loading = false;
		},
	},
};
</script>
