<template>
  <div class="ap__section">
    <!-- Loading Circle -->
    <ap-loading :loading="loading" />

    <!-- Warning Popup -->
    <v-dialog
      v-model="warning"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Warning
        </v-card-title>

        <v-card-text>
          {{ warningMsg }} Are you sure you want to do this?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1 white--text"
            @click="warning = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1 white--text"
            @click="generateSite"
          >
            Migrate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Generation -->
    <div class="ap__title">
      Generate Site
    </div>
    <div class="ap__item">
      Generate a DDC Site on the Achilles Platform.
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Account Name</label>
        <input
          v-model="data.domain"
          type="text"
        >
        <div class="ap__input__desc">
          Based on XML file name.
          <br>401dixiehyundaitc_en-CA.xml -> 401dixiehyundaitc
        </div>
      </div>
      <div class="ap__input">
        <label>Destination Network</label>
        <v-select
          v-model="data.destination"
          :options="albs"
          label="domain"
          @input="updateDestSubdomains(); updateProfiles()"
        />
        <div class="ap__input__desc">
          Network to generate site in.
        </div>
      </div>
      <div class="ap__input">
        <label>Profile Site</label>
        <v-select
          v-model="data.profile"
          :options="profiles"
          label="domain"
        />
        <div class="ap__input__desc">
          Profile to use with site.
        </div>
      </div>
      <div class="ap__input">
        <label>Prioritize Profile Pages</label>
        <v-switch
            v-model="data.use_profile_common_pages"
            color="primary"
            value="true"
            hide-details
        ></v-switch>
        <div class="ap__input__desc">
          Use profile site's page if URL matched.
        </div>
      </div>
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Site XML(s)</label>
        <input
          type="file"
          multiple
          accept=".xml"
          @change="updateFiles"
        >
      </div>
      <div class="ap__input">
        <label>Account + Lang XLSX</label>
        <input
          type="file"
          accept=".xlsx"
          @change="updateFiles"
        >
      </div>
      <div class="ap__input">
        <label>Leads CSV</label>
        <input
          type="file"
          accept=".csv"
          @change="updateFiles"
        >
      </div>
    </div>
    <div
      class="ap__notification"
    >
      {{ message }}
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Generate Site"
        @clicked="confirmGenerate"
      />
    </div>
    <div class="ap__item">
      <v-tabs color="white">
        <v-tab
          v-for="tab in tabs"
          :key="tab.label"
          ripple
          @click="changeActiveTab(tab)"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <div class="ap__tab__content">
        <keep-alive>
          <component :is="activeTab" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { io } from 'socket.io-client';
import { mapGetters } from 'vuex';
import Request from './generate/Request.vue';
import Completed from './generate/Completed.vue';
import Failed from './generate/Failed.vue';

export default {
	components: {
		Request,
		Completed,
		Failed,
	},
	data() {
		return {
			activeTab: 'Request',
			tabs: [{
				label: 'Requests',
				component: 'Request',
			}, {
				label: 'Completed',
				component: 'Completed',
			}, {
				label: 'Failed',
				component: 'Failed',
			}],
			data: {
				profile: {
					domain: 'None',
				},
                use_profile_common_pages: false,
				accounts: {},
				domain: '',
				subdomain: '',
				blogname: '',
				destination: '',
				xmls: [],
				leads: {},
				email: '',
			},
			warning: false,
			generate: false,
			warningMsg: '',
			message: '',
			destSubdomains: [],
			profiles: [],
			socket: null,
			output: {},
			subdomainLoading: false,
			profileLoading: false,
			checkLoading: false,
		};
	},
	computed: {
		...mapGetters( { albs: 'allALBs', user: 'user' } ),
		loading() {
			return this.subdomainLoading || this.profileLoading || this.checkLoading;
		},
	},
	mounted() {
		this.data.email = this.user.email;
		this.data.destination = this.albs[0];
		this.updateDestSubdomains();
		this.updateProfiles();
		this.connectToSocket();
	},
	methods: {
		updateFiles( e ) {
			const type = e.target.files[0].type;

			if ( type.includes( 'spreadsheet' ) ) {
				this.data.accounts = e.target.files[0];
			} else if ( type.includes( 'csv' ) ) {
				this.data.leads = e.target.files[0];
			} else if ( e.target.files.length > 1 ) {
				this.data.xmls = [
					{
						name: e.target.files[0].name,
						file: e.target.files[0],
					},
					{
						name: e.target.files[1].name,
						file: e.target.files[1],
					},
				];
			} else {
				this.data.xmls = [{
					name: e.target.files[0].name,
					file: e.target.files[0],
				}];
			}
		},
		async updateDestSubdomains() {
			this.message = '';
			this.updated = '';
			this.subdomainLoading = true;
			try {
				const response = await axios.get( `${this.data.destination.prefix}://${this.data.destination.domain}/wp-json/achilles/v1/sites` );
				const siteList = Object.values( response );
				const subdomains = [];

				siteList.forEach( ( site ) => {
					if ( site.domain.includes( this.data.destination.domain ) && site.domain.split( '.' ).length === 3 && site.domain.split( '.' )[0] !== 'www' ) {
						subdomains.push( site.domain.split( '.' )[0] );
					}
				} );

				this.destSubdomains = subdomains;
			} catch ( e ) {
				this.destSubdomains = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.subdomainLoading = false;
		},

		async updateProfiles() {
			this.message = '';
			this.updated = '';
			this.profileLoading = true;
			try {
				let response = await axios.get( `${this.data.destination.prefix}://${this.data.destination.domain}/wp-json/achilles/v1/sites` );
				const siteList = Object.values( response );
				response = await axios.get( `${this.data.destination.prefix}://${this.data.destination.domain}/wp-json/achilles/v1/blogmeta?key=${this.data.destination.auth.key}&blogmeta=siteprofile_type&sites=` );

				const profiles = [];
				response = response.data;
				siteList.forEach( ( site ) => {
					if ( site.domain in response && response[site.domain].siteprofile_type === 'Profile' ) {
						profiles.push( site );
					}
				} );

				this.profiles = [{ domain: 'None' }].concat( profiles );
			} catch ( e ) {
				this.profiles = [{ domain: 'None' }];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.data.profile = {
				domain: 'None',
			};
			this.profileLoading = false;
		},

		async confirmGenerate() {
			const defaultsub = this.data.domain;

			// Check if site with same blogname already exists in destination.
			this.checkLoading = true;
			const response = await axios.post( '/wpapi/achilles/check-site-exists', { ...this.data } );
			this.checkLoading = false;

			if ( response.pass === true ) {
				this.warningMsg = `${response.data.domain} has the same blog name as the site you are migrating, so this site will be overwritten with new data.`;
				this.warning = true;
			} else if ( this.destSubdomains.includes( this.data.subdomain ) || ( this.destSubdomains.includes( defaultsub ) && this.data.subdomain === '' ) ) {
				let subdomain = this.data.subdomain;
				if ( subdomain === '' ) {
					subdomain = defaultsub;
				}

				this.warningMsg = `${subdomain}.${this.data.destination.domain} already exists, so this site will be overwritten with new data.`;
				this.warning = true;
			} else {
				await this.generateSite();
			}
		},
		async generateSite() {
			this.warning = false;
			try {
				this.generate = true;
				this.socket.emit( 'generate-site', this.data );
			} catch ( e ) {
				console.log( e );
			}
			return 'test';
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'generate-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.errors.join( ', ' );
				} else {
					this.output = { errors: response.errors, success: response.success };
					this.updated = 'success';
					this.message = 'Successfully made query request.';
				}
				this.generate = false;
			} );
			this.socket.on( 'generate-db-update', () => {
				this.$store.dispatch( 'updateGenerate' );
			} );
			this.socket.on( 'generate-progress', ( response ) => {
				this.progress = response;
			} );
		},
		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
	},
};
</script>
