import Vue from 'vue';
import Vuex from 'vuex';
import alb from './modules/alb';
import dns from './modules/dns';
import user from './modules/user';
import ssl from './modules/ssl';
import query from './modules/query';
import customQuery from './modules/custom-query';
import jira from './modules/jira';
import nodeping from './modules/nodeping';
import generate from './modules/generate';
import showroomModels from './modules/showroom-models';

Vue.use( Vuex );
export default new Vuex.Store( {
	state: {
		activePanel: 'Networks',
		transition: 'fade',
		axiosError: 'There was an issue while connecting to the server.',
	},
	getters: {
		activePanel( state ) {
			return state.activePanel;
		},
		transition( state ) {
			return state.transition;
		},
	},
	mutations: {
		setActivePanel( state, panel ) {
			state.activePanel = panel;
		},
	},
	actions: {},
	modules: {
		alb, dns, user, ssl, query, customQuery, jira, nodeping, generate, showroomModels,
	},
} );
