<template>
  <div>
    <div class="ap__label">
      {{ label }}
    </div>
    <pre><code>{{ json }}</code></pre>
  </div>
</template>
<script>
export default {
	props: {
		json: {
			type: [Array, Object, String],
			required: true,
		},
		label: {
			type: String,
			default: 'Response',
		},
	},
	data() {
		return {};
	},
};
</script>
<style lang="scss">
pre {
	overflow-x: auto;
	white-space: pre-wrap;
	display: block;
	padding: 9.5px;
	margin: 5px 0px;
	font-size: 13px;
	line-height: 1.42857143;
	color: #333;
	word-break: break-all;
	word-wrap: break-word;
	background-color: #0c0c0c;
	color: white;
	box-shadow: 1px 1px 1px #bebebe;
	max-height: 400px;
}
</style>
