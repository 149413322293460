<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Build Query
    </div>

    <v-card 
		color="#343536"
		class="white--text bold--text rounded-xl px-5 my-5"
	>
        <v-row align="center">

			<v-col class="d-flex" cols="2">
                <div class="ap__input__option">
                    <v-select
						v-model="query.network"
						label="network"
						:options="albs"
						@input="updateAccountList"
                    />
                    <div class="ap__input__desc font-italic">
                    	Which network to query
                    </div>
					<div class="ap__input__desc font-italic error--text" v-if="hasErrors.query.network">
						Please select a network
					</div>
                </div>
            </v-col>

			<v-col class="d-flex" cols="2">
                <div class="ap__input__option">
                    <v-select
						v-model="query.account"
						:options="accountList"
						label="domain"
                    />
                    <div class="ap__input__desc font-italic">
                    	Which account to query
                    </div>
					<div class="ap__input__desc font-italic error--text" v-if="hasErrors.query.account">
						Please select an account
					</div>
                </div>
            </v-col>
		</v-row>
		<v-row style="border: 1px solid #FFF">
            <v-col class="d-flex" cols="1">
                <div class="ap__input__option">
                    <v-input readonly> <strong> SELECT * FROM </strong> </v-input>
                </div>
            </v-col>
            <v-col class="d-flex" cols="2">
                <div class="ap__input__option">
                    <v-select
						v-model="query.table"
						label="label"
						:options="tableNames"
                    />
                    <div class="ap__input__desc font-italic">
                    	Which Table to Query
                    </div>
					<div class="ap__input__desc font-italic error--text" v-if="hasErrors.query.table">
						Please select a table
					</div>
                </div>
            </v-col>
			<v-col class="d-flex" cols="1">
                <div class="ap__input__option">
                    <v-input readonly> <strong> WHERE </strong> </v-input>
                </div>
            </v-col>
			<v-col class="d-flex" cols="2" v-if="query.condition">
                <div class="ap__input__option">
					<label></label>
                    <v-text-field 
						solo
						dense
						single-line
						v-model="query.conditionColumnName"
						label="Enter column (eg: cfRecord)"
					/>
					<div class="ap__input__desc font-italic error--text" v-if="hasErrors.query.conditionColumnName">
						Please enter column name
					</div>
                </div>
            </v-col>
			<v-col class="d-flex" cols="2">
                <div class="ap__input__option">
                    <v-select
						v-model="query.condition"
						label="label"
						:options="conditionOptions"
						dense
						solo
                    />
                    <div class="ap__input__desc font-italic">
                    	Some condition (Optional)
                    </div>
                </div>
            </v-col>
			<v-col class="d-flex" cols="2" v-if="query.condition">
                <div class="ap__input__option">
					<label></label>
                    <v-text-field 
						solo
						dense
						single-line
						v-model="query.conditionColumnValue"
						label="Enter value (eg: ghY56Tgf)"
					/>
					<div class="ap__input__desc font-italic error--text" v-if="hasErrors.query.conditionColumnValue">
						Please enter some value
					</div>
                </div>
            </v-col>
			<v-col class="d-flex" cols="2">
                <div class="ap__input__option">
					<label></label>
                    <v-textarea
						single-line
						rows=2
						:flat="true"
						v-model="query.additionalFilters"
						label="Any additional filter"
					/>
					<div class="ap__input__desc font-italic">
						eg: LIMIT, ORDER BY, WHERE <v-icon left @click="infoDialog = true" class="white--text" >fa fa-question-circle</v-icon>
					</div>
                </div>
            </v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex" cols="1">
				<div class="ap__input__option">
					<v-btn
						color="success"
						:disabled="query.disabled"
						@click.prevent="queryDatabase"
					> <v-icon left >fa fa-check</v-icon> Query </v-btn>
				</div>
            </v-col>
			<v-col class="d-flex" cols="1">
				<div class="ap__input__option">
					<v-btn
						color="error"
						@click.prevent="resetQuery"
					> <v-icon left> fa fa-ban</v-icon> Reset </v-btn>
				</div>
            </v-col>
			<v-col class="d-flex" cols="1">
				<div class="ap__input__option">
					<v-btn
						color="primary"
						@click="openSaveQueryDialog"
					> 
					<v-icon left> fa fa-save </v-icon>
					Save </v-btn>
				</div>
            </v-col>
        </v-row>
    </v-card>

	<h4 class="text-xl-h4">OR</h4>

	<v-card 
		color="#343536"
		class="white--text bold--text rounded-xl px-5 my-5"
	>
        <v-row align="center">
			<v-col class="d-flex" cols="2">
                <div class="ap__input__option">
                    <v-select
						v-model="useSavedQuery"
						label="name"
						:options="savedQueries"
                    />
                    <div class="ap__input__desc font-italic">
                    	Use from saved queries
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="results"
        :search="search"
      >
        <template v-slot:item.account="{ item }">
          <a
            :href="'http://' + item.account"
            target="_blank"
          >
            {{ item.account }}
          </a>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Download CSV"
          @clicked="csv"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    />{{ message }}
	
	<v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Save Query
          </v-card-title>
          <v-card-text>
            
			<div class="ap__input__option">
				<label></label>
				<v-text-field :single-line="true" :outlined="true" v-model="saveQueryName" label="Enter query title" />
				<div class="ap__input__desc font-italic error--text" v-if="hasErrors.saveQueryName">
					Please enter some title
				</div>
			</div>

          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="saveQuery"
            >
              Save
            </v-btn>
			<v-btn
              color="error"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

	  <!-- SQL info text dialog start-->
	  <v-dialog
        v-model="infoDialog"
        max-width="700px"
      >
        <v-card>
			<v-card-title class="text-h5 grey lighten-2">
          		SQL Syntax
        	</v-card-title>
          <v-card-text>
            <v-alert
				dense
				type="info"
				class="my-2"
			>
				Always put string values in single quotes, like 'John Doe', 'Canada', 'SOMETOKEN'
			</v-alert>
			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>Add extra where clause</v-list-item-title>
					<v-list-item-subtitle>eg: WHERE name = 'John Doe'</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>Add multiple where clause</v-list-item-title>
					<v-list-item-subtitle>eg: WHERE name = 'John Doe' AND age > 30</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>To get only 100 records maximum</v-list-item-title>
					<v-list-item-subtitle>eg: LIMIT 100</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>To sort result using user_name column</v-list-item-title>
					<v-list-item-subtitle>eg: ORDER by user_name ASC</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>Use where, limit and order by togther</v-list-item-title>
					<v-list-item-subtitle>eg: WHERE name = 'John Doe' AND age > 30 ORDER by user_name ASC LIMIT 100</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="infoDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
	  <!-- SQL info text dialogs end-->

  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { io } from 'socket.io-client';
import json from 'format-json';
import download from '../../../js/mixins/download';

export default {
	mixins: [download],
	data() {
		return {
			dialog: false,
			infoDialog: false,
			loading: false,
			search: '',
			message: '',
			updated: '',
			headers: [],
			results: [],
			conditionOptions: [
				{ label: "=", value: "equal" },
				{ label: ">", value: "gt" },
				{ label: "<", value: "lt" },
				{ label: "%LIKE%", value: "like" }
			],
			query: {
				disabled: false,
				account: null,
				network: null,
                table: null,
				condition: null,
				conditionColumnName: null,
				conditionColumnValue: null,
				additionalFilters: null
			},
			accountList: [],
			useSavedQuery: null,
			saveQueryName: null,
			notification: '',
			socket: null,
			hasErrors: {
				saveQueryName: false,
				query: {
					table: false,
					network: false,
					account: false,
					condition: false,
					conditionColumnName: false,
					conditionColumnValue: false
				}
			}
		};
	},
	computed: mapGetters( {
		albs: 'allALBs',
		tableNames: 'getWpTableNames',
		savedQueries: 'getSavedQueries',
	} ),
	watch: {
		'query.table': function(newVal, oldVal) {
			//only empty if not using some prefilled query
			if(!this.useSavedQuery) {
				this.query.condition = null;
				this.query.conditionColumnName = null;
				this.query.conditionColumnValue = null;
				this.query.additionalFilters = null;
				this.useSavedQuery = null;
			}
		},
		useSavedQuery(newVal, oldVal) {
			if(newVal.query) {
				this.query.table = newVal.query.table;
				this.query.condition = newVal.query.condition;
				this.query.conditionColumnName = newVal.query.conditionColumnName;
				this.query.conditionColumnValue = newVal.query.conditionColumnValue;
				this.query.additionalFilters = newVal.query.additionalFilters;
			}
		}
	},
	async mounted() {
        //get all table names
        this.$store.dispatch( 'updateWpTableNames' );
        this.$store.dispatch( 'updateSavedQueries' );

		if ( this.albs.length !== 0 ) {
			this.query.network = this.albs[0];
			this.updateAccountList();
		}

		this.connectToSocket();
	},
	methods: {
		async updateAccountList() {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get( `${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/sites` );
				const accountList = Object.values( response );
				this.accountList = accountList;
				this.account = [accountList[0]];
			} catch ( e ) {
				this.query.accountList = [];
				this.query.accounts = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async queryDatabase() {

			//check validations
			if(this.hasErrorsCustomQuery())
				return false;

			this.loading = true;
            
			//hit query
            let results = await this.$store.dispatch( 'queryAchillesDatabase', this.query );

			this.loading = false;
			
			this.updateTable(Object.values( results.data ));
        },
		hasErrorsCustomQuery() {
			this.resetValidationErrors();

			let hasError = false;

			if(!this.query.network)
				this.hasErrors.query.network = hasError = true;

			if(!this.query.account)
				this.hasErrors.query.account = hasError = true;
			
			if(!this.query.table)
				this.hasErrors.query.table = hasError = true;
			
			if(this.query.condition) {

				if(!this.query.conditionColumnName)
					this.hasErrors.query.conditionColumnName = hasError = true;

				if(!this.query.conditionColumnValue)
					this.hasErrors.query.conditionColumnValue = hasError = true;
			}

			return hasError;
		},
		resetValidationErrors() {
			this.hasErrors.saveQueryName = false;
			this.hasErrors.query.network = false;
			this.hasErrors.query.account = false;
			this.hasErrors.query.table = false;
			this.hasErrors.query.condition = false;
			this.hasErrors.query.conditionColumnName = false;
			this.hasErrors.query.conditionColumnValue = false;
		},
		openSaveQueryDialog() {
			
			//check validations
			if(this.hasErrorsCustomQuery())
				return false;

			this.dialog = true; //this will open pop-up window
		},
        async saveQuery() {
			
			if(this.hasErrorsCustomQuery())
				return false;

			//check validations
			if(!this.saveQueryName) {
				this.hasErrors.saveQueryName = true;
				return;
			}

			this.loading = true; 
            
			//hit query
            await this.$store.dispatch( 'saveCustomQuery', {
				name: this.saveQueryName,
				query: this.query
			} );

			//refresh saved queries list
			await this.$store.dispatch( 'updateSavedQueries' );
			
			this.dialog = false;
			this.loading = false;
			
        },
		resetQuery() {
			this.resetValidationErrors();
			this.query.table = null;
			this.query.condition = null;
			this.query.conditionColumnName = null;
			this.query.conditionColumnValue = null;
			this.query.additionalFilters = null;
			this.useSavedQuery = null;
			this.results = [];
		},
		csv() {
			if ( this.results.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			this.updated = '';
			this.message = '';
			this.loading = true;
			const response = this.download( this.results, this.headers.map( ( h ) => h.text ), 'Query' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
		updateTable( data ) {
			const results = [];
			const headers = [];

			if(data.length) {
				data = Object.entries( data );
				data.forEach( ( e ) => {
					Object.entries( e[1] ).forEach( ( en ) => {
						if ( en[1].constructor !== String ) {
							const str = json.space( en[1] );
							e[1][en[0]] = str;
						}
					} );
					results.push( e[1] );
				} );
				Object.keys( data[0][1] ).forEach( ( h ) => headers.push( { text: h, value: h } ) );
			}

			this.headers = headers;
			this.results = results;
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'options-list-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.data;
				} else {
					this.updateTable( response.data );
					this.updated = 'success';
					this.message = 'Successfully made query request.';
				}
				this.loading = false;
			} );
		},
	},
};
</script>