import axios from 'axios';

const state = {
	user: false,
};
const getters = {
	user( s ) {
		return s.user;
	},
};
const mutations = {
	setUser( s, user ) {
		s.user = user;
	},
};
const actions = {
	async fetchUser( { commit } ) {
		const response = await axios.get( '/user/session' );
		commit( 'setUser', response.data );
	},
};
export default {
	getters, mutations, actions, state,
};
