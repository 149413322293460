<template>
  <div class="ap__section">
    <div class="ap__item">
      <ap-loading :loading="loading" />
      <div class="ap__title--sub">
        Showroom Build and Price Configuration
      </div>
      <div class="ap__wrap--btn">
        <ap-button
          :value="currentEnv.toggledLabel"
          @clicked="toggleEnv()"
        />
      </div>
      <div class="ap__input__wrap">
        <div class="ap__input">
          <label class="ap__input__label">{{ currentEnv.value[0].toUpperCase() + currentEnv.value.slice(1) }} Networks</label>
          <v-select
            v-model="networks"
            :options="albs"
            label="network"
            multiple
            disabled
          />
          <div class="ap__input__desc">
            Which networks to update.
          </div>
        </div>
      </div>
      <v-card class="v-card-bp">
        <v-tabs color="white">
          <v-tab
            v-for="make of makes"
            :key="make.id"
            ripple
            class="text-white"
            @click="setMake(make)"
          >
            {{ make.label }}
          </v-tab>
          <a
            class="bp-config-link"
            target="_blank"
            href="https://trader.atlassian.net/wiki/spaces/TAD/pages/3694133290/Showroom+Build+Price+Configuration+Guide"
          >Configuration Guide</a>

          <v-tab-item
            v-for="make of makes"
            :key="make.id"
          >
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              />
              <ap-button
                value="Add New URL"
                @clicked="addItem()"
              />
            </v-card-title>
            <showroomBPTable
              v-if="currentItems.length > 0"
              :active-items="currentItems"
              :active-make="activeTab.label"
              :search="search"
              :makes="makes.map( make => make.label )"
              @edit="editItem"
              @delete="deleteItem"
            />

            <div
              class="ap__table__footer"
            >
              <ap-button
                value="Submit Configuration"
                :disabled="! itemsModified"
                @clicked="openDialog()"
              />
              <ap-button
                :disabled="loading"
                value="Download History"
                @clicked="downloadBPConfigHistory"
              />
              <div
                class="ap__notification"
                :class="updated"
              >
                {{ message }}
              </div>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="500"
            >
              <v-card>
                <v-card-title class="headline">
                  Confirmation
                </v-card-title>
                <v-card-text>
                  Do you have approval to update the Build and Price configuration for the selected {{ currentEnv.value }} networks?
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="green darken-1"
                    text
                    @click="updateBPConfig(true)"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="updateBPConfig(false)"
                  >
                    No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="alert.visible"
              max-width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Warning!
                </v-card-title>
                <v-card-text>
                  <v-alert
                    type="info"
                    dense
                    class="my-2"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    @click="alert.visible = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { format } from 'date-fns';
import showroomBPTable from './showroomBP/showroomBPTable.vue';

export default {
	components: {
		showroomBPTable,
	},
	data() {
		return {
			loading: false,
			dialog: false,
			validated: true,
			alert: {
				visible: false,
				message: '',
			},
			networks: [],
			updated: '',
			message: '',
			search: '',
			makes: [
				{ id: 1, label: 'Ford', status: true },
				{ id: 2, label: 'Lincoln', status: false },
			],
			items: {},
			originalItems: {},
			originalBPConfigJSON: {},
			env: [{
				toggledLabel: 'Select Prod Networks',
				value: 'dev',
				status: true,
			},
			{
				toggledLabel: 'Select Dev Networks',
				value: 'prod',
				status: false,
			},
			],
		};
	},
	computed: {
		...mapGetters( {
			albs: 'allALBs',
			currentUser: 'user',
		} ),
		activeTab() {
			return this.makes.find( ( make ) => make.status === true );
		},
		currentEnv() {
			return this.env.find( ( e ) => e.status === true );
		},
		currentItems() {
			return this.items[this.activeTab.label] || [];
		},
		itemsModified() {
			return this.upsertedItems.length || this.deletedItems.length;
		},
		// diff of originalItems (server version) and currentItems (client version).
		upsertedItems() {
			const upsertedItems = [];
			if ( this.currentItems ) {
				this.currentItems.forEach( ( updatedItem ) => {
					const originalItem = this.originalItems[this.activeTab.label].find( ( item ) => item.id === updatedItem.id );
					if ( !originalItem ) { // new entry added.
						upsertedItems.push( updatedItem );
					} else if ( JSON.parse( JSON.stringify( originalItem ) !== JSON.stringify( updatedItem ) ) ) {
						// Item is modified
						upsertedItems.push( updatedItem );
					}
				} );
			}
			return upsertedItems;
		},
		deletedItems() {
			const deletedItems = [];
			if ( this.originalItems && this.originalItems[this.activeTab.label] ) {
				this.originalItems[this.activeTab.label].forEach( ( originalItem ) => {
					const updatedItem = this.currentItems.find( ( item ) => item.id === originalItem.id );
					if ( !updatedItem ) {
						deletedItems.push( originalItem );
					}
				} );
			}
			return deletedItems;
		},
	},
	watch: {
		message( val ) {
			if ( val ) {
				setTimeout( () => {
					this.message = '';
				}, 8000 );
			}
		},
	},
	async mounted() {
		if ( this.albs.length !== 0 ) {
			await this.generateList();
		}
		await this.initItems();
	},
	methods: {
		openDialog() {
			// Validate if there are any unsaved changes.
			this.validateDiff();
			if ( this.validated ) {
				this.dialog = true;
			}
		},
		showAlert( message ) {
			this.alert.message = message;
			this.alert.visible = true;
		},
		async toggleEnv() {
			this.env.forEach( ( e ) => {
				e.status = !e.status;
			} );
			await this.generateList();

			await this.initItems();
		},
		async initItems() {
			this.makes.forEach( ( make ) => {
				this.$set( this.items, make.label, [] );
			} );
			this.originalItems = JSON.parse( JSON.stringify( this.items ) );
			await this.fetchCurrentBPConfig();
		},
		async generateList() {
			const type = this.currentEnv.value;
			const list = [];
			// Skip tadvantageperformance2 for now as it is dedicated to SCE & doesn't have sitemeta routes yet.
			// Skip tadvantagegroupdev as the routes are inaccessible for now.
			this.albs.forEach( ( alb ) => {
				switch ( type ) {
				case 'dev':
					if ( alb.network.match( /staging|dev|.ninja|platinum-test|tadvantageperformance/ ) && alb.network.match( /staging.achilles-portal|tadvantageperformance2|tadvantagegroupdev/ ) === null ) {
						list.push( alb );
					}
					break;
				default:
					if ( alb.network.match( /staging|dev|localhost|.ninja|platinum-test|tadvantageperformance|tadvantageperformance2|tadvantagegroupdev/ ) === null ) {
						list.push( alb );
					}
				}
			} );
			this.networks = list;
		},
		// Fetch config from single network as it is same across all dev or all prod networks.
		async fetchCurrentBPConfig() {
			this.loading = true;
			const params = {
				network: this.networks[0],
				sitemeta: 'oem_build_and_price_config_json',
			};
			try {
				const response = await axios.post( '/wpapi/achilles/sitemeta-list', params );
				if ( response.pass === true && response.data && response.data.oem_build_and_price_config_json ) {
					// Store existing URLs into items & originalItems.
					this.originalBPConfigJSON = response.data.oem_build_and_price_config_json;
					Object.entries( this.originalBPConfigJSON ).forEach( ( [make, models] ) => {
						let id = 0;
						Object.entries( models ).forEach( ( [modelName, value] ) => {
							Object.entries( value ).forEach( ( [year, item] ) => {
								id += 1;
								this.items[make].push( {
									id,
									model: modelName,
									year: year.split( '_' ).map( ( str ) => +str ), // convert to int
									pattern: item.pattern || '',
									segment1: item.segment1 || '',
									mappedModel: item.mappedModel || '',
									segment2: item.segment2 || '',
									dcIncentivesURL: item.dcIncentivesURL || 'siteurl',
									dctype: item.dctype || '',
									intcmp: item.intcmp || 'dc1-OEMID',
									readonly: true,
								} );
							} );
						} );
					} );
					this.originalItems = JSON.parse( JSON.stringify( this.items ) ); // deep clone
				} else {
					this.updated = 'bperror';
					this.message = response.data || 'No Build and Price configuration found!';
					this.loading = false;
					return;
				}
			} catch ( e ) {
				console.log( `${this.$store.state.axiosError} or restful routes not integrated. ${e}` );
			}
			this.loading = false;
		},
		async validateDiff() {
			// no need to validate deleted items.
			for ( const unsavedItem of this.upsertedItems ) {
				this.validateData( unsavedItem );
				if ( !this.validated ) {
					return; // could be multiple errors, so return after first one.
				}
			}
		},
		async updateBPConfig( approved ) {
			this.dialog = false;
			if ( approved ) {
				// retain after state for logging.
				const afterConfig = await this.getFormattedConfig();
				// Save the configuration.
				this.loading = true;
				const networks = this.networks;
				const params = {
					networks,
					sitemeta: {
						oem_build_and_price_config_json: {
							meta_value: afterConfig,
							type: 'json',
						},
					},
				};
				try {
				// either save to all networks or none to keep same config across all networks in selected env all the time.
					const response = await axios.post( '/wpapi/achilles/sitemeta', params );
					const updatedNetworks = [];
					const failedNetworkDomains = [];
					let failed = false;
					for ( const network of networks ) {
						if ( response[network.domain] && response[network.domain].success && response[network.domain].success.oem_build_and_price_config_json ) { // true | meta_id
							updatedNetworks.push( network );
						} else if ( response[network.domain] && !response[network.domain].success ) {
							failed = true;
							failedNetworkDomains.push( network.domain );
						}
					}
					if ( failed ) {
						await this.handleSaveFailure( failedNetworkDomains, updatedNetworks );
					} else {
						await this.handleSaveSuccess( afterConfig );
					}
				} catch ( e ) {
					console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
				}
			}
		},
		async handleSaveFailure( failedNetworkDomains, updatedNetworks ) {
			this.updated = 'bperror';
			this.message = `Failed to update Build and Price configuration in ${failedNetworkDomains.join( ',' )}. So resetting all updated networks..`;
			this.loading = false;
			// sleep for 3 seconds before resetting to show the above msg.
			await new Promise( ( resolve ) => setTimeout( resolve, 3000 ) );
			await this.resetUpdatedNetworks( updatedNetworks );
		},
		async handleSaveSuccess( afterConfig ) {
			this.updated = 'bpsuccess';
			this.message = `Successfully updated Build and Price configuration in ${this.currentEnv.value} networks!`;
			this.loading = false;
			this.resetReadOnly();
			await this.saveLogInfo( afterConfig );
		},
		async saveLogInfo( afterConfig ) {
			const logInfo = {
				user: this.currentUser.username,
				env: this.currentEnv.value,
				oldConfig: this.originalBPConfigJSON,
				newConfig: afterConfig,
				urlsDeleted: !!this.deletedItems.length,
				urlsUpserted: !!this.upsertedItems.length,
			};
			const logResponse = await axios.post( 'create-logs/save-bpconfig', logInfo );
			if ( !logResponse.pass ) {
				console.log( `Unable to save the log for Build & Price configuration change in ${this.currentEnv.value} networks!` );
			}
		},
		async resetUpdatedNetworks( updatedNetworks ) {
			this.loading = true;
			const params = {
				networks: updatedNetworks,
				sitemeta: {
					oem_build_and_price_config_json: {
						meta_value: this.originalBPConfigJSON,
						type: 'json',
					},
				},
			};
			try {
				const resetResponse = await axios.post( '/wpapi/achilles/sitemeta', params );
				for ( const network of updatedNetworks ) {
					if ( resetResponse[network.domain] && resetResponse[network.domain].success && resetResponse[network.domain].success.oem_build_and_price_config_json ) { // true | meta_id
						this.items = JSON.parse( JSON.stringify( this.originalItems ) );
						this.updated = 'bpsuccess';
						this.message = `Build and Price configuration has been reverted to previous state in all ${this.currentEnv.value} networks. Please check if all networks routes are accessible and retry updating it!`;
					}
				}
			} catch ( e ) {
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
			this.resetReadOnly();
		},
		async getFormattedConfig() {
			const sitemetaValue = {};
			Object.entries( this.items ).forEach( ( [make, values] ) => {
				sitemetaValue[make] = {};
				values.forEach( ( item ) => {
					const year = item.year;
					if ( !sitemetaValue[make][item.model] ) {
						sitemetaValue[make][item.model] = {};
					}
					sitemetaValue[make][item.model][year.join( '_' )] = {
						pattern: item.pattern,
						year: year.join( ',' ),
						segment1: item.segment1,
						mappedModel: item.mappedModel,
						segment2: item.segment2,
						dcIncentivesURL: item.dcIncentivesURL,
						dctype: item.dctype,
						intcmp: item.intcmp,
					};
				} );
			} );
			return sitemetaValue;
		},
		resetReadOnly() {
			this.originalItems = JSON.parse( JSON.stringify( this.items ) ); // deep clone
			this.makes.forEach( ( make ) => {
				this.items[make.label].forEach( ( item ) => {
					item.readonly = true;
				} );
			} );
		},
		setMake( make ) {
			this.makes.forEach( ( m ) => {
				m.status = m.id === make.id;
			} );
		},
		addItem() {
			const nextId = this.items[this.activeTab.label] ? this.items[this.activeTab.label].length : 0;
			const newItem = {
				id: nextId + 1,
				model: '',
				year: [],
				pattern: '',
				segment1: '',
				mappedModel: '',
				segment2: '',
				dcIncentivesURL: 'siteurl',
				dctype: '',
				intcmp: 'dc1-OEMID',
				readonly: false,
			};
			this.search = ''; // clear search to make the new entry always visible.
			this.items[this.activeTab.label].unshift( newItem );
		},
		deleteItem( id ) {
			const index = this.items[this.activeTab.label].findIndex( ( item ) => item.id === id );
			this.items[this.activeTab.label].splice( index, 1 );
		},
		editItem( id ) {
			for ( const item of this.items[this.activeTab.label] ) {
				if ( item.id === id ) {
					item.readonly = !item.readonly;
					if ( item.readonly ) {
						// validation before saving client version.
						this.validateData( item );
					}
					break;
				}
			}
		},
		validateData( item ) {
			this.alert.visible = false;

			if ( item.model === '' || item.year.length === 0 || item.mappedModel === '' || item.pattern === '' ) {
				this.showAlert( 'Please fill in mandatory fields!' );
				item.readonly = false;
				this.validated = false;
				return;
			}

			if ( item.model && item.year.length ) {
				// check all items except current one matching the model.
				const sameModelItems = this.currentItems.filter( ( i ) => i.model === item.model && i.id !== item.id );

				for ( const i of sameModelItems ) {
					// check if any year in current item matches any year in the existing model items.
					// we don't want to save duplicate data.
					if ( item.year.some( ( year ) => i.year.includes( year ) ) ) {
						this.showAlert( 'Model and Year combination already exists, try editing it instead!' );
						item.readonly = false;
						this.validated = false;
						return;
					}
				}
			}

			const patternArr = item.pattern.split( '?' );
			const segmentsArr = patternArr[0].split( '/' );
			const qsArr = patternArr[1] ? patternArr[1].split( '&' ) : [];
			if ( patternArr.length < 2 || segmentsArr.length < 1 || patternArr[0].includes( '//' ) ) {
				this.showAlert( 'Please enter a valid URL pattern!' );
				item.readonly = false;
				this.validated = false;
				return;
			}
			if ( !segmentsArr.includes( ( 'mappedModel' ) ) ) {
				this.showAlert( 'Please include mappedModel in the URL pattern!' );
				item.readonly = false;
				this.validated = false;
				return;
			}

			for ( const segment of ['segment1', 'segment2'] ) {
				if ( segmentsArr.includes( segment ) && item[segment] === '' ) {
					this.showAlert( `${segment} is part of the pattern. Please enter a value!` );
					item.readonly = false;
					this.validated = false;
					return;
				}
				if ( !segmentsArr.includes( segment ) && item[segment] !== '' ) {
					this.showAlert( `${segment} is not part of the pattern. Please remove the value!` );
					item.readonly = false;
					this.validated = false;
					return;
				}
			}

			for ( const qs of ['dctype', 'intcmp'] ) {
				if ( qsArr.includes( qs ) && item[qs] === '' ) {
					this.showAlert( `${qs} is part of the pattern. Please enter a value!` );
					item.readonly = false;
					this.validated = false;
					return;
				}
				if ( !qsArr.includes( qs ) && item[qs] !== '' ) {
					this.showAlert( `${qs} is not part of the pattern. Please remove the value!` );
					item.readonly = false;
					this.validated = false;
					return;
				}
			}

			this.validated = true;
		},
		async downloadBPConfigHistory() {
			this.loading = true;
			try {
				const response = await axios.get( 'create-logs/download-bpconfig' );
				if ( !response ) {
					throw new Error( 'No changes found!' );
				}
				// Download CSV.
				const csvContent = `data:text/csv;charset=utf-8,${response}`;
				const encodedUri = encodeURI( csvContent ).replace( /#/g, '%23' );
				const link = document.getElementById( 'download' );
				link.setAttribute( 'href', encodedUri );
				link.setAttribute( 'download', `Achilles Portal - BPConfigHistory - ${format( Date.now(), 'YYYY-MM-DD' )}.csv` );
				link.click();

				this.message = 'Successfully downloaded BPConfigHistory csv!';
				this.updated = 'bpsuccess';
			} catch ( e ) {
				this.message = `Failed to download BPConfigHistory csv:, ${e}`;
				this.updated = 'bperror';
			}
			this.loading = false;
		},
	},
};
</script>

<style lang="scss">
	.ap__input__label {
		color:#78be20;
		font-weight: bold;
	}

	.v-card.v-card-bp {
		margin-top: 30px;

		.bp-config-link {
			align-self: center;
			margin-left: auto;
			margin-right: 40px;
			color: #1976d2;
			text-decoration: underline;
			font-weight: 510;
		}

		.bp-config-link:hover {
			color: #40a9ff;
		}

		.v-card__title {
			display: flex;
			justify-content: space-between;

			.ap__button {
				line-height: 1.2rem;
			}
		}

		.ap__table__footer {
			bottom: 8px !important;
			padding: 0 !important;
			position: relative;

			.ap__button {
				line-height: 1.6rem !important;
			}
		}

		.ap__notification {
			font-size: 14px;
			padding: 10px;

			&.bperror {
				color: red;
			}

			&.bpsuccess {
				color: #78be20;
				font-size: 14px;
			}
		}
	}
</style>
