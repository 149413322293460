import axios from 'axios';

const state = {
	options: [],
	customizerParams: [],
};
const getters = {
	allOptions( s ) {
		return s.options;
	},
	allCustomizerParams( s ) {
		return s.customizerParams;
	},
};
const mutations = {
	setAllOptions( s, options ) {
		s.options = options;
	},
	setAllCustomizerParams( s, customizerParams ) {
		s.customizerParams = customizerParams;
	},
};
const actions = {
	async updatePossibleOptions( { commit } ) {
		const response = await axios.get( '/api/possible-options' );
		commit( 'setAllOptions', response );
	},
	async updateCustomizerParams( { commit } ) {
		const response = await axios.get( '/api/possible-customizer-params' );
		commit( 'setAllCustomizerParams', response );
	},
};
export default {
	getters, mutations, actions, state,
};
