<template>
  <div class="ap__item">
    <v-data-table
      :headers="headers"
      :items="sites"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ dateCreated( item.created ) }}
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ time(item.start, item.end) }}
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <v-btn
          color="success"
          :href="`${item.destination.prefix}://${item.account}.${item.destination.domain}`"
          target="_blank"
        >
          Link
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			headers: [{
				text: 'Account',
				value: 'account',
			},
			{
				text: 'Destination',
				value: 'destination.domain',
			},
			{
				text: 'Created',
				value: 'created',
			},
			{
				text: 'Time',
				value: 'time',
			},
			{
				text: 'Link',
				value: 'link',
			}],
		};
	},
	computed: mapGetters( {
		sites: 'completedSites',
	} ),
	methods: {
		time( start, end ) {
			const dateStart = new Date( start );
			const dateEnd = new Date( end );
			const diff = dateEnd.getTime() - dateStart.getTime();

			let msec = diff;
			const hh = Math.floor( msec / 1000 / 60 / 60 );
			msec -= hh * 1000 * 60 * 60;
			const mm = Math.floor( msec / 1000 / 60 );
			msec -= mm * 1000 * 60;
			const ss = Math.floor( msec / 1000 );
			msec -= ss * 1000;

			let result = '';

			if ( hh !== 0 ) {
				result += `${hh} hours `;
			}

			return `${result}${mm} minutes ${ss} seconds`;
		},
		dateCreated( created ) {
			const date = new Date( created );
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const local = date.toLocaleString( 'en-US', { timeZone } );
			return local.replace( ', ', ' at ' );
		},
	},
};
</script>
