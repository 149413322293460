<template>
  <div class="validate">
    <select
      v-model="selected"
      class="validate__select"
      multiple
    >
      <option
        v-for="file in files"
        :key="file"
        :value="file"
      >
        {{ file }}
      </option>
    </select>
    <ap-button
      :disabled="disabled"
      value="Validate File(s)"
      @clicked="validate"
    />
    <div class="ap__label">
      Response
    </div>
    <convertus-output :section="{disabled, output, results}" />
  </div>
</template>
<script>
export default {
	data() {
		return {
			selected: [],
			files: [],
			disabled: false,
			output: {},
			results: {},
		};
	},
	mounted() {
		// let xhr = new XMLHttpRequest();
		// xhr.onload = () => {
		// 	if (xhr.status === 200) {
		// 		this.files = JSON.parse(xhr.response);
		// 	}
		// };
		// xhr.open("GET", "/api/files", true);
		// xhr.send();
	},
	methods: {
		validate() {
			this.disabled = true;
			const data = JSON.stringify( {
				files: JSON.stringify( this.selected ),
			} );

			const xhr = new XMLHttpRequest();
			xhr.onload = () => {
				if ( xhr.status === 200 ) {
					const res = JSON.parse( xhr.response );
					console.log( res );
					this.output = res;
					// this.results = res;
					this.disabled = false;
				}
			};
			xhr.open( 'POST', '/api/validate', true );
			xhr.setRequestHeader(
				'Content-Type',
				'application/json;charset=UTF-8',
			);
			xhr.send( data );
		},
	},
};
</script>
<style lang="scss">
.validate {
	&__select {
		height: 240px;
	}
}
</style>
