<template>
  <div class="ap__section">
    <div class="ap__title">
      DNS
    </div>
    <v-card>
      <ap-loading :loading="loading" />
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dns"
        :search="search"
      >
	  	<template v-slot:item.accountId="{ item }">
          {{ item.accountId || "N/A" }}
        </template>
		<template :class="getColor(item.wwwDomainStatus) + '--text'" v-slot:item.wwwDomainStatus="{ item }">
          {{ getStatusText(item.wwwDomainStatus) }}
        </template>
		<template v-slot:item.wwwDomainStatus="{ item }">
          <span :class="getColor(item.wwwDomainStatus) + '--text'"> {{ getStatusText(item.wwwDomainStatus) }} </span>
        </template>
		<template v-slot:item.lastDomainStatusCheckAt="{ item }">
          {{ item.lastDomainStatusCheckAt | dateFormat }} </span>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Refresh"
          @clicked="update"
        />
        <ap-button
          :disabled="loading"
          value="Download CSV"
          @clicked="csv"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import download from '../../js/mixins/download';

export default {
	mixins: [download],
	data() {
		return {
			search: '',
			loading: false,
			updated: '',
			message: '',
			headers: [{
				text: 'Account ID',
				value: 'accountId',
				align: 'center',
			},
			{
				text: 'Domain',
				value: 'name',
				align: 'center',
			},
			{
				text: 'Cloud Front',
				align: 'center',
				value: 'cfRecord',
				sortable: false,
			},
			{
				text: 'www Status',
				align: 'center',
				value: 'wwwDomainStatus',
				sortable: true,
			},
			{
				text: 'Last Update',
				align: 'center',
				sortable: false,
				value: 'lastDomainStatusCheckAt'
			}],
		};
	},
	computed: mapGetters( {
		dns: 'allDns',
		user: 'user',
	} ),
	filters: {
		dateFormat(utcDate) {
			if(utcDate == null)
				return "N/A";

			let dateObj = new Date(utcDate);
			return dateObj.toDateString() +", "+ dateObj.toLocaleTimeString();
		}
	},
	methods: {
		getColor( status ) {
			if ( status === 1 ) {
				return 'green';
			}
			return 'red';
		},
		getStatusText( status ) {
			if ( status === 0 ) {
				return 'PENDING';
			}
			return 'SUCCESS';
		},
		async update() {
			this.loading = true;
			await this.$store.dispatch( 'updateAllDNS' );
			this.loading = false;
		},
		csv() {
			let self = this;
			if ( this.dns.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			const results = this.dns.map( ( f ) => {
				let temp = {};
				temp.accountId = f.accountId || "N/A";
				temp.name = f.name || "N/A";
				temp.cfRecord = f.cfRecord || "N/A";
				temp.wwwDomainStatus = (f.wwwDomainStatus != null) ? self.getStatusText(f.wwwDomainStatus) : "N/A";
				temp.lastDomainStatusCheckAt = (f.lastDomainStatusCheckAt != null) ? self.$options.filters.dateFormat(f.lastDomainStatusCheckAt) : "N/A";
				return temp;
			});
			this.updated = '';
			this.message = '';
			this.loading = true;
			const response = this.download( results, this.headers.map( ( h ) => h.text ), 'DNS' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
table {
    th {
        &:last-child {
            padding: 0px 0px 0px 50px;
        }
    }
    td {
        &:last-child {
            padding: 0px 0px 0px 50px;
        }
    }
}
</style>