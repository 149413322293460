<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Select Network Sites
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disabled"
        value="Clear All Accounts"
        @clicked="accounts = []"
      />
      <ap-button
        :disabled="disabled"
        value="Select All Accounts"
        @clicked="accounts = JSON.parse(JSON.stringify( accountList ))"
      />
    </div>

    <div class="ap__label">
      Network & Accounts
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="network"
          :options="albs"
          label="network"
          @input="updateAccountList"
        />
        <div class="ap__input__desc">
          Which network to query.
        </div>
      </div>
      <div class="ap__input">
        <label>Accounts</label>
        <v-select
          v-model="accounts"
          :options="accountList"
          label="domain"
          multiple
        />
        <div class="ap__input__desc">
          Which account(s) to query.
        </div>
      </div>
    </div>
    <div class="ap__label">
      Migrate Genesisbase Page
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Slugs</label>
        <input
          v-model="slugs"
          type="text"
        >
        <div class="ap__input__desc">
          Page slugs delimtted by commas. Ex; thank-you,about
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disabled"
        value="Migrate Page"
        @clicked="migratePage"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			accountList: [],
			accounts: [],
			network: {},
			slugs: '',
			output: {},
			disabled: false,
			loading: false,
		};
	},
	computed: mapGetters( { albs: 'allALBs' } ),
	mounted() {
		if ( this.albs.length > 0 ) {
			this.network = this.albs[0];
			this.updateAccountList();
		}
	},
	methods: {
		async migratePage() {
			this.loading = true;
			this.output = {};

			// Check slug is not empty or a random comma.
			if ( this.slugs.replace( /,/, '' ).length === 0 ) {
				this.output = 'Please enter slug(s).';
				this.loading = false;
				return;
			}

			// Request page from genesis, migrate to network.
			try {
				let response = await axios.get( `https://www.genesisbase.cvrt.us/wp-json/convertus/v1/pages?slugs=${this.slugs}` );
				if ( response.pass === false ) {
					this.output = response.data;
				} else {
					response = await axios.post( '/wpapi/achilles/pages', {
						network: this.network,
						accounts: this.accounts,
						pages: response.data,
					} );
					this.output = response;
				}
			} catch ( e ) {
				console.log( e );
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updateAccountList() {
			this.loading = true;
			try {
				const response = await axios.get( `${this.network.prefix}://${this.network.domain}/wp-json/achilles/v1/sites` );
				const accountList = Object.values( response );
				this.accountList = JSON.parse( JSON.stringify( accountList ) );
				this.accounts = [accountList[0]];
			} catch ( e ) {
				this.accountList = [];
				this.accounts = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
	},
};
</script>
