import axios from 'axios';

const state = {
	groups: [{ name: 'all' }, { name: 'pending' }],
	logs: [],
	cloudflareLogs: [],
	domains: [],
	newDomains: [],
};
const getters = {
	sslGroups( s ) {
		return s.groups;
	},
	sslDomains( s ) {
		return s.domains;
	},
	newSslDomains( s ) {
		return s.newDomains;
	},
	sslLogs( s ) {
		return s.logs;
	},
	cloudflareLogs( s ) {
		return s.cloudflareLogs;
	},
};

const mutations = {
	setSSLGroups( s, groups ) {
		s.groups = groups;
	},
	setSSLDomains( s, domains ) {
		s.domains = domains;
	},
	setNewSSLDomains( s, domains ) {
		s.newDomains = domains;
	},
	setSSLLogs( s, logs ) {
		s.logs = logs;
	},
    setCloudflareLogs( s, logs ) {
		s.cloudflareLogs = logs;
	},
};

const actions = {
	async updateGroups( { commit, rootState } ) {
		let groups = [{ name: 'all' }, { name: 'pending' }, { name: 'failed' }];
		try {
			const response = await axios.get( '/db/groups' );
			if ( response.pass === true ) {
				// Sort alphabetically.
				response.data.sort( ( a, b ) => {
					const aName = a.name.toUpperCase();
					const bName = b.name.toUpperCase();
					let result = 0;

					if ( aName < bName ) {
						result = -1;
					} else if ( aName > bName ) {
						result = 1;
					}
					return result;
				} );
				groups = groups.concat( response.data );
				commit( 'setSSLGroups', groups );

				// this.table.filter.group.name = 'all';
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
	async updateDomains( { commit, rootState } ) {
		try {
			const response = await axios.get( '/aws/ssl' );
			if ( response.pass === true ) {
				commit( 'setSSLDomains', response.data );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
    async updateNewDomains( { commit, rootState } ) {
		try {
			const response = await axios.get( '/cloudflare' );
			if ( response.pass === true ) {
				commit( 'setNewSSLDomains', response.data );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
	async updateLogs( { commit, rootState } ) {
		try {
			const response = await axios.get( '/db/certificate-logs' );
			if ( response.pass === true ) {
				commit( 'setSSLLogs', response.data );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
	async updateCloudflareLogs( { commit, rootState } ) {
		try {
			const response = await axios.get( '/cloudflare/sync-logs' );
			if ( response.pass === true ) {
				commit( 'setCloudflareLogs', response.data );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};

export default {
	state, getters, actions, mutations,
};
