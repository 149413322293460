<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Manually Run Crons
    </div>
    <ul>
      <li>Sync Network Data With AWS - Manually run cron that updates all network instance ips, health status, and versions in db. This cron is set to run every 30 minutes.</li>
      <li>Sync Domains Data with AWS - Manually run cron that updates all domain distribution ids, cloudfront records, and distribution arn. This cron is set to run every hour.</li>
    </ul>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disabled"
        value="Sync Network Data With AWS"
        @clicked="updateALBs"
      />
      <ap-button
        :disabled="disabled"
        value="Sync Distribution Data With AWS"
        @clicked="updateCFDistributions"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';

export default {
	data() {
		return {
			loading: false,
			disabled: false,
			output: {},
		};
	},
	methods: {
		async updateCFDistributions() {
			this.loading = true;
			try {
				this.output = {};
				const response = await axios.get( '/aws/cloudfront/update' );
				this.output = response;
			} catch ( e ) {
				console.log( e );
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updateALBs() {
			this.loading = true;
			try {
				this.output = {};
				const response = await axios.get( '/aws/alb/update' );
				this.output = response;
			} catch ( e ) {
				console.log( e );
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
ul { padding-bottom: 15px; }
</style>
