<script src="../../../../server/routes/update.js"></script>
<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Tag OEM to Domains
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="query.disabled"
        value="Import all domains"
        @clicked="importAllDomains"
      />
      <ap-button
        :disabled="queryDomainDisable"
        value="Query OEM of selected domains"
        @clicked="displayOEMDomain"
      />
      <ap-button
        :disabled="queryDomainDisable"
        value="Reset OEM values to WP platform."
        @clicked="openModalForReset"
      />
      <ap-button
        :disabled="overrideDisable"
        value="Override Values"
        @clicked="openModalForOverride"
      />
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="query.network"
          :options="albs"
          label="network"
          @input="updateAccountList"
        />
        <div class="ap__input__desc">
          Which network to query.
        </div>
      </div>
      <div class="ap__input">
        <label>Accounts</label>
        <v-select
          v-model="query.accounts"
          :options="query.accountList"
          label="domain"
          multiple
          @input="updateProfileList"
        />
        <div class="ap__input__desc">
          Which account(s) to query.
        </div>
      </div>
      <div class="ap__input">
        <label>OEM value</label>
        <input
          v-model="newOEM"
          type="text"
        >
        <div class="ap__input__desc">
          Will be replaced by this:
        </div>
      </div>
    </div>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="results"
        :search="search"
      >
        <template v-slot:item.account="{ item }">
          <a
            :href="'http://' + item.account"
            target="_blank"
          >
            {{ item.account }}
          </a>
        </template>
      </v-data-table>
      <div class="ap__table__footer" />
    </v-card>

    <v-dialog
      v-model="showConfirmationDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Reset domain OEM value.</v-card-title>
        <v-card-text v-if="resetValue">
          Are you sure you want to reset the values ?
        </v-card-text>
        <v-card-text v-if="overrideValue">
          Are you sure you want to override the values ?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              v-if="overrideValue"
              color="primary"
              @click="override"
            >
              Yes, Overrid.
            </v-btn>
            <v-btn
              v-if="resetValue"
              color="primary"
              @click="importSelectedDomain"
            >
              Yes, Reset.
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeModal"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div
      class="ap__notification"
      :class="updated"
    />{{ message }}
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { io } from 'socket.io-client';
import json from 'format-json';

export default {
  name: 'Domain',
  data() {
    return {
      loading: false,
      search: '',
      message: '',
      updated: '',
      headers: [],
      results: [],
      newOEM: '',
      overrideFromWP: false,
      query: {
        disabled: false,
        accountList: [],
        accounts: [],
        originalUrl: '',
        targetUrl: '',
        network: {},
        pagesList: [],
        activate: true,
        profileAccountlist: [],
      },
      profile: {
        type: 'Profile',
        profile: '',
        parent: '',
        disabled: false,
        accountList: [],
        siteList: [],
      },
      showConfirmationDialog: false,
      resetValue: false,
      overrideValue: false,
    }
  },
  computed: { ...mapGetters( {
      albs: 'allALBs',
      possibleOptions: 'allOptions',
    }),
    overrideDisable: function() {
      return ( this.newOEM.length === 0 || this.query.accounts.length === 0 );
    },
    queryDomainDisable: function() {
      return ( this.query.accounts.length === 0 );
    }
  },
  mounted() {
    if ( this.albs.length > 0 ) {
      this.query.network = this.albs[0];
      this.updateAccountList();
    }
  },
  methods: {
    async importAllDomains() {
      try {
        this.loading = true;
        const response = await axios.post( '/wpapi/sliderbroadcast/importaccounts', {
          network: this.query.network,
          overrideFromWP: false
        });
        if ( response.pass === false ) {
          this.updated = 'error';
          this.message = 'Error happened, please try again later.';
          this.loading = false;
        }
        else if ( response.error.length !== 0 ) {
          this.updated = 'success';
          this.message = 'Some domains did not get updated.';
          this.loading = false;
        }  else {
          this.updated = 'success';
          this.message = 'Successfully update all domains of the selected network.';
          this.loading = false;
        }
      } catch ( e ) {
        this.updated = 'error';
        this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
        this.loading = false;
      }
    },
    async importSelectedDomain() {
      this.loading = true;
      try {
        console.log(this.query.network);
        const response = await axios.post( '/wpapi/sliderbroadcast/importaccounts', {
          network: this.query.network,
          accounts: this.query.accounts,
          overrideFromWP: true,
        });
        const modifiedAccounts = response.success.map( ( { domain, OEM } ) =>
            ( { domain, OEM } )
        );

        if ( response.pass === false ) {
          this.updated = 'error';
          this.message = 'Error happened, please try again later.';
          this.loading = false;
        }
        else if ( response.error.length !== 0 ) {

          this.updated = 'success';
          this.updateTable( modifiedAccounts );

          this.message = 'Some domains were not updated.';
          this.loading = false;
        } else {
          this.updateTable( modifiedAccounts );

          this.updated = 'success';
          this.message = 'Successfully update selected domains.  ';
          this.loading = false;
        }
      } catch ( e ) {
        this.loading = false;
        this.updated = 'error';
        this.message = `${this.$store.state.axiosError} or restful routes not integrated.`
      }
      this.closeModal();
    },
    async displayOEMDomain() {
      try {
        this.loading = true;
        const sites = this.query.accounts.map( ( a ) => a.domain );

        const accounts = await axios.get( `/wpapi/sliderbroadcast/${this.query.network.domain}/domains` );
        const filteredAccounts = accounts.data.filter( (account) => {
          return ( sites.includes(account.domain) );
        }).map(account => {
          return {
            domain: account.domain,
            oem:  account.OEM,
          }
        });

        this.updateTable(filteredAccounts);
        this.loading = false;
      } catch ( e ) {
        this.updated = 'error';
        this.loading = false;
        this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
      }
    },
    async override() {
      this.loading = true;
      try {
        const sites = this.query.accounts.map( ( a ) => a.domain );
        const response = await axios.post( '/wpapi/sliderbroadcast/override', {
          network: this.query.network,
          sites,
          newOEM: this.newOEM,
        });
        if ( !response.pass ) {
          this.updated = 'error';
          this.message = 'Error happened, please try again later.';
          this.loading = false;
        } else {
          this.updateTable( response.data );
          this.updated = 'success';
          this.message = 'Successfully update selected domains.';
          this.loading = false;
        }
      } catch ( e ) {
        console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
      }
      this.closeModal();
    },
    async updateProfileList() {
      this.loading = true;
      try {
        const response = await axios.get(
            `${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/blogmeta-siteprofile`,
        );
        const output = {};
        const domains = [];
        this.query.accounts.forEach( ( account ) => {
          domains.push( account.domain );
        } );

        if ( Array.isArray( domains ) && domains.length !== 0 ) {
          Object.entries( response ).forEach( ( entry ) => {
            const domain = entry[0];
            if ( domains.includes( domain ) ) {
              output[domain] = entry[1];
            }
          } );
        } else {
          output.error = 'Please select an account.';
        }

        this.profileViewOutput = output;
      } catch ( e ) {
        this.profileViewOutput = `${this.$store.state.axiosError} or restful routes not integrated.`;
      }
      this.loading = false;
    },
    async updateAccountList() {
      this.updateProfileList();
      this.message = '';
      this.updated = '';
      this.loading = true;
      try {
        const response = await axios.get(
            `${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/sites`,
        );
        const accountList = Object.values( response );
        this.query.accountList = JSON.parse( JSON.stringify( accountList ) );
        this.query.accounts = [accountList[0]];

        this.profile.accountList = [{
          blog_id: '',
          domain: 'None',
        }, ...accountList];
        this.profile.profile = this.profile.accountList[0];
        this.profile.parent = this.profile.accountList[0];
      } catch ( e ) {
        this.query.accountList = [];
        this.profile.accountList = [];
        this.query.accounts = [];
        console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
      }
      this.loading = false;
    },
    async queryNetwork() {
      this.updated = '';
      this.message = '';
      this.headers = [];
      this.results = [];
      if ( this.query.network.auth.key === '' ) {
        this.updated = 'error';
        this.message = 'Missing api key in db for this network.';
        return;
      }
      if ( this.query.network.auth.basic === '' ) {
        this.updated = 'error';
        this.message = 'Missing basic header in db for this network.';
        return;
      }

      axios.post( '/aws/ssl', {
        network: this.query.network,
      } );
    },
    updateTable( data ) {
      const results = [];
      const headers = [];
      data = Object.entries( data );
      if ( data.length !== 0 ) {
        data.forEach( ( e ) => {
          Object.entries( e[1] ).forEach( ( en ) => {
            if ( en[1].constructor !== String ) {
              const str = json.space( en[1] );
              e[1][en[0]] = str;
            }
          } );
          results.push( {
            account: e[0],
            ...e[1],
          } );
        } );
        Object.keys( data[0][1] ).forEach( ( h ) => headers.push( { text: h, value: h } ) );
      }

      this.headers = headers;
      this.results = results;
    },
    closeModal() {
      this.resetValue = false;
      this.overrideValue = false;
      this.showConfirmationDialog = false;
    },
    openModal() {
      this.showConfirmationDialog = true;
    },
    openModalForReset() {
      this.resetValue = true;
      this.overrideValue = false;
      this.openModal();
    },
    openModalForOverride() {
      this.overrideValue = true;
      this.resetValue = false;
      this.openModal();
    },
  }
};
</script>

<style scoped>

</style>
