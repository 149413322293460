<template>
  <div class="ap__section">
    <div class="ap__title">
      Custom Query
    </div>
    <v-tabs color="white">
      <v-tab
        v-for="tab in tabs"
        :key="tab.label"
        ripple
        @click="changeActiveTab(tab)"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="ap__tab__content">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import QueryNetworkSites from './customQuery/QueryNetworkSites.vue';
import QueryNetwork from './customQuery/QueryNetwork.vue';
import QueryPortalDatabase from './customQuery/QueryPortalDatabase.vue';

export default {
	components: { QueryNetworkSites, QueryNetwork, QueryPortalDatabase },
	data() {
		return {
			tabs: [{
				label: 'Query Sites in Network',
				component: 'QueryNetworkSites',
			}, {
				label: 'Query Networks',
				component: 'QueryNetwork',
			}, {
				label: 'Query Local Achilles Portal Database',
				component: 'QueryPortalDatabase',
			}],
			activeTab: 'QueryNetworkSites',
		};
	},
	methods: {
		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
	},
};
</script>