<template>
  <div style="position: relative;">
    <ap-loading :loading="loading" />
    <p>
      Only domains that <b>exist in production networks</b> and have a <b>cloudfront distribution</b> display here.<br>
      The following explains how this tool is used and its pre-requisites.
    </p>
    <ol>
      <li>Make sure your site exists on the network your transfering it to.</li>
      <li>Make sure your site ran the find and replace to map the old url to the new url ( live url ).</li>
      <li>Select the live url(s) from the domain(s) list.</li>
      <li>Select the network you'd like to transfer these sites over to.</li>
      <li>Run the switch networks button and read the output for any error(s).</li>
    </ol>
    <p>The above will re-group all your url SSLs into the new network in the Achille's AWS infrastructure, and point all the distributions to the new network.</p>
    <div class="ap__input__wrap">
      <!-- <div class="ap__input">
                <label>Domain</label>
                <input type="text" v-model="domains">
                <div class="ap__input__desc">Domain to request ssl for.</div>
            </div> -->
      <div class="ap__input">
        <label>Domain(s)</label>
        <v-select
          v-model="domains"
          :options="filteredDomains"
          label="name"
          multiple
        />
        <div class="ap__input__desc">
          Which domains to transfer.
        </div>
      </div>

      <div class="ap__input">
        <label>Network</label>
        <v-select
          v-model="network"
          :options="prodALBs"
          label="network"
        />
        <div class="ap__input__desc">
          Which network is this domain transfering to.
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="loading"
        value="Switch Networks"
        @clicked="switchNetwork"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			loading: false,
			domains: [],
			output: {},
			network: {},
		};
	},
	computed: {
		...mapGetters( ['sslDomains', 'prodALBs'] ),
		filteredDomains() {
			const domains = [];
			this.sslDomains.forEach( ( d ) => {
				if ( d.apexSSLGroup === 'SUCCESS' && d.wwwSSLGroup === 'SUCCESS' && d.cfRecord !== 'na' ) {
					domains.push( d );
				}
			} );
			return domains;
		},
	},
	methods: {
		async switchNetwork() {
			this.output = {};

			if ( Object.keys( this.network ).length === 0 || !( 'network' in this.network ) ) {
				this.output = 'Please select a valid network';
				return;
			}

			// Select network and domain(s).
			if ( Object.keys( this.network ).length === 0 ) {
				this.output = 'Please select a network to transfer to.';
				return;
			}
			if ( this.domains.length === 0 ) {
				this.output = 'Please select a domain to transfer.';
				return;
			}

			// Check to see each domain is not in the same network as the switching one.
			const errors = [];
			this.domains.forEach( ( d ) => {
				if ( d.network === this.network ) {
					errors.push( `${d.name} is already inside network ${this.network}.` );
				}
			} );
			if ( errors.length > 0 ) {
				this.output = errors;
				return;
			}

			// Send request to transfer domains then output.
			this.loading = true;
			try {
				const response = await axios.post( '/aws/migrate', { domains: this.domains, network: this.network } );
				this.output = response;
			} catch ( e ) {
				console.log( e );
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
	},
};
</script>
