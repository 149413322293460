<template>
  <div class="ap__section">
    <!-- Loading Circle -->
    <ap-loading :loading="loading" />

    <!-- Warning Popup -->
    <v-dialog
      v-model="warning"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Warning
        </v-card-title>

        <v-card-text>
          {{ warningMsg }} Are you sure you want to do this?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1 white--text"
            @click="warning = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1 white--text"
            @click="migrateSites"
          >
            Migrate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Bar -->
    <div
      v-if="migration"
      class="loading"
    >
      <v-progress-linear
        v-model="percent"
        height="25"
        color="#78be20"
      >
        <strong>{{ progress }} <span v-if="subprogress !== ''">{{ subprogress }}</span> <span v-if="entryprogress !== ''">{{ entryprogress }}</span></strong>
      </v-progress-linear>
    </div>

    <!-- Migration -->
    <div class="ap__title">
      Migrate
    </div>
    <div class="ap__item">
      <div class="ap__title--sub">
        Migrate Site
      </div>
      <ul>
        <li><strong>Inputs</strong></li>
        <li>Sites displayed will be based on the source network.</li>
        <li>Migrate sites between two of the listed networks below.</li>
        <li>To duplicate a site, select the same network for source and destination, then set the new subdomain and blog name.</li>
        <li>A warning will pop up if a site with the same blog name or domain already exists in the destination network.</li>
        <li>The site will slowly populate after clicking the migrate sites button.</li>
        <li><strong>Important</strong></li>
        <li>The message "Network error while making POST request to https://site.network.com/wp-json/achilles/v1/import-db or request took longer than 30seconds to respond." is harmless and can be ignored.</li>
        <li>After migrating, we have to click the <strong>'Flush Cache'</strong> button on the network we migrated the site to. You can find this in the Network Admin wp-admin -> settings -> redis panel.</li>
        <li>Make sure no images in the source site reference another source site's media library.</li>
      </ul>
      <ul />
      <div class="ap__input__wrap">
        <div class="ap__input">
          <label>Site</label>
          <v-select
            v-model="data.site"
            :options="siteList"
            label="domain"
          />
          <div class="ap__input__desc">
            Select site to migrate.
          </div>
        </div>
        <div class="ap__input">
          <label>New Subdomain</label>
          <input
            v-model="data.subdomain"
            type="text"
          >
          <div class="ap__input__desc">
            Defaults to original subdomain.
          </div>
        </div>
        <div class="ap__input">
          <label>New Blog Name</label>
          <input
            v-model="data.blogname"
            type="text"
          >
          <div class="ap__input__desc">
            Defaults to original blogname.
          </div>
        </div>
        <div class="ap__input">
          <label>Source Network</label>
          <v-select
            v-model="data.from"
            :options="albs"
            label="network"
            @input="updateSiteList"
          />
          <div class="ap__input__desc">
            Network migrating from.
          </div>
        </div>
        <div class="ap__input">
          <label>Destination Network</label>
          <v-select
            v-model="data.to"
            :options="albs"
            label="network"
            @input="updateDestSubdomains"
          />
          <div class="ap__input__desc">
            Network migrating to.
          </div>
        </div>
        <div class="ap__input">
          <label />
          <v-checkbox
            v-model="data.imagesOnly"
            hide-details
            color="#78be20"
            label="Images only?"
          />
        </div>
      </div>
      <div
        class="ap__notification"
        :class="nclass"
      >
        {{ message }}
      </div>
      <div class="ap__wrap--btn">
        <ap-button
          :disabled="loading"
          value="Migrate Site"
          @clicked="confirmMigration"
        />
      </div> <ap-response :json="output" />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { io } from 'socket.io-client';
import Pusher from 'pusher-js';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			nclass: '',
			message: '',
			warning: false,
			warningMsg: '',
			percent: 0,
			progress: '',
			subprogress: '',
			entryprogress: '',
			loading: false,
			migration: false,
			siteList: [],
			destSubdomains: [],
			output: {},
			data: {
				from: {},
				to: {},
				site: {},
				sites: [],
				subdomain: '',
				blogname: '',
				imagesOnly: false,
			},
			socket: null,
		};
	},
	computed: mapGetters( { albs: 'allALBs' } ),
	async mounted() {
		if ( this.albs.length !== 0 ) {
			this.data.from = this.albs[0];
			this.data.to = this.albs[1];
			this.updateSiteList();
			this.updateDestSubdomains();
			this.connectToSocket();
		}
	},
	methods: {
		async updateSiteList() {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get( `${this.data.from.prefix}://${this.data.from.domain}/wp-json/achilles/v1/sites` );
				const siteList = Object.values( response );
				this.siteList = siteList;
			} catch ( e ) {
				this.siteList = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.data.sites = [];
			this.loading = false;
		},
		async updateDestSubdomains() {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get( `${this.data.to.prefix}://${this.data.to.domain}/wp-json/achilles/v1/sites` );
				const siteList = Object.values( response );
				const subdomains = [];

				siteList.forEach( ( site ) => {
					if ( site.domain.includes( this.data.to.domain ) && site.domain.split( '.' ).length === 3 && site.domain.split( '.' )[0] !== 'www' ) {
						subdomains.push( site.domain.split( '.' )[0] );
					}
				} );

				this.destSubdomains = subdomains;
			} catch ( e ) {
				this.destSubdomains = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async confirmMigration() {
			let defaultsub = this.data.site.domain.split( '.' )[0];

			// If migrating a production site to another network.
			if ( defaultsub === 'www' ) {
				defaultsub = this.data.site.domain.split( '.' )[1];
			}

			// Check if site with same blogname already exists in destination.
			this.loading = true;
			const response = await axios.post( '/wpapi/achilles/check-site-exists', { ...this.data } );
			this.loading = false;

			if ( response.pass === true ) {
				this.warningMsg = `${response.data.domain} has the same blog name as the site you are migrating, so this site will be overwritten with new data.`;
				this.warning = true;
			} else if ( this.destSubdomains.includes( this.data.subdomain ) || ( this.destSubdomains.includes( defaultsub ) && this.data.subdomain === '' ) ) {
				let subdomain = this.data.subdomain;
				if ( subdomain === '' ) {
					subdomain = defaultsub;
				}

				this.warningMsg = `${subdomain}.${this.data.to.domain} already exists, so this site will be overwritten with new data.`;
				this.warning = true;
			} else {
				await this.migrateSites();
			}
		},
		async migrateSites() {
			this.warning = false;
			this.warningMsg = '';
			if ( !( this.data.subdomain.match( /^[a-z0-9-]*$/ ) ) ) {
				this.output = 'Subdomain must only include letters, numbers and dashes.';
				return;
			}

			if ( !( this.data.subdomain.match( /^(?!-).*(?<!-)$/ ) ) ) {
				this.output = 'Subdomain cannot begin or end with a dash.';
				return;
			}

			const pusher = new Pusher( process.env.PUSHER_KEY, {
				cluster: 'us2',
				forceTLS: true,
			} );

			const channel = pusher.subscribe( 'migration' );

			channel.bind( 'progress', ( data ) => {
				this.percent = data.percent;
				this.progress = data.message;
			} );
			channel.bind( 'sub-progress', ( data ) => {
				this.subprogress = data.message;
			} );
			channel.bind( 'entry-progress', ( data ) => {
				if ( data.message !== 'Finished!' ) {
					this.entryprogress = data.message;
				} else {
					this.entryprogress = '';
				}
			} );
			channel.bind( 'complete', ( data ) => {
				this.percent = data.percent;
				this.progress = data.message;
				this.subprogress = '';
			} );
			channel.bind( 'done', () => {
				channel.unsubscribe( 'migration' );
				pusher.disconnect();
			} );

			this.migration = true;
			this.message = '';
			this.nclass = '';
			try {
				this.output = {};
				this.data.sites = [this.data.site];
				this.socket.emit( 'migrate', this.data );
			} catch ( e ) {
				this.output = `${this.$store.state.axiosError} or restful routes not integrated.`;
			}
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'migrate-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.data;
				} else {
					this.output = { errors: response.errors, success: response.success };
					console.log( response.sites );
					this.updated = 'success';
					this.message = 'Successfully made query request.';
				}
				setTimeout( () => {
					this.migration = false;
					this.percent = 0;
					this.progress = '';
					this.subprogress = '';
				}, 2000 );
			} );
		},
	},
};
</script>
