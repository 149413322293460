<template>
  <div class="ap__section">
    <div class="ap__title">
      Domains
    </div>
    <v-tabs color="white">
      <v-tab
        v-for="tab in tabs"
        :key="tab.label"
        ripple
        @click="changeActiveTab(tab)"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="ap__tab__content">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import Sites from './domains/Sites.vue';
import NodePing from './domains/NodePing.vue';
import MassUpdate from './domains/MassUpdate.vue';

export default {
	components: {
		Sites, NodePing, MassUpdate,
	},
	data() {
		return {
			tabs: [{
				label: 'Sites',
				component: 'Sites',
			}, {
				label: 'NodePing',
				component: 'NodePing',
			}, {
				label: 'Mass Update',
				component: 'MassUpdate',
			}],
			activeTab: 'Sites',
		};
	},
	methods: {
		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
	},
};
</script>
