<template>
  <div style="position: relative;">
    <ap-loading :loading="loading" />
    <p>The following are a list of actions available in this tab.</p>
    <ul>
      <li>Request ssl for a domain and assign it to its network.</li>
      <li>See the status of a domain's apex and www SSL certificates.</li>
      <li>Delete an expired / incorrect domain. Available when status = TIMED_OUT.</li>
      <li>Re-request an expired ssl request. Available when status = TIMED_OUT.</li>
      <li>Run the cron that updates all pending certificate statuses. Available on pending table.</li>
      <li>Run the cron that updates all Cloudfront values on successful domains. Only updates this value if the group SSL has been ISSUED.</li>
    </ul>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Account ID</label>
        <input
          v-model="accountId"
          type="text"
        >
        <div class="ap__input__desc">
          Enter Account ID (Unique/String)
        </div>
      </div>
      <div class="ap__input">
        <label>Domain</label>
        <input
          v-model="domains"
          type="text"
        >
        <div class="ap__input__desc">
          Domain to request ssl for.
        </div>
      </div>
      <div class="ap__input">
        <label>Network</label>
        <v-select
          v-model="network"
          :options="prodALBs"
          label="network"
          @input="$store.dispatch('updateGroups');"
        />
        <div class="ap__input__desc">
          Which production network does this domain belong to.
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disabled"
        value="Request SSL"
        @clicked="requestSSL"
      />
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			loading: false,
			disabled: false,
			domains: '',
			accountId: '',
			network: {},
			region: 'us-east-1',
			output: {},
		};
	},
	computed: mapGetters( ['prodALBs'] ),
	methods: {
		async requestSSL() {
			if ( Object.keys( this.network ).length === 0 || !( 'network' in this.network ) ) {
				this.output = 'Please select a valid network';
				return;
			}

			this.output = {};
			this.loading = true;
			this.disabled = true;
			try {
				const response = await axios.post( '/aws/ssl', {
					domains: this.domains,
					network: this.network,
					region: this.region,
					accountId: this.accountId,
				} );
				await this.$store.dispatch( 'updateDomains' );
				await this.$store.dispatch( 'updateGroups' );
				this.output = response;
			} catch ( e ) {
				this.output = this.$state.store.axiosError;
			}
			this.disabled = false;
			this.loading = false;
		},
	},
};
</script>
