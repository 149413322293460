<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Update Network Sites
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="query.disabled"
        value="Clear All Accounts"
        @clicked="query.accounts = []"
      />
      <ap-button
        :disabled="query.disabled"
        value="Select All Accounts"
        @clicked="query.accounts = JSON.parse(JSON.stringify( query.accountList ))"
      />
    </div>

    <div class="ap__label">
      Network & Accounts
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="query.network"
          :options="albs"
          label="network"
          @input="updateAccountList"
        />
        <div class="ap__input__desc">
          Which network to query.
        </div>
      </div>
      <div class="ap__input">
        <label>Accounts</label>
        <v-select
          v-model="query.accounts"
          :options="query.accountList"
          label="domain"
          multiple
          @input="updateProfileList"
        />
        <div class="ap__input__desc">
          Which account(s) to query.
        </div>
      </div>
    </div>

    <v-tabs color="white">
      <v-tab
        v-for="item of tabs"
        :key="item"
      >
        {{ item }}
      </v-tab>

      <v-tab-item>
        <!-- Update Network Site Option -->
        <div class="ap__label">
          Update Option
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Option</label>
            <v-select
              v-model="query.option"
              label="label"
              :options="possibleOptions"
              taggable
            />
            <div class="ap__input__desc">
              Options to update.
            </div>
          </div>

          <div class="ap__input">
            <label>Value</label>
            <input
              v-model="query.optionValue"
              type="text"
            >
            <div class="ap__input__desc">
              New option value.
            </div>
          </div>

          <div class="ap__input">
            <label>Type</label>
            <select v-model="query.optionType">
              <option value="String">
                String
              </option>
              <option value="JSON or Boolean">
                JSON or Boolean
              </option>
              <option value="Serialized Array">
                Serialized Array
              </option>
            </select>
            <div class="ap__input__desc">
              What type is the option?
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="query.disabled"
            value="Update Option"
            @clicked="updateOption"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <!-- Update Network Site Redirect -->
        <div class="ap__label">
          Update Redirect
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Original URL</label>
            <input
              v-model="query.originalUrl"
              type="text"
            >
            <div class="ap__input__desc">
              The original url.
            </div>
          </div>

          <div class="ap__input">
            <label>Target URL</label>
            <input
              v-model="query.targetUrl"
              type="text"
            >
            <div class="ap__input__desc">
              The url to redirect to.
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="query.disabled"
            value="Update Redirect"
            @clicked="updateRedirect"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <!-- Activate Deactivate Network Site Plugins -->
        <div class="ap__label">
          Update Plugins
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Plugins</label>
            <v-select
              v-model="query.plugins"
              :options="query.pluginsList"
              label="label"
              multiple
            />
            <div class="ap__input__desc">
              Plugins to activate/deactivate.
            </div>
          </div>

          <div class="ap__input">
            <label>Activate/Deactivate</label>
            <select v-model="query.activate">
              <option :value="true">
                Activate
              </option>
              <option :value="false">
                Deactivate
              </option>
            </select>
            <div class="ap__input__desc">
              Plugin action.
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="query.disabled"
            value="Update Plugins"
            @clicked="updatePlugins"
          />
        </div>
      </v-tab-item>

      <!-- Profiles Section -->
      <v-tab-item>
        <ap-response
          label="Network siteprofile Data Viewer"
          :json="profileViewOutput"
        />
        <!-- Update Profile Settings-->
        <div class="ap__label">
          Update Site Profile
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Site Type</label>
            <select v-model="profile.type">
              <option value="Profile">
                Profile
              </option>
              <option value="Parent">
                Parent
              </option>
              <option value="Child">
                Child
              </option>
            </select>
            <div class="ap__input__desc">
              What type of site are these accounts?
            </div>
          </div>

          <div
            v-if="profile.type !== 'Profile'"
            class="ap__input"
          >
            <label>Profile Site</label>
            <v-select
              v-model="profile.profile"
              :options="profile.accountList"
              label="domain"
            />
            <div class="ap__input__desc">
              Select a profile site.
            </div>
          </div>

          <div
            v-if="profile.type === 'Child'"
            class="ap__input"
          >
            <label>Parent Site</label>
            <v-select
              v-model="profile.parent"
              :options="profile.accountList"
              label="domain"
            />
            <div class="ap__input__desc">
              Select a child's parent site.
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="profile.disabled"
            value="Update Profile / Parent"
            @clicked="updateProfiles"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <!-- Upsert Network Site Page -->
        <div class="ap__label">
          Update / Add Page
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Title</label>
            <input
              v-model="page.post_title"
              type="text"
            >
            <div class="ap__input__desc">
              Page title.
            </div>
          </div>

          <div class="ap__input">
            <label>Name</label>
            <input
              v-model="page.post_name"
              type="text"
            >
            <div class="ap__input__desc">
              URL Reference for page being updated / added.
            </div>
          </div>

          <div class="ap__input">
            <label>Page Parent</label>
            <select v-model="page.post_parent">
              <option :value="0">
                None
              </option>
              <option value="finance">
                Finance
              </option>
              <option value="new">
                New Vehicles
              </option>
              <option value="used">
                Used Vehicles
              </option>
            </select>
            <div class="ap__input__desc">
              The parent page ( if any ).
            </div>
          </div>

          <div class="ap__input">
            <label>Page Template</label>
            <select v-model="page['meta_input']['_wp_page_template']">
              <option value="template-default.php">
                Default
              </option>
              <option value="template-sidebar.php">
                Sidebar
              </option>
              <option value="template-calculator.php">
                Finance Calculator
              </option>
              <option value="template-careers.php">
                Careers
              </option>
              <option value="template-specials.php">
                Specials
              </option>
              <option value="template-reviews.php">
                Reviews
              </option>
              <option value="template-thank-you.php">
                Thank You
              </option>
              <option value="template-sitemap.php">
                Sitemap
              </option>
              <option value="home.php">
                Blog
              </option>
            </select>
            <div class="ap__input__desc">
              The parent page ( if any ).
            </div>
          </div>
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Content</label>
            <textarea
              v-model="page.post_content"
              rows="5"
              cols="75"
            />
            <div class="ap__input__desc">
              The page's html.
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="query.disabled"
            value="Update / Create Page"
            @clicked="updatePage"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <!-- Duplicate existing page to other sites -->
        <div class="ap__label">
          Broadcast Existing Pages
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Source Network</label>
            <v-select
              v-model="broadcast.srcNetwork"
              :options="albs"
              label="network"
              @input="updateSrcSitesList"
            />
            <div class="ap__input__desc">
              Which network to grab page from.
            </div>
          </div>
          <div class="ap__input">
            <label>Source Site</label>
            <v-select
              v-model="broadcast.srcSite"
              :options="query.srcSitesList"
              label="domain"
              @input="updatePagesList"
            />
            <div class="ap__input__desc">
              Which site to grab page from.
            </div>
          </div>
          <div class="ap__input">
            <label>Pages</label>
            <v-select
              v-model="broadcast.pages"
              :options="query.pagesList"
              label="post_name"
              multiple
            />
            <div class="ap__input__desc">
              Pages to broadcast.
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="query.disabled"
            value="Broadcast Pages"
            @clicked="broadcastPages"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <!-- Find and replace a value from the the database -->
        <div class="ap__label">
          Replace
        </div>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>Find</label>
            <input
              v-model="findAndReplace.find"
              type="text"
            >
            <div class="ap__input__desc">
              Find what:
            </div>
          </div>
          <div class="ap__input">
            <label>Replace</label>
            <input
              v-model="findAndReplace.replace"
              type="text"
            >
            <div class="ap__input__desc">
              Replace with:
            </div>
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            value="Find"
            @clicked="findAndReplaceSubmit('find')"
          />
          <ap-button
            value="Replace"
            @clicked="findAndReplaceSubmit()"
          />
        </div>
      </v-tab-item>
    </v-tabs>

    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import serialize from 'php-serialize';

import helpers from '../../../../helpers';

const pluginsList = require( '../../../../server/json/plugins.json' );

export default {
	data() {
		return {
			loading: false,
			message: '',
			updated: '',
			output: {},
			profileViewOutput: {},
			query: {
				disabled: false,
				accountList: [],
				accounts: [],
				originalUrl: '',
				targetUrl: '',
				network: {},
				pluginsList,
				pagesList: [],
				option: '',
				optionValue: '',
				optionType: 'String',
				plugins: [],
				activate: true,
				profileAccountlist: [],
			},
			findAndReplace: {
				find: '',
				replace: '',
			},
			broadcast: {
				srcNetwork: {},
				srcSite: {},
				pages: [],
			},
			profile: {
				type: 'Profile',
				profile: '',
				parent: '',
				disabled: false,
				accountList: [],
				siteList: [],
			},
			page: {
				post_title: '',
				post_name: '',
				post_content: '',
				post_type: 'page',
				post_status: 'publish',
				meta_input: {
					_wp_page_template: 'template-default.php',
				},
				post_parent: 0,
			},
			tabs: [
				'Options',
				'Redirects',
				'Plugins',
				'Profiles',
				'Upsert Page',
				'Broadcast Pages',
				'Find & Replace',
			],
		};
	},
	computed: mapGetters( {
		albs: 'allALBs',
		possibleOptions: 'allOptions',
	} ),
	watch: {
		'profile.type': function () {
			this.profile.profile = '';
			this.profile.parent = '';
		},
	},
	mounted() {
		if ( this.albs.length > 0 ) {
			this.query.network = this.albs[0];
			this.updateAccountList();
		}
	},
	methods: {
		async updateProfileList() {
			this.loading = true;
			try {
				const response = await axios.get(
					`${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/blogmeta-siteprofile`,
				);
				const output = {};
				const domains = [];
				this.query.accounts.forEach( ( account ) => {
					domains.push( account.domain );
				} );

				if ( Array.isArray( domains ) && domains.length !== 0 ) {
					Object.entries( response ).forEach( ( entry ) => {
						const domain = entry[0];
						if ( domains.includes( domain ) ) {
							output[domain] = entry[1];
						}
					} );
				} else {
					output.error = 'Please select an account.';
				}

				this.profileViewOutput = output;
			} catch ( e ) {
				this.profileViewOutput = `${this.$store.state.axiosError} or restful routes not integrated.`;
			}
			this.loading = false;
		},
		async updateAccountList() {
			this.updateProfileList();
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get(
					`${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/sites`,
				);
				const accountList = Object.values( response );
				this.query.accountList = JSON.parse( JSON.stringify( accountList ) );
				this.query.accounts = [accountList[0]];

				this.profile.accountList = [{
					blog_id: '',
					domain: 'None',
				}, ...accountList];
				this.profile.profile = this.profile.accountList[0];
				this.profile.parent = this.profile.accountList[0];
			} catch ( e ) {
				this.query.accountList = [];
				this.profile.accountList = [];
				this.query.accounts = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async updateSrcSitesList() {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get(
					`${this.broadcast.srcNetwork.prefix}://${this.broadcast.srcNetwork.domain}/wp-json/achilles/v1/sites`,
				);
				const accountList = Object.values( response );
				this.query.srcSitesList = JSON.parse( JSON.stringify( accountList ) );
				this.broadcast.srcSite = accountList[0];
			} catch ( e ) {
				this.query.srcSitesList = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async updatePagesList() {
			this.loading = true;
			try {
				const response = await axios.post( '/wpapi/achilles/pages-list', { network: this.broadcast.srcNetwork, sites: [this.broadcast.srcSite] } );
				this.query.pagesList = response;
			} catch ( e ) {
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async updateOption() {
			this.loading = true;
			this.output = {};
			const options = {};
			const option = 'value' in this.query.option ? this.query.option.value : this.query.option.label;
			if ( this.query.optionType === 'JSON or Boolean' ) {
				try {
					options[option] = JSON.parse( this.query.optionValue );
				} catch ( e ) {
					this.output = 'Option value could not be parsed';
					this.loading = false;
					return;
				}
			} else if ( this.query.optionType === 'Serialized Array' ) {
				try {
					options[option] = serialize.unserialize( this.query.optionValue );
				} catch ( e ) {
					this.output = 'Option value could not be parsed';
					this.loading = false;
					return;
				}
			} else {
				options[option] = this.query.optionValue;
			}
			try {
				const response = await axios.post( '/wpapi/achilles/options', {
					network: this.query.network,
					options,
					accounts: this.query.accounts,
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updatePlugins() {
			this.loading = true;
			this.output = {};
			const plugins = {};
			const type = this.query.activate === true ? 'activate' : 'deactivate';
			plugins[type] = [];
			this.query.plugins.forEach( ( plugin ) => plugins[type].push( plugin.value ) );
			try {
				const response = await axios.post( '/wpapi/achilles/plugins', {
					network: this.query.network,
					accounts: this.query.accounts,
					plugins,
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updatePage() {
			this.loading = true;
			this.output = {};
			try {
				const response = await axios.post( '/wpapi/achilles/pages', {
					network: this.query.network,
					accounts: this.query.accounts,
					pages: [this.page],
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async broadcastPages() {
			this.loading = true;
			this.output = {};
			const allPages = [];
			this.query.pagesList.forEach( ( p ) => {
				allPages.push( {
					ID: p.ID,
					post_name: p.post_name,
				} );
			} );
			try {
				const response = await axios.post( '/wpapi/achilles/broadcast', {
					network: this.query.network,
					accounts: this.query.accounts,
					...this.broadcast,
					allPages,
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async findAndReplaceSubmit( method ) {
			this.loading = true;

			if ( !this.findAndReplace.replace || !this.findAndReplace.find ) {
				this.output = 'Missing fields';
				this.loading = false;
				return;
			}

			try {
				const headers = {
					Authorization: `Basic ${this.query.network.auth.basic}`,
				};

				/* Grab Data */
				const responseSiteDb = await axios.post( `${this.query.network.prefix}://${this.query.accounts[0].domain}/wp-json/achilles/v1/site-db?key=${this.query.network.auth.key}`, {
					data: { key: this.query.network.auth.key },
				},
				{
					headers,
				} );

				if ( responseSiteDb.pass !== true ) {
					this.output = responseSiteDb.data ? responseSiteDb.data : "There was an error fetching the site's DB";
					this.loading = false;
					return;
				}

				const tablesFound = [];
				const addToTablesFoundCallback = ( tables, callbackMethod ) => {
					for ( const table in tables ) {
						tablesFound.push( {
							table: `${table}`,
							replace: callbackMethod !== 'find',
							values: Object.values( tables[table] ),
						} );
					}
				};

				/* Find or Replace */
				if ( method === 'find' ) { /* Find */
					const findAndReplaceTables = this.findAndReplaceTables( responseSiteDb.data, this.findAndReplace.find, null, method );
					addToTablesFoundCallback( findAndReplaceTables, method );

					this.output = tablesFound;
				} else { /* Replace */
					const findAndReplaceTables = this.findAndReplaceTables( responseSiteDb.data, this.findAndReplace.find, this.findAndReplace.replace );
					addToTablesFoundCallback( findAndReplaceTables );

					/* Import Data */
					const responseImportDb = await axios.post( `${this.query.network.prefix}://${this.query.accounts[0].domain}/wp-json/achilles/v1/import-db?key=${this.query.network.auth.key}`, {
						key: this.query.network.auth.key,
						db: {
							tables: tablesFound,
						},
					},
					{
						headers,
					} );

					this.output = responseImportDb;
				}
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updateRedirect() {
			this.loading = true;
			this.output = {};
			try {
				const response = await axios.post( '/wpapi/achilles/redirect', {
					network: this.query.network,
					originalUrl: this.query.originalUrl,
					targetUrl: this.query.targetUrl,
					accounts: this.query.accounts,
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
		async updateProfiles() {
			this.loading = true;
			this.output = {};
			const params = {
				network: this.query.network,
				accounts: this.query.accounts,
				args: this.profile,
			};

			try {
				const responseBlog = await axios.post( '/wpapi/achilles/blogmeta-profiles', params );

				if ( responseBlog.pass === false ) {
					this.output = responseBlog;
					this.loading = false;
					return;
				}

				// Only update sites blogmeta updated
				params.accounts = responseBlog.updatedSites;

				const responseOption = await axios.post( '/wpapi/achilles/profiles', params );
				this.output = {
					blogmeta: responseBlog,
					options: responseOption,
				};
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
				this.loading = false;
			}

			this.updateProfileList();
			this.loading = false;
		},
		findAndReplaceTables( entity, needle, replacement, method ) {
			const newEntity = [];
			const regExp = new RegExp( needle, 'g' );
			for ( const property in entity ) {
				const values = entity[property];
				const newProperty = property;

				if ( typeof values === 'object' && values.length > 0 ) {
					values.forEach( ( rows ) => {
						for ( const row in rows ) {
							if ( typeof rows[row] === 'string' && regExp.test( rows[row] ) === true && rows[row].length > 0 ) {
								if ( typeof newEntity[newProperty] === 'undefined' ) newEntity[newProperty] = [];
								if ( method !== 'find' ) {
									rows[row] = helpers.replaceVals( rows[row], replacement, regExp );
								}
								newEntity[newProperty].push( rows );
							}
						}
					} );
				}
			}
			return newEntity;
		},
	},
};
</script>
