<template>
  <div class="upload">
    <input
      type="file"
      multiple
      @change="updateFiles"
    >
    <div
      class="upload__response"
      :class="uploadClass"
    >
      {{ response }}
    </div>
    <ap-button
      value="Upload"
      :disabled="disabled"
      @clicked="upload"
    />
  </div>
</template>
<script>
export default {
	data() {
		return {
			uploadClass: '',
			response: '',
			disabled: false,
			files: {},
		};
	},
	methods: {
		updateFiles( e ) {
			const files = e.target.files || e.dataTransfer.files;
			if ( !files.length ) {
				return;
			}
			this.files = files;
		},
		upload() {
			if ( Object.keys( this.files ).length === 0 ) {
				this.response = 'Please select a file!';
				this.uploadClass = 'fail';
				return;
			}

			this.disabled = true;
			const formData = new FormData();
			for ( let i = 0; i < this.files.length; i += 1 ) {
				const file = this.files[i];
				formData.append( 'files[]', file, file.name );
			}
			const xhr = new XMLHttpRequest();
			xhr.open( 'POST', '/upload', true );
			xhr.onload = () => {
				if ( xhr.status === 200 ) {
					const data = JSON.parse( xhr.response );
					this.uploadClass = data.class;
					this.response = data.response;
					this.disabled = false;
				}
			};
			xhr.send( formData );
		},
	},
};
</script>
<style lang="scss" scoped>
.upload {
	&__response {
		font-size: 14px;
		&.pass {
			color: green;
		}
		&.fail {
			color: red;
		}
	}
}
</style>
