<template>
  <div class="ap__section">
    <v-card>
      <ap-loading :loading="loading" />

      <!-- Warning Popup -->
      <v-dialog
        v-model="edit"
        max-width="500"
      >
        <v-card>
          <v-card-title>
            Edit {{ editedDomain.name }}
          </v-card-title>

          <v-card-text>
            <label>Profile
              <select v-model="npProfile">
                <option
                  v-for="option in nodepings"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.name }}
                </option>
              </select>
            </label>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="red darken-1 white--text"
              @click="reset()"
            >
              Cancel
            </v-btn>

            <v-btn
              color="green darken-1 white--text"
              @click="save( editedDomain )"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="domains"
        :search="search"
      >
        <template v-slot:item.name="{ item }">
          <a
            :href="'https://' + item.name"
            target="_blank"
          >
            {{ item.name }}
          </a>
        </template>
        <template v-slot:item.network="{ item }">
          <a
            :href="'https://' + item.network.replace( '-Webserver', '' )"
            target="_blank"
          >
            {{ item.network.replace( '-Webserver', '' ) }}
          </a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editNodePing( item )"
          >
            fa fa-pencil-alt
          </v-icon>
          <v-icon
            v-if="item.mute === true"
            small
            class="mr-2"
            @click="mute( item )"
          >
            fa fa-volume-off
          </v-icon>
          <v-icon
            v-else
            small
            class="mr-2"
            @click="mute( item )"
          >
            fa fa-volume-up
          </v-icon>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Refresh"
          @clicked="update"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			search: '',
			loading: false,
			updated: '',
			message: '',
			headers: [],
			edit: false,
			editedDomain: {},
			editedIndex: -1,
			npProfile: '',
		};
	},
	computed: {
		...mapGetters( {
			allDomains: 'sslDomains',
			nodepings: 'allNodePingProfiles',
		} ),
		domains() {
			return this.allDomains.filter( ( d ) => d.group !== 'pending' && d.npID !== undefined );
		},
		nodepingObj() {
			const obj = {};
			this.nodepings.forEach( ( np ) => {
				obj[np.name] = {
					interval: np.interval,
					threshold: np.threshold,
					sens: np.sens,
					follow: np.follow,
					enabled: np.enabled,
				};
			} );

			return obj;
		},
	},
	mounted() {
		this.headers = [{
			text: 'Domain',
			value: 'name',
		}, {
			text: 'Network',
			value: 'network',
		}, {
			text: 'NodePing ID',
			value: 'npID',
		}, {
			text: 'NodePing Profile',
			value: 'npProfile',
		}, {
			text: 'Actions',
			value: 'actions',
		}];
	},
	methods: {
		editNodePing( item ) {
			this.editedDomain = item;
			this.editedIndex = this.domains.indexOf( item );
			this.npProfile = this.editedDomain.npProfile;
			this.edit = true;
		},
		reset() {
			this.editedDomain = {};
			this.editedIndex = -1;
			this.npProfile = '';
			this.edit = false;
		},
		async save() {
			this.edit = false;
			if ( this.editedIndex > -1 ) {
				this.editedDomain.npProfile = this.npProfile;
				this.loading = true;
				await axios.post( '/node-ping/update-site', { domain: this.editedDomain, profile: this.nodepingObj[this.npProfile] } );
				this.loading = false;
			}
			this.reset();
		},
		async mute( item ) {
			item = {
				...item,
				mute: !item.mute,
			};
			await axios.post( '/node-ping/mute-site', { domain: item } );
			this.update();
		},
		async update() {
			this.loading = true;
			await this.$store.dispatch( 'updateDomains' );
			await this.$store.dispatch( 'updateNodePingProfiles' );
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
table {

	th {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}

	td {

		&:last-child {
			padding: 0 0 0 50px;
		}
	}
}
</style>
