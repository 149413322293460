<template>
  <div class="ap__section">
    <ap-loading :loading="loading" />
    <div class="ap__title">
      Selenium
    </div>
    <div class="ap__item">
      <div class="ap__title--sub">
        Build Test
      </div>
      <p>- Build a selenium job on jenkins and have it log the output in a Jira sub-task.</p>
      <div class="ap__input__wrap">
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>URL</label>
            <input
              v-model="data.url"
              type="text"
            >
            <div class="ap__input__desc">
              URL to run selenium test on.
            </div>
          </div>
          <div class="ap__input">
            <label>Test</label>
            <select v-model="data.test">
              <option
                v-for="test in jira.tests"
                :key="test.name"
                :value="test.command"
              >
                {{ test.name }}
              </option>
            </select>
            <div class="ap__input__desc">
              Which test to run the url on.
            </div>
          </div>
          <div class="ap__input">
            <label>Story</label>
            <input
              v-model="data.story"
              type="text"
            >
            <div class="ap__input__desc">
              Name of story to assign sub-task to.
            </div>
          </div>
          <div class="ap__input">
            <label>Assignee</label>
            <select v-model="data.assignee">
              <option
                v-for="(value, key) in jira.users"
                :key="key"
                :value="key"
              >
                {{ value.display }}
              </option>
            </select>
            <div class="ap__input__desc">
              User to assign sub-task to.
            </div>
          </div>
          <div class="ap__input">
            <label>Owner</label>
            <select v-model="data.owner">
              <option
                v-for="(value, key) in jira.users"
                :key="key"
                :value="key"
              >
                {{ value.display }}
              </option>
            </select>
            <div class="ap__input__desc">
              Owner of the sub-task.
            </div>
          </div>
        </div>
      </div>
      <div
        class="ap__notification"
        :class="nclass"
      >
        {{ notification }}
      </div>
      <div class="ap__wrap--btn">
        <ap-button
          :disabled="build.disabled"
          value="Build Job"
          @clicked="buildFn"
        />
      </div>
      <ap-response :json="build.output" />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import jira from '../../../server/json/site-map/map-jira.json';

export default {
	data() {
		return {
			loading: false,
			jira,
			data: {
				url: '',
				story: '',
				test: 'test=XmlFile-testng.xml',
				assignee: 'holly',
				owner: 'holly',
			},
			notification: '',
			nclass: '',
			build: {
				disabled: false,
				output: {},
			},
		};
	},
	methods: {
		async buildFn() {
			this.loading = true;
			const notification = [];
			this.notification = '';
			this.nclass = '';

			['url', 'story'].forEach( ( param ) => {
				if ( this.data[param] === '' ) {
					notification.push( `*Please enter a value for ${param}` );
				}
			} );

			if ( notification.length > 0 ) {
				this.nclass = 'error';
				this.notification = notification.join( '<br>' );
				this.loading = false;
				return;
			}

			try {
				const response = await axios.post( '/jenkins/selenium', this.data );
				const data = response.data;

				// Create anchor tag upon success
				if ( data.pass === true ) {
					this.build.output = data.data.message;
					this.notification = `<a href="${response.data.url}" target="_blank">Created Task - ${response.data.url}</a>`;
					this.nclass = 'success';
				} else {
					this.build.output = data.data;
				}
			} catch ( e ) {
				this.build.output = 'There was an error while making the request!';
			}
			this.loading = false;
		},
	},
};
</script>
<style lang="scss">
</style>
