import Vue from 'vue';
import axios from 'axios';
import VueRouter from 'vue-router';
import vSelect from 'vue-select';
import Vuetify from 'vuetify';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueCodeHighlight from 'vue-code-highlight';
import Validate from '../components/items/Validate.vue';
import Upload from '../components/items/Upload.vue';
import Loading from '../components/items/Loading.vue';
import Button from '../components/items/Button.vue';
import Output from '../components/items/Output.vue';
import Response from '../components/items/Response.vue';
import Modal from '../components/items/Modal.vue';
import App from '../components/App.vue';
import Version from '../components/items/Version.vue';
import routes from './routes';
import store from './store';
import 'vue-code-highlight/themes/prism-okaidia.css';
import 'vuetify/dist/vuetify.min.css';

global.Buffer = global.Buffer || require( 'buffer' ).Buffer;

const opts = {
	options: {
		themeCache: {
			get: () => '',
		},
	},
	icons: {
		iconfont: 'fa',
	},
};
Vue.use( Vuetify );
Vue.use( VueCodeHighlight );
Vue.use(VueConfirmDialog);

Vue.component( 'ap-modal', Modal );
Vue.component( 'ap-loading', Loading );
Vue.component( 'ap-validate', Validate );
Vue.component( 'ap-output', Output );
Vue.component( 'ap-upload', Upload );
Vue.component( 'ap-button', Button );
Vue.component( 'ap-response', Response );
Vue.component( 'v-select', vSelect );
Vue.component(	'ap-version', Version );
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

require( '../scss/main.scss' );

axios.interceptors.response.use( ( response ) => {
	if ( response.status > 199 && response.status < 301 ) {
		return response.data;
	}
	return { pass: false, data: `Server responded with code ${response.status}` };
} );

Vue.use( VueRouter );
const router = new VueRouter( {
	mode: 'history',
	routes,
} );

router.beforeEach( async ( to, from, next ) => {
	const session = await axios.get( '/user/session' );

	// console.log( `Attempting ${from.name} to ${to.name}` );
	// Incase user is logged in but state data is not set for user.
	if ( store.state.user.user === false ) {
		store.commit( 'setUser', session.data );
	}

	// Ensure route only accessed with right session state.
	switch ( to.path ) {
	case '/login':
		if ( session === false ) {
			next();
		} else {
			next( 'home' );
		}
		break;

	case '/':
		if ( session !== false ) {
			next();
		} else {
			next( 'login' );
		}
		break;

	default:
		break;
	}
} );

Vue.filter( 'capitalize', ( value ) => {
	if ( !value ) {
		return '';
	}
	value = value.toString();
	return value.charAt( 0 ).toUpperCase() + value.slice( 1 );
} );

new Vue( {
	el: '#app',
	router,
	store,
	components: { App },
	render: ( h ) => h( App ),
	template: '<App/>',
	vuetify: new Vuetify( opts ),
} );
