<template>
  <div
    class="ap__button"
    :class="{ disabled : disabled }"
    @click="clicked"
  >
    {{ value }}
  </div>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			required: false,
		},
		value: {
			type: String,
			required: true,
		},
	},
	data() {
		return {};
	},
	methods: {
		clicked() {
			this.$emit( 'clicked', '' );
		},
	},
};
</script>
<style lang="scss">
.ap__button {
        display: inline-block;
		border: 1px solid transparent;
        text-align: center;
		padding: 5px 15px;
		margin: 0px 5px;
		border-radius: 25px;
        color: white;
        cursor: pointer;
        background: #78be20;
        transition: all 0.2s;
        font-size: 14px;
        min-width: 100px;
        &:hover {
			background: white;
			color: #78be20;
			border: 1px solid #78be20;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.75;
        }
    }
</style>
