<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Sync Genesisbase Specials / Careers / Employees With Achilles
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Account ID</label>
        <v-select
          v-model="selected"
          :options="list"
          label="accountid"
        />
        <div class="ap__input__desc">
          Which account do you want to sync?
        </div>
      </div>
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disabled"
        value="Sync Specials"
        @clicked="syncGenesisbase('specials')"
      />
      <ap-button
        :disabled="disabled"
        value="Sync Careers"
        @clicked="syncGenesisbase('careers')"
      />
      <ap-button
        :disabled="disabled"
        value="Sync Employees"
        @clicked="syncGenesisbase('employees')"
      />
    </div>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
    <ap-response :json="output" />
  </div>
</template>
<script>
import axios from 'axios';

let list = require( '../../../../server/json/genesis/genesis-built.json' );

list = Object.values( list );
export default {
	data() {
		return {
			disabled: false,
			loading: false,
			list,
			selected: list[0],
			message: '',
			updated: '',
			output: {},
		};
	},
	methods: {
		async syncGenesisbase( endpoint ) {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const network = this.selected.network.replace( 'groupstaging', 'groupprod' ).replace( 'staging', 'prod' );
				const response = await axios.post( `/wpapi/genesisbase/${endpoint}`, {
					network,
					ninjaUrl: this.selected.ninjaUrl,
					account: this.selected.accountid,
				} );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.loading = false;
		},
	},
};
</script>
