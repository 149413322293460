<template>
  <div class="ap__section">
    <div class="ap__title">
      Networks
    </div>
    <v-card>
      <ap-loading :loading="loading" />
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="albs"
        :search="search"
      >
        <template v-slot:item.domain="{ item }">
          <a
            :href="'http://' + item.domain"
            target="_blank"
          >
            {{ item.domain }}
          </a>
        </template>
        <template v-slot:item.status="{ item }">
          <span
            :style="'color: ' + getColor( item.status )"
            dark
            small
          >
            {{ item.status }}
          </span>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Refresh"
          @clicked="update"
        />
        <ap-button
          :disabled="loading"
          value="Download CSV"
          @clicked="csv"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import download from '../../js/mixins/download';

export default {
	mixins: [download],
	data() {
		return {
			search: '',
			loading: false,
			updated: '',
			message: '',
			headers: [],
		};
	},
	computed: mapGetters( {
		albs: 'allALBs',
		user: 'user',
	} ),
	mounted() {
		let headers = [{
			text: 'Network',
			value: 'network',
		}, {
			text: 'URL',
			value: 'domain',
		}];
		if ( ['Administrator', 'Superadmin'].includes( this.user.role ) ) {
			headers.push( {
				text: 'Restful Key',
				value: 'auth.key',
			} );
		}
		headers = headers.concat( [{
			text: 'Version',
			value: 'version',
		}, {
			text: 'Status',
			value: 'status',
		}] );
		this.headers = headers;
	},
	methods: {
		getColor( status ) {
			if ( status === 'healthy' ) {
				return '#78be20';
			}
			return 'red';
		},
		async update() {
			this.loading = true;
			await this.$store.dispatch( 'updateALBs' );
			this.loading = false;
		},
		csv() {
			if ( this.albs.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			const results = this.albs.map( ( a ) => {
				const temp = {
					name: a.network,
					domain: a.domain,
				};
				if ( ['Administrator', 'Superadmin'].includes( this.user.role ) ) {
					temp.key = a.auth.key;
				}
				temp.verison = a.version;
				temp.status = a.status;
				return temp;
			} );
			this.updated = '';
			this.message = '';
			this.loading = true;
			const response = this.download( results, this.headers.map( ( h ) => h.text ), 'Network' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
table {
    th {
        &:last-child {
            padding: 0px 0px 0px 50px;
        }
    }
    td {
        &:last-child {
            padding: 0px 0px 0px 50px;
        }
    }
}
</style>
