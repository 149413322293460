<template>
  <div class="ap__section">
    <div class="ap__title">
      SSL
    </div>
    <div class="ap__item">
      <v-tabs color="white">
        <v-tab
          v-for="tab in tabs"
          :key="tab.label"
          ripple
          @click="changeActiveTab(tab)"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <div class="ap__tab__content">
        <keep-alive>
          <component :is="activeTab" />
        </keep-alive>
      </div>
    </div>

    <div
      class="ap__item"
      style="position: relative;"
    >
      <ap-loading :loading="table.loading" />
      <v-card>
        <div class="ap__nav">
          <div>Last Updated By Cron: <strong>{{ lastUpdated }}</strong></div>
          <div>Total Domains In Group: <strong>{{ groupTotal }}</strong></div>
          <div>Total Domains: <strong>{{ sslDomains.length }}</strong></div>
          <ap-response :json="output" />
        </div>
        <div />
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
          <ap-button
            :disabled="table.loading"
            value="Refresh"
            @clicked="refresh"
          />
          <ap-button
            v-if="table.filter.group.name === 'pending'"
            :disabled="table.loading"
            value="Update All"
            @clicked="aws({method: 'get', url: '/aws/ssl/update-all'})"
          />
          <ap-button
            v-else
            :disabled="table.loading"
            value="Cloud Front"
            @clicked="aws({method: 'get', url: '/aws/ssl/cloud-front'})"
          />
          <v-spacer />
          <label style="margin: 0 5px;">Group</label>
          <v-select
            v-model="table.filter.group"
            :options="sslGroups"
            label="name"
          />
        </v-card-title>
        <v-data-table
          :headers="domainHeaders"
          :items="filtered"
          :search="search"
        >
          <template
            v-slot:body="{ items }"
          >
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.name"
              >
                <td>
                  <div v-if="!item.beingEdited">
                    {{ item.accountId || 'N/A' }}
                    <i
                      class="fa fa-pencil-alt"
                      @click="editField(item.name, 'beingEdited', true)"
                    />
                  </div>
                  <div v-else>
                    <input
                      v-model="newAccountId"
                      type="text"
                      placeholder="New Account ID"
                      class="form-control"
                    >
                    <button
                      class="ap__button"
                      @click="updateDomain(item.name)"
                    >
                      <i class="fa fa-check" />
                    </button>
                    <button
                      class="ap__button red"
                      @click="editField(item.name, 'beingEdited', false)"
                    >
                      <i class="fa fa-times" />
                    </button>
                  </div>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.network.split( '-' )[0] }}</td>
                <td>{{ item.group }}</td>
                <td>{{ getSSLName('apex', item) }}</td>
                <td>{{ getSSLValue('apex', item) }}</td>
                <td :class="getStatusClass('apex', item) + '--text'">
                  {{ getSSLValidated('apex', item) }}
                </td>
                <td>{{ getSSLName('www', item) }}</td>
                <td>{{ getSSLValue('www', item) }}</td>
                <td :class="getStatusClass('www', item) + '--text'">
                  {{ getSSLValidated('www', item) }}
                </td>
                <td>{{ item.cfRecord }}</td>
                <td>
                  <div
                    v-if="!expired(item)"
                    class="unavailable"
                  >
                    N/A
                  </div>
                  <i
                    class="fa fa-paper-plane"
                    aria-hidden="true"
                    @click="aws({method: 'post', url: '/aws/ssl/retry', data: {domain: item}})"
                  />
                </td>
                <td v-if="table.filter.group === 'pending' || table.filter.group === 'failed'">
                  <i
                    class="fa fa-minus-circle"
                    aria-hidden="true"
                    @click.prevent="deleteDomain('/aws/ssl', item)"
                  />
                </td>
                <td v-else>
                  <i
                    class="fa fa-minus-circle"
                    aria-hidden="true"
                    @click.prevent="deleteDomain('/aws/ssl/group-domain', item)"
                  />
                </td>
                <td>
                  <div
                    v-if="item.wwwSSL.validated === 'SUCCESS' && item.apexSSL.validated === 'SUCCESS'"
                    class="unavailable"
                  >
                    N/A
                  </div><i
                    class="fa fa-database"
                    aria-hidden="true"
                    @click="aws({method: 'put', url: '/aws/ssl', data: {domain: item}})"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div class="ap__table__footer">
          <ap-button
            :disabled="loading"
            value="Download CSV"
            @clicked="csv"
          />
        </div>
      </v-card>
      <div
        class="ap__notification"
        :class="updated"
      >
        {{ message }}
      </div>
    </div>
    <vue-confirm-dialog />
  </div>
</template>
<script>
import axios from 'axios';
import format from 'date-fns/format';
import { mapGetters } from 'vuex';
import download from '../../js/mixins/download';
import Migrate from './ssl/Migrate.vue';
import Request from './ssl/Request.vue';
import Renew from './ssl/Renew.vue';

export default {
	components: {
		Migrate,
		Request,
		Renew,
	},
	mixins: [download],
	data() {
		return {
			activeTab: 'Request',
			updated: '',
			message: '',
			newAccountId: null,
			loading: false,
			tabs: [{
				label: 'Request SSL',
				component: 'Request',
			}, {
				label: 'Change Network',
				component: 'Migrate',
			}, {
				label: 'Renew Group Certificate',
				component: 'Renew',
			}],
			search: '',
			domainHeaders: [{
				text: 'Account ID',
				value: 'accountId',
				align: 'center',
			},
			{
				text: 'Domain',
				value: 'name',
				align: 'center',
			},
			{
				text: 'Network',
				value: 'network',
				align: 'center',
			},
			{
				text: 'Group',
				value: 'group',
				align: 'center',
			},
			{
				text: 'apexSSL Name',
				align: 'center',
				sortable: false,
			},
			{
				text: 'apexSSL Value',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Apex Status',
				align: 'center',
				value: 'apexSSLGroup',
				sortable: true,
			},
			{
				text: 'wwwSSL Name',
				align: 'center',
				sortable: false,
			},
			{
				text: 'wwwSSL Value',
				align: 'center',
				sortable: false,
			},
			{
				text: 'www Status',
				align: 'center',
				value: 'wwwSSLGroup',
				sortable: true,
			},
			{
				text: 'Cloud Front',
				align: 'center',
				value: 'cfRecord',
				sortable: false,
			},
			{
				text: 'Request Again',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Delete',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Update',
				align: 'center',
				sortable: false,
			},
			],
			output: {},
			status: {
				PENDING_VALIDATION: 'warning',
				SUCCESS: 'success',
				TIMED_OUT: 'error',
			},
			table: {
				loading: false,
				filter: {
					group: {
						name: 'all',
					},
				},
			},
		};
	},
	computed: {
		...mapGetters( {
			albs: 'allALBs',
			sslGroups: 'sslGroups',
			sslDomains: 'sslDomains',
			sslLogs: 'sslLogs',
		} ),
		lastUpdated() {
			if ( this.sslLogs.length > 0 ) {
				return format( this.sslLogs[this.sslLogs.length - 1].created, 'MMM DD hh:mmA' );
			}
			return 'N/A';
		},
		filtered() {
			let filtered = [];

			// console.log(this.table.filter);

			// Filter domains.
			if ( this.table.filter.group.name === 'all' ) {
				filtered = this.sslDomains;
			} else {
				filtered = this.sslDomains.filter( ( d ) => d.group === this.table.filter.group.name );
			}
			return filtered;
		},
		groupTotal() {
			return this.filtered.length;
		},
	},
	methods: {
		csv() {
			if ( this.filtered.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			this.updated = '';
			this.message = '';
			this.loading = true;
			const results = this.filtered.map( ( f ) => ( {
				accountId: f.accountId || 'N/A',
				domain: f.name || 'N/A',
				network: f.network || 'N/A',
				group: f.group || 'N/A',
				apexName: f.apexSSL.name || 'N/A',
				apexValue: f.apexSSL.value || 'N/A',
				apexStatus: f.apexSSL.validated || 'N/A',
				wwwName: f.wwwSSL.name || 'N/A',
				wwwValue: f.wwwSSL.value || 'N/A',
				wwwStatus: f.wwwSSL.validated || 'N/A',
				cloudfront: f.cfRecord || 'N/A',
			} ) );
			const headers = this.domainHeaders.map( ( h ) => h.text ).slice( 0, -3 );
			const response = this.download( results, headers, 'SSL' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
		deleteDomain( url, item ) {
			this.$confirm(
				{
					message: `Do you really want to delete ${( item.name || 'N/A' )}?`,
					button: {
						no: 'No',
						yes: 'Yes',
					},
					/**
				 * Callback Function
				 * @param {Boolean} confirm
				 */
					callback: ( confirm ) => {
						if ( confirm ) {
							this.aws( { url, method: 'delete', data: { domain: item } } );
						}
					},
				},
			);
		},
		async refresh() {
			this.table.loading = true;
			this.output = {};
			const response = await this.$store.dispatch( 'updateDomains' );
			if ( response.pass === false ) {
				this.output = response.data;
			} else {
				this.output = 'Successfully updated domains table.';
			}
			this.table.loading = false;
		},

		async editField( domain_name, fieldName, fieldValue ) {
			this.newAccountId = null;
			const required_index = this.filtered.findIndex( ( domain ) => domain.name === domain_name );
			this.$set( this.filtered[required_index], fieldName, fieldValue );
		},

		async updateDomain( domain_name ) {
			this.table.loading = true;
			this.output = {};
			const newAccountId = this.newAccountId;

			const required_index = this.filtered.findIndex( ( domain ) => domain.name === domain_name );
			const updated_domain = this.filtered[required_index];

			const response = await axios( {
				url: '/aws/ssl/update-domain',
				method: 'POST',
				data: {
					name: updated_domain.name,
					accountId: newAccountId,
					_id: updated_domain._id,
				},
			} );

			if ( response.pass === true ) {
				this.$set( this.filtered[required_index], 'accountId', newAccountId );
				this.$set( this.filtered[required_index], 'beingEdited', false );
				this.newAccountId = null;
			}

			this.table.loading = false;
			this.output = response.data;
		},

		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
		getSSLName( type, domain ) {
			if ( !( `${type}SSL` in domain ) ) {
				return 'N/A';
			}

			return domain[`${type}SSL`].name;
		},
		getSSLValue( type, domain ) {
			if ( !( `${type}SSL` in domain ) ) {
				return 'N/A';
			}

			return domain[`${type}SSL`].value;
		},
		getSSLValidated( type, domain ) {
			if ( !( `${type}SSL` in domain ) ) {
				return 'N/A';
			}

			// us-east-1 value.
			if ( domain.group === 'pending' ) {
				return domain[`${type}SSL`].validated;
			}

			// us-west-2 value.
			return domain[`${type}SSLGroup`];
		},
		getStatusClass( type, domain ) {
			if ( !( `${type}SSL` in domain ) ) {
				return 'N/A';
			}

			// us-east-1 value.
			if ( domain.group === 'pending' ) {
				return this.status[domain[`${type}SSL`].validated];
			}

			// us-west-2 value.
			return this.status[domain[`${type}SSLGroup`]];
		},
		async aws( config ) {
			this.output = {};
			this.table.loading = true;
			try {
				const response = await axios( config );
				await this.$store.dispatch( 'updateDomains' );
				await this.$store.dispatch( 'updateGroups' );
				this.output = response;
			} catch ( e ) {
				this.output = this.$store.state.axiosError;
			}
			this.table.loading = false;
		},
		expired( domain ) {
			const expired = ['TIMED_OUT', 'FAILED'];
			if ( !( 'apexSSL' in domain ) || !( 'wwwSSL' in domain ) ) {
				return false;
			}
			if ( expired.includes( domain.apexSSL.validated ) || expired.includes( domain.wwwSSL.validated ) ) {
				return true;
			}
			return false;
		},
		canDelete( domain ) {
			const valid = ['PENDING_VALIDATION', 'TIMED_OUT', 'FAILED'];
			if ( !( 'apexSSL' in domain ) || !( 'wwwSSL' in domain ) ) {
				return false;
			}

			let apexSSL = domain.apexSSL.validated;
			let wwwSSL = domain.wwwSSL.validated;
			if ( domain.group !== 'pending' && domain.group !== 'failed' ) {
				apexSSL = domain.apexSSLGroup;
				wwwSSL = domain.wwwSSLGroup;
			}

			if ( valid.includes( apexSSL ) || valid.includes( wwwSSL ) ) {
				return true;
			}
			return false;
		},
	},
};
</script>
<style lang="scss" scoped>
	.ap__nav {
		padding: 15px;
		padding-bottom: 0;
	}

	.ap__button.red
	{
		background : #bb2222;
	}

	.last-updated,
	.total-filtered {
		font-size: 14px;
	}

	.nav-select {
		display: flex;
		align-items: center;

		label {
			padding-right: 15px;
		}
	}

	.unavailable {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #bebebe;
	}

	table {
		border-collapse: collapse;
		background: #f5f5f5;
		width: 100%;

		thead {
			background: #e4e4e4;
			box-shadow: 1px 1px 1px #bebebe;
		}

		th {
			padding: 0 10px;
			text-align: center;
		}

		td {
			position: relative;
			max-width: 200px;
			overflow-wrap: break-word;
			font-size: 14px;
			padding: 5px 10px;
			text-align: center;

			i {
				transition: all 0.2s;
				cursor: pointer;

				&.fa-paper-plane:hover,
				&.fa-database:hover {
					color: #78be20;
				}

				&.fa-minus-circle:hover {
					color: "red";
				}
			}

			&:last-child {
				max-width: 100px;
			}
		}
	}
</style>
