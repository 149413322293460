<template>
  <div class="ap__section">
    <div class="ap__title">
      Slider Broadcast
    </div>
    <v-tabs color="white">
      <v-tab
          v-for="tab in tabs"
          :key="tab.label"
          ripple
          @click="changeActiveTab(tab)"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="ap__tab__content">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import Buckets from './Slider/Buckets.vue';
import Sliders from './Slider/Sliders.vue';
import Domain from './Slider/Domain.vue';

export default {
  components: { Buckets, Sliders, Domain },
  data() {
    return {
      tabs: [ {
        label: 'Domain',
        component: 'Domain',
      },
      {
        label: 'Buckets',
        component: 'Buckets',
      }, {
        label: 'Sliders',
        component: 'Sliders',
      }],
      activeTab: 'Domain',
    };
  },
  methods: {
    changeActiveTab( tab ) {
      this.activeTab = tab.component;
    },
  },
};
</script>
