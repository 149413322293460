<template>
  <div id="login">
    <div class="ap__box">
      <div>
        <img
          width="275px"
          src="/assets/images/logo.png"
          alt="Convertus Logo"
        >
      </div>
      <div class="ap__box__input">
        <input
          v-model="username"
          type="text"
          placeholder="Username"
        >
      </div>
      <div class="ap__box__input">
        <input
          v-model="password"
          type="password"
          placeholder="Password"
        >
      </div>
      <div
        id="login_btn"
        class="ap__button"
        :class="{ disabled : disabled }"
        @click="login"
      >
        Log in
      </div>
      <div
        class="ap__notification"
        :class="messageClass + '--text'"
      >
        {{ message }}
      </div>
      <div class="loginPageText">
        <a href="mailto:support@convertus.com?subject=Forgotten Password For Achilles Portal">Forgot Your Password?</a>
      </div>
      <ap-version class="loginPageText"></ap-version>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
	data() {
		return {
			disabled: false,
			username: '',
			password: '',
			message: '',
			messageClass: '',
		};
	},
	mounted() {
		document.addEventListener( 'keypress', this.keypress );
	},
	destroyed() {
		document.removeEventListener( 'keypress', this.keypress );
	},
	methods: {
		async login() {
			this.message = '';
			this.disabled = true;
			try {
				const response = await axios.request( {
					url: '/user/login',
					method: 'POST',
					data: {
						username: this.username,
						password: this.password,
					},
				} );

				if ( response.pass === true ) {
					this.messageClass = 'success';
					this.message = `Welcome ${response.data.username}.`;
					this.$store.commit( 'setActivePanel', 'Networks' );
					setTimeout( () => {
						this.$store.dispatch( 'fetchUser' );
						this.$router.push( { name: 'home' } );
					}, 500 );
				} else {
					this.messageClass = 'error';
					this.message = response.data;
					this.disabled = false;
				}
			} catch ( e ) {
				this.message = 'An error occured when calling the endpoint.';
				this.disabled = false;
			}
		},
		keypress( e ) {
			if ( e.which === 13 ) {
				if ( this.messageClass !== 'success' && this.disabled === false ) {
					this.login();
				}
			}
		},
	},
};
</script>
<style lang="scss" scoped>
#login {
    justify-content: center;
    display: flex;
    align-items: center;
}

#login_btn {
    margin: 0;
    margin-top: 10px;
    width: 100%;
    padding: 7px 0px;
    box-shadow: 1px 1px 1px #d4d4d4;
    border-radius: 3px;
}
.loginPageText {
    padding-top: 10px;
    text-align: center;
    a {
        font-size: 12;
        color: #868b90;
        transition: color 0.2s;
        &:hover {
            color: darken(#868b90, 15%);
        }
    }
}
</style>
