import axios from 'axios';

const state = {
	allALBs: [],
	stagingALBs: [],
	prodALBs: [],
};
const getters = {
	allALBs( s ) {
		return s.allALBs;
	},
	stagingALBs( s ) {
		return s.stagingALBs;
	},
	prodALBs( s ) {
		return s.prodALBs;
	},
};

const mutations = {
	setAllALBs( s, albs ) {
		s.allALBs = albs;
	},
	setStagingALBs( s, albs ) {
		s.stagingALBs = albs;
	},
	setProdALBs( s, albs ) {
		s.prodALBs = albs;
	},
};

const actions = {
	async updateALBs( { commit, rootState } ) {
		try {
			const response = await axios.get( '/aws/alb' );
			if ( response.pass === true && response.data.length !== 0 ) {
				const albs = response.data;
				commit( 'setAllALBs', albs );

				// Get staging ALBs
				const staging = [];
				const production = [];
				albs.forEach( ( alb ) => {
					if ( alb.network.match( /staging|dev|.ninja|platinum-test|tadvantageperformance|tadvantageperformance2/ ) === null ) {
						production.push( alb );
					} else {
						staging.push( alb );
					}
				} );
				commit( 'setStagingALBs', staging );
				commit( 'setProdALBs', production );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};

export default {
	state, getters, actions, mutations,
};
