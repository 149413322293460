<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Query Sites in Network
    </div>
    <div class="ap__wrap--btn">
      <ap-button
        value="Clear All Accounts"
        @clicked="query.accounts = []"
      />
      <ap-button
        value="Select All Accounts"
        @clicked="query.accounts = JSON.parse(JSON.stringify( query.accountList ))"
      />
      <ap-button
        :disabled="query.disabled"
        value="Query"
        @clicked="queryNetwork"
      />
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Networks</label>
        <v-select
          v-model="query.network"
          :options="albs"
          label="network"
          @input="updateAccountList"
        />
        <div class="ap__input__desc">
          Which network to query.
        </div>
      </div>

      <div class="ap__input">
        <label>Accounts</label>
        <v-select
          v-model="query.accounts"
          :options="query.accountList"
          label="domain"
          multiple
        />
        <div class="ap__input__desc">
          Which account(s) to query.
        </div>
      </div>

      <div class="ap__input__option">
        <label>Option</label>
        <v-select
          v-model="query.options"
          label="label"
          :options="possibleOptions"
          taggable
          multiple
        />
        <div class="ap__input__desc">
          Options to query.
        </div>
      </div>
    </div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="results"
        :search="search"
      >
        <template v-slot:item.account="{ item }">
          <a
            :href="'http://' + item.account"
            target="_blank"
          >
            {{ item.account }}
          </a>
        </template>
      </v-data-table>
      <div class="ap__table__footer">
        <ap-button
          :disabled="loading"
          value="Download CSV"
          @clicked="csv"
        />
      </div>
    </v-card>
    <div
      class="ap__notification"
      :class="updated"
    />{{ message }}
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { io } from 'socket.io-client';
import json from 'format-json';
import download from '../../../js/mixins/download';

export default {
	mixins: [download],
	data() {
		return {
			loading: false,
			search: '',
			message: '',
			updated: '',
			headers: [],
			results: [],
			query: {
				disabled: false,
				accountList: [],
				accounts: [],
				network: '',
				options: [],
				output: {},
			},
			notification: '',
			socket: null,
		};
	},
	computed: mapGetters( {
		albs: 'allALBs',
		possibleOptions: 'allOptions',
	} ),
	async mounted() {
		if ( this.albs.length !== 0 ) {
			this.query.network = this.albs[0];
			this.updateAccountList();
		}
		this.connectToSocket();
	},
	methods: {
		async updateAccountList() {
			this.message = '';
			this.updated = '';
			this.loading = true;
			try {
				const response = await axios.get( `${this.query.network.prefix}://${this.query.network.domain}/wp-json/achilles/v1/sites` );
				const accountList = Object.values( response );
				this.query.accountList = accountList;
				this.query.accounts = [accountList[0]];
			} catch ( e ) {
				this.query.accountList = [];
				this.query.accounts = [];
				console.log( `${this.$store.state.axiosError} or restful routes not integrated.` );
			}
			this.loading = false;
		},
		async queryNetwork() {
			this.updated = '';
			this.message = '';
			this.headers = [];
			this.results = [];
			if ( this.query.network.auth.key === '' ) {
				this.updated = 'error';
				this.message = 'Missing api key in db for this network.';
				return;
			}
			if ( this.query.network.auth.basic === '' ) {
				this.updated = 'error';
				this.message = 'Missing basic header in db for this network.';
				return;
			}
			if ( this.query.options.length < 1 ) {
				this.updated = 'error';
				this.message = 'Please enter at least one option.';
				return;
			}
			try {
				this.loading = true;
				// let sites = [];
				const sites = this.query.accounts.map( ( a ) => a.domain );
				// this.query.accounts.forEach( ( a ) => sites.push( a.domain.replace( `.${this.query.network.domain}`, '' ).replace( /.ca|.com/gi, '' ) ) );
				// sites = sites.join( ',' );

				const options = this.query.options.map( ( opt ) => ( 'value' in opt ? opt.value : opt.label ) ).join( ',' );
				this.socket.emit( 'options-list', { network: this.query.network, options, sites } );
			} catch ( e ) {
				this.updated = 'error';
				this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
			}
		},
		csv() {
			if ( this.results.length === 0 ) {
				this.updated = 'error';
				this.message = 'No data to export!';
				return;
			}
			this.updated = '';
			this.message = '';
			this.loading = true;
			const response = this.download( this.results, this.headers.map( ( h ) => h.text ), 'Query' );
			if ( response.pass === true ) {
				this.updated = 'success';
			} else {
				this.updated = 'error';
			}
			this.message = response.data;
			this.loading = false;
		},
		updateTable( data ) {
			const results = [];
			const headers = [{ text: 'Account', value: 'account' }];
			data = Object.entries( data );
			data.forEach( ( e ) => {
				Object.entries( e[1] ).forEach( ( en ) => {
					if ( en[1].constructor !== String ) {
						const str = json.space( en[1] );
						e[1][en[0]] = str;
					}
				} );
				results.push( {
					account: e[0],
					...e[1],
				} );
			} );
			Object.keys( data[0][1] ).forEach( ( h ) => headers.push( { text: h, value: h } ) );
			this.headers = headers;
			this.results = results;
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'options-list-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.data;
				} else {
					this.updateTable( response.data );
					this.updated = 'success';
					this.message = 'Successfully made query request.';
				}
				this.loading = false;
			} );
		},
	},
};
</script>
