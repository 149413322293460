import axios from 'axios';

const state = {
	nodepings: [],
};
const getters = {
	allNodePingProfiles( s ) {
		return s.nodepings;
	},
};
const mutations = {
	setNodePingProfiles( s, nodepings ) {
		s.nodepings = nodepings;
	},
};
const actions = {
	async updateNodePingProfiles( { commit, rootState } ) {
		try {
			const response = await axios.get( '/node-ping' );
			if ( response.pass === true ) {
				commit( 'setNodePingProfiles', response.data );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};
export default {
	getters, mutations, actions, state,
};
