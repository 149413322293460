import axios from 'axios';

const state = {
	allReadableTableNames: [],
	allWpTableNames: [],
	allSavedQueries: []
};
const getters = {
	getAllReadableTableNames( s ) {
		return s.allReadableTableNames;
	},
	getWpTableNames( s ) {
		return s.allWpTableNames;
	},
	getSavedQueries( s ) {
		return s.allSavedQueries;
	}
};
const mutations = {
	setAllReadableTableNames( s, options ) {
		s.allReadableTableNames = options;
	},
	setWpTableNames( s, options ) {
		s.allWpTableNames = options;
	},
	setSavedQueries( s, options ) {
		s.allSavedQueries = options;
	}
};
const actions = {
	async updateAllReadableTableNames( { commit } ) {
		const response = await axios.get( '/custom-query/table-names' );
		commit( 'setAllReadableTableNames', response );
	},
	async updateWpTableNames( { commit } ) {
		const response = await axios.get( '/custom-query/wp-table-names' );
		commit( 'setWpTableNames', response );
	},
	async updateSavedQueries( { commit } ) {
		const response = await axios.get( '/custom-query/get-saved-queries' );
		commit( 'setSavedQueries', response.data );
	},
	queryDatabase( { commit }, queryOptions ) {
		return new Promise((resolve, reject) => {
			const response = axios.post( '/custom-query/query-database', queryOptions );
			if(response.pass === false)
				reject();
			
			resolve(response);
		});
	},
	queryAchillesDatabase( { commit }, queryOptions ) {
		return new Promise((resolve, reject) => {
			const response = axios.post( '/custom-query/achilles-database-query', queryOptions );
			if(response.pass === false)
				reject();
			
			resolve(response);
		});
	},
	saveCustomQuery( { commit }, data ) {
		return new Promise((resolve, reject) => {
			const response = axios.post( '/custom-query/save-custom-query', data );
			if(response.pass === false)
				reject();
			
			resolve(response);
		});
	}
};
export default {
	getters, mutations, actions, state,
};
