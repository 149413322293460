<template>
  <div class="ap__item">
    <div v-if="Object.keys( running ).length > 0">
      In Progress
      <v-card
        flat
        class="mb-6"
        color="#a8d0e6"
      >
        <v-row
          align="center"
        >
          <v-col>
            <v-card
              flat
              color="#a8d0e6"
            >
              <v-card-title>{{ running.account }}</v-card-title>
              <v-card-subtitle>{{ `on ${running.destination.domain}` }}</v-card-subtitle>
              <v-card-text>
                {{ `Requested on: ${time(running.created)}` }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="text-right">
            <v-card
              flat
              color="#a8d0e6"
              class="pr-4"
            >
              <v-progress-circular
                :value="running.progress.percent"
                :size="100"
                :width="8"
                :rotate="-90"
              />
              <v-card-text>{{ running.progress.text }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

    Waiting
    <div v-if="sites.length > 0">
      <v-card
        v-for="site of sites"
        :key="site._id"
        outlined
        class="mb-2"
      >
        <v-card-title>{{ site.account }}</v-card-title>
        <v-card-subtitle>{{ `on ${site.destination.domain}` }}</v-card-subtitle>
        <v-card-text>{{ `Created: ${time(site.created)}` }}</v-card-text>
      </v-card>
    </div>
    <v-card
      v-else
      outlined
    >
      <v-card-subtitle>There are no pending sites</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: mapGetters( {
		sites: 'waitingSites',
		running: 'runningSite',
	} ),
	methods: {
		time( created ) {
			const date = new Date( created );
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const local = date.toLocaleString( 'en-US', { timeZone } );
			return local.replace( ', ', ' at ' );
		},
	},
};
</script>
