import axios from 'axios';

const state = {
	models: {},
};
const getters = {
	showroomModels( s ) {
		return s.models;
	},
};
const mutations = {
	setShowroomModels( s, models ) {
		s.models = models;
	},
};
const actions = {
	async fetchShowroomModels( { commit, rootState }, args ) {
		try {
			const response = await axios.get( '/showroom/models-by-makes', {
				params: {
					makes: args.makes,
				},
			} );
			if ( response.success === true && response.data.length !== 0 ) {
				const modelObj = {};
				response.data.forEach( ( item ) => {
					if ( !modelObj[item.division_name] ) {
						modelObj[item.division_name] = [];
					}
					// save model if not already exists
					if ( !modelObj[item.division_name].some( ( model ) => model.model_name === item.model_name ) ) {
						modelObj[item.division_name].push( {
							model_name: item.model_name,
							years: [item.model_year],
						} );
					} else { // append year if model already exists
						modelObj[item.division_name].find( ( model ) => model.model_name === item.model_name ).years.push( item.model_year );
					}
				} );
				commit( 'setShowroomModels', modelObj );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};
export default {
	getters, mutations, actions, state,
};
