import axios from 'axios';

const state = {
	runningSite: {},
	waitingSites: [],
	completedSites: [],
	failedSites: [],
};
const getters = {
	runningSite( s ) {
		return s.runningSite;
	},
	waitingSites( s ) {
		return s.waitingSites;
	},
	completedSites( s ) {
		return s.completedSites;
	},
	failedSites( s ) {
		return s.failedSites;
	},
};

const mutations = {
	setRunningSite( s, site ) {
		s.runningSite = site;
	},
	setWaitingSites( s, sites ) {
		s.waitingSites = sites;
	},
	setCompletedSites( s, sites ) {
		s.completedSites = sites;
	},
	setFailedSites( s, sites ) {
		s.failedSites = sites;
	},
};

const actions = {
	async updateGenerate( { commit, rootState } ) {
		try {
			const response = await axios.get( '/db/generate' );
			if ( response.pass === true && response.data.length !== 0 ) {
				const sites = response.data;

				// Sort sites
				const waiting = [];
				const completed = [];
				const failed = [];
				let running = {};

				sites.forEach( ( site ) => {
					switch ( site.status ) {
					case 'Failed':
						failed.push( site );
						break;
					case 'Success':
						completed.push( site );
						break;
					case 'Waiting':
						waiting.push( site );
						break;
					default:
						running = site;
					}
				} );

				commit( 'setRunningSite', running );
				commit( 'setWaitingSites', waiting.reverse() );
				commit( 'setCompletedSites', completed );
				commit( 'setFailedSites', failed );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};

export default {
	state, getters, actions, mutations,
};
