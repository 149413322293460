<template>
  <v-app>
    <div>
      <div id="main">
        <transition
          :name="transition"
          mode="out-in"
        >
          <router-view />
        </transition>
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	computed: mapGetters( ['transition'] ),
};
</script>
