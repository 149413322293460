import axios from 'axios';

const state = {
	allDns: []
};
const getters = {
	allDns( s ) {
		return s.allDns;
	}
};

const mutations = {
	setAllDns( s, dns ) {
		s.allDns = dns;
	}
};

const actions = {
	async updateAllDNS( { commit, rootState } ) {
		try {
			const response = await axios.get( '/dns' );
			if ( response.pass === true && response.data.length !== 0 ) {
				const dns = response.data;
				commit( 'setAllDns', dns );
			}
			return response;
		} catch ( e ) {
			return { pass: false, data: rootState.axiosError };
		}
	},
};

export default {
	state, getters, actions, mutations,
};
