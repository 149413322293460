import { format } from 'date-fns';

export default {
	methods: {
		download( results, headers, type ) {
			try {
				// Get header and rows.
				const rows = [];
				rows.push( headers );
				results.forEach( ( r ) => {
					const entry = Object.values( r );

					// Ensure every double quote is escaped.
					entry.forEach( ( v, i, arr ) => {
						arr[i] = v.replace( /"/g, '""' );
					} );
					rows.push( entry );
				} );

				// Download CSV.
				const csvContent = `data:text/csv;charset=utf-8,"${rows.map( ( row ) => row.join( '","' ) ).join( '"\n"' )}`;
				const encodedUri = encodeURI( csvContent ).replace( /#/g, '%23' );
				const link = document.getElementById( 'download' );
				link.setAttribute( 'href', encodedUri );
				link.setAttribute( 'download', `Achilles Portal - ${type} - ${format( Date.now(), 'YYYY-MM-DD' )}.csv` );
				link.click();

				return {
					pass: true,
					data: `Successfully downloaded ${type} csv.`,
				};
			} catch ( e ) {
				return {
					pass: false,
					data: `Failed to download ${type} csv.`,
				};
			}
		},
	},
};
