<template>
  <div class="sidebar">
    <div class="sidebar__title">
      Achilles Portal
    </div>
    <div
      v-for="p in panels"
      :key="p.component"
      class="sidebar__tab"
      :class="{active : activePanel == p.component}"
      @click="$store.commit('setActivePanel', p.component)"
    >
      <i :class="p.icon" />{{ p.label }}
    </div>
    <ap-version />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			panels: [],
		};
	},
	computed: mapGetters( ['user', 'activePanel'] ),
	mounted() {
		let panels = [{
			label: 'Networks',
			component: 'Networks',
			icon: 'fas fa-sitemap',
		}, {
			label: 'Domains',
			component: 'Domains',
			icon: 'fas fa-sitemap',
		}, {
			label: 'DNS',
			component: 'DNS',
			icon: 'fas fa-server',
		}, {
			label: 'Generate Site',
			component: 'Generate',
			icon: 'fas fa-database',
		}, {
			label: 'Migrate Site',
			component: 'Migrate',
			icon: 'fas fa-database',
		}, {
			label: 'Query Site Options',
			component: 'Query',
			icon: 'fas fa-database',
		}, {
			label: 'SSL',
			component: 'SSL',
			icon: 'fas fa-certificate',
		}, {
			label: 'SSL Cloudflare',
			component: 'SSLCloudFlare',
			icon: 'fas fa-certificate',
		},
		{
			label: 'Slider Broadcast',
			component: 'SliderBroadcast',
			icon: 'fas fa-images',
		}];

		if ( ['Administrator', 'Superadmin'].includes( this.user.role ) ) {
			panels = [...panels, {
				label: 'Custom Query',
				component: 'CustomQuery',
				icon: 'fas fa-server',
			}, {
				label: 'Update Sites',
				component: 'Update',
				icon: 'fas fa-sync',
			}, {
				label: 'Users',
				component: 'Users',
				icon: 'fas fa-user',
			}, {
				label: 'Logging',
				component: 'Logging',
				icon: 'fas fa-clock',
			}, {
				label: 'Selenium',
				component: 'Selenium',
				icon: 'fas fa-wrench',
			}];
		}
		if ( this.user.role === 'Superadmin' ) {
			panels = [...panels, {
				label: 'Showroom Build & Price',
				component: 'BuildAndPrice',
				icon: 'fas fa-file-invoice-dollar',
			}];
		}

		this.panels = panels;
	},
};
</script>
<style lang="scss">
.sidebar {
    min-width: 220px;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px #d8d8d8;
    &__tab {
        cursor: pointer;
        padding: 10px 15px;
        transition: all 0.2s;
        color: #3a3a3a;
        i {
            padding-right: 10px;
            font-size: 18px;
        }
        &:hover{
            background: darken( #f7f7f7, 15% );
        }
        &.active {
            background: darken( #f7f7f7, 15% );
        }
    }
    &__title {
        background: #78be20;
        color: white;
        padding: 12px 15px;
        font-size: 18px;
    }
}
</style>
