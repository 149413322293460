<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Slides
    </div>

    <div
      class="ap__notification"
      :class="updated"
    />
    <div>
      {{ message }}
    </div>
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Oem</label>
        <v-select
          v-model="selectedOEM"
          :options="oemNames"
          label="OEM"
          @input="oemChanged(selectedOEM)"
        />
        <div class="ap__input__desc">
          Select the OEM.
        </div>
      </div>
    </div>

    <v-tabs
      v-model="tab"
      color="white"
    >
      <v-tab
        v-for="item of tabs"
        :key="item"
      >
        {{ item }}
      </v-tab>

      <v-tab-item>
        <div class="ap__input__wrap">
          <div class="ap__input">
            <label>From Buckets: </label>
            <v-select
              v-model="fromBucket"
              :options="allBucketNames"
              label="Language"
              @input="fromChanged"
            />
            <div class="ap__input__desc">
              Select source bucket.
            </div>
          </div>

          <div class="ap__input">
            <label>To Buckets: </label>
            <v-select
              v-model="toBucket"
              :options="toBucketNames"
              label="Language"
            />
            <div class="ap__input__desc">
              Select destination bucket.
            </div>
          </div>
          <div class="ap__wrap--btn">
            <ap-button
              :disabled="disableMigrateSlides"
              value="Migrate Slides"
              @clicked="displayMigrationDialog"
            />
          </div>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="ap__input">
          <label>Buckets</label>
          <v-select
            v-model="selectedBucket"
            :options="activeBucketNames"
            label="Bucket"
            @input="bucketChange"
          />
          <div class="ap__input__desc">
            Select the Bucket.
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="disableAddSlide"
            value="Add slides to bucket"
            @clicked="addNewSlideModal"
          />

          <ap-button
            :disabled="disableDisplaySlide"
            value="Display Bucket's Slides"
            @clicked="displayBucketSlides"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="ap__input">
          <label>Bucket:</label>
          <v-select
            v-model="bucketToBroadcast"
            :options="allBucketNames"
            label="domain"
          />
          <div class="ap__input__desc">
            Select bucket to broadcast to all domains that use it.
          </div>
        </div>
        <div class="ap__wrap--btn">
          <ap-button
            :disabled="disableDomainSlides"
            value="Preview Domain Slides"
            @clicked="displayDomainSlides"
          />
          <ap-button
            :disabled="disableDomainSlides"
            value="Push to WP"
            @clicked="pushToWPDialog"
          />
        </div>
      </v-tab-item>
    </v-tabs>

    <div>
      <v-data-table
        v-if="displaySlides"
        :headers="headers"
        :items="items"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-img
                :src="item.imageSrc"
                height="120"
                width="300"
              />
            </td>
            <td>
              <v-btn @click="editSlideModal(item)">
                Replace
              </v-btn>
              <v-btn @click="showDeleteDialog(item)">
                Delete
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <div>
      <v-tabs v-if="displayDomainImages">
        <v-tab
          v-for="item of domainSliderTabs"
          :key="item"
          class="text-white"
        >
          {{ item }}
        </v-tab>

        <v-tab-item
          v-for="data of domainSliderTables"
          :key="data.domain"
        >
          <v-data-table
            class="table-domain"
            :headers="domainHeaders"
            :items="data.domainItems"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr :style="{ backgroundColor: item.color }">
                <td>
                  <v-img
                    :src="item.imageSrc"
                    height="120"
                    width="300"
                  />
                </td>
                <td>
                  {{ item.bucket }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </div>

    <v-dialog
      v-model="modalOpen"
      max-width="700"
    >
      <v-card>
        <v-card-title>Image Modal</v-card-title>
        <v-card-text>
          <v-card class="image-card">
            <v-img :src="getImageSrc" />
          </v-card>
          <div class="ap__input__wrap">
            <input
              v-model="newImageUrl"
              type="text"
              @click="changeImageUrlEvent"
            >
            <ap-button
              :disabled="disableInsertSlide"
              value="Load image from URL."
              @clicked="openImagePicker"
            />
          </div>
          <div class="ap__input d-flex justify-space-between">
            <div>
              <label class="card-label">Start</label>
              <v-date-picker
                v-model="startDate"
                no-title
                :show-current="false"
                color="#78be20"
                :min="today"
              />
            </div>

            <div>
              <label class="card-label">Expiry</label>
              <v-date-picker
                v-model="expirytDate"
                no-title
                :show-current="false"
                color="#78be20"
                :min="todayAndStart"
              />
            </div>
          </div>
          <label>Image Alt tag:</label>
          <input
            v-model="imageAltTag"
            type="text"
            class="image-label"
          >
          <br>
          <label class="input-label">Image title:</label>
          <input
            v-model="imageTitle"
            type="text"
            class="image-label"
          >
          <br>
          <label class="input-label">Link URL:</label>
          <input
            v-model="linkUrl"
            type="text"
            class="image-label"
          >
          <v-checkbox
            v-model="openInNewTab"
            :disabled="openInNewTabDisable"
            hide-details
            color="#78be20"
            label="Open in new tab."
          />
          <label class="input-label">Disclaimer Title:</label>
          <input
            v-model="disclaimerTitle"
            type="text"
            class="image-label"
          >
          <br>
          <label class="input-label">Disclaimer Description:</label>
          <br>
          <textarea
            v-model="disclaimerDescription"
            rows="5"
            cols="75"
          />
        </v-card-text>
        <v-card-actions>
          <ap-button
            v-if="insertNewImage"
            :disabled="diableNewImageSelected"
            value="Insert slide into the bucket"
            @clicked="handleImageAdd"
          />
          <ap-button
            v-if="editCurrentImage"
            :disabled="disableReplaceSlide"
            value="Update Slide"
            @clicked="handleImageChange"
          />

          <v-btn
            color="primary"
            @click="closeModal"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showMigrationDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Migrate Slides:</v-card-title>
        <v-card-text>
          Are you sure you want to replace destination's slides?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="migrateSlides"
            >
              Yes, Migrate
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeMigrationDialog"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmationDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Delete/Deactivate Item</v-card-title>
        <v-card-text>
          Are you sure you want to delete this item?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="deleteSlide"
            >
              Yes, Delete
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showUpateDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Update Item</v-card-title>
        <v-card-text>
          Are you sure you want to update this item?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="updateSlide"
            >
              Yes, Update
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPushToWPDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Update Slides</v-card-title>
        <v-card-text>
          Are you sure you want to update slides of the domain?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="pushToWP"
            >
              Yes, Update
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { io } from 'socket.io-client';

export default {
	name: 'Sliders',
	data() {
		return {
			loading: false,
			search: '',
			message: '',
			selectedOEM: '',
			updated: '',
			bucketNames: [],
			selectedBucket: '',
			allBuckets: [],
			activeBucketNames: [],
			modalOpen: false,
			placeHolderImage: '/assets/images/placeholder.png',
			imageUrl: '',
			editImageUrl: '',
			newImageUrl: '',
			newImageSelected: false,
			headers: [
				{ text: 'Image', value: 'image' },
				{ text: 'Actions', value: 'actions' },
			],
			items: [
				{ imageSrc: '/assets/images/placeholder.png' },
			],
			domainHeaders: [
				{ text: 'Image', value: 'image' },
				{ text: 'Category', value: 'Category (bucket)' },
			],
			domainItems: [
				{ imageSrc: '/assets/images/placeholder.png' },
			],
			displaySlides: false,
			displayDomainImages: false,
			showConfirmationDialog: false,
			showMigrationDialog: false,
			toDeleteSlide: '',
			insertNewImage: false,
			editCurrentImage: false,
			fromBucket: '',
			toBucket: '',
			allBucketNames: [],
			oemNames: [],
			startDate: '',
			expirytDate: '',
			showUpateDialog: false,
			selectedDomain: {},
			bucketToBroadcast: '',
			bucketDomainNames: [],
			imageAltTag: '',
			linkUrl: '',
			today: new Date().toISOString().substr( 0, 10 ),
			catNumber: 1,
			oddRow: true,
			evenRow: false,
			selectedBucketIsDeactive: false,
			openInNewTab: false,
			disclaimerTitle: '',
			disclaimerDescription: '',
			imageTitle: '',
			showPushToWPDialog: false,
			imageUrlChange: false,
			tabs: [
				'Migrate Slides',
				'Update Bucket Slides',
				'Push Slides to WP',
			],
			tab: 0,
			domainSliderTabs: [],
			domainSliderTables: [],
		};
	},
	computed: {
		disableInsertSlide( ) {
			return ( !this.isValidUrl( this.newImageUrl ) );
		},
		diableNewImageSelected( ) {
			return !this.newImageSelected;
		},
		disableReplaceSlide( ) {
			if ( this.imageUrlChange ) {
				if ( !this.newImageSelected ) {
					return true;
				}
				return ( !this.isValidUrl( this.newImageUrl ) );
			}

			return false;
		},
		getImageSrc() {
			if ( this.newImageSelected ) {
				return this.newImageUrl;
			}

			if ( this.editCurrentImage ) {
				return this.editImageUrl;
			}
			return this.placeHolderImage;
		},
		disableMigrateSlides() {
			if ( this.fromBucket !== '' && this.toBucket !== '' ) {
				return false;
			}
			return true;
		},
		toBucketNames( ) {
			const toBucketNames = [];
			this.allBucketNames.forEach( ( bucket ) => {
				if ( bucket !== this.fromBucket ) {
					toBucketNames.push( bucket );
				}
			} );
			return toBucketNames;
		},
		todayAndStart( ) {
			if ( this.startDate !== '' ) {
				return this.startDate;
			}
			return this.today;
		},
		disableDomainSlides( ) {
			return ( this.selectedDomains.length === 0 );
		},
		categoryBackgroundColor( ) {
			if ( this.catNumber % 2 === 1 ) {
				return '#E4E4E4';
			}
			return '#FFFFFF';
		},
		disableAddSlide( ) {
			return ( this.selectedBucket === '' || this.selectedBucketIsDeactive );
		},
		disableDisplaySlide( ) {
			return ( this.selectedBucket === '' );
		},
		openInNewTabDisable( ) {
			return ( this.linkUrl === '' );
		},
		selectedDomains() {
			if ( this.bucketToBroadcast !== '' ) {
				return ( this.bucketDomainNames.filter( ( item ) => item.buckets.map( ( subitem ) => subitem.name ).includes( this.bucketToBroadcast ) && item.OEM.toLowerCase() === this.selectedOEM.toLowerCase() ) );
			}
			return [];
		},
	},
	watch: {
		tab( newValue, oldValue ) {
			if ( oldValue === 1 && newValue !== 1 ) {
				this.displaySlides = false;
			}
		},
		bucketToBroadcast() {
			this.displayDomainImages = false;
		},
	},
	mounted() {
		this.fetchBuckets();
		this.fetchOEMResults();
		this.connectToSocket();
		this.message = '';
	},
	activated() {
		this.fetchOEMResults();
		this.fetchBuckets();
	},
	deactivated() {
		this.selectedOEM = '';
		this.activeBucketNames = [];
		this.selectedBucket = '';
		this.displayBucketSlides();
		this.displaySlides = false;
		this.displayDomainImages = false;
		this.selectedDomain = {};
	},
	methods: {
		changeImageUrlEvent() {
			this.imageUrlChange = true;
		},
		async getOEMs() {
			let response;
			const result = [];
			try {
				this.loading = true;
				response = await axios.get( '/wpapi/oem/' );
			} catch ( e ) {
				this.updated = 'error';
				this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
				this.loading = false;
			}
			if ( response.pass === false ) {
				this.updated = 'error';
				this.message = 'Error happened, please try again later.';
				this.loading = false;
			} else {
				this.updated = 'success';
				this.message = 'Successfully loaded all OEMs.';
				this.loading = false;

				this.oemsList = response.data;
				response.data.forEach( ( oem ) => {
					result.push( oem.name );
				} );
			}
			return result;
		},

		bucketChange() {
			if ( this.selectedBucket.endsWith( ')' ) ) {
				this.message = 'Bucket is deactivated.';
				this.updated = 'error';
				this.bucketDomainNames = [];
				this.selectedDomain = {};
				this.bucketToBroadcast = '';
				this.items = [];
				this.selectedBucketIsDeactive = true;
				return;
			}
			this.selectedBucketIsDeactive = false;
			this.message = '';
			this.updated = '';
			this.allBuckets.filter( ( bucket ) => bucket.name === this.trimBucketName( this.selectedBucket ) );
			this.selectedDomain = {};
			this.bucketToBroadcast = '';
			this.displayBucketSlides();
		},
		isValidUrl( text ) {
			// Regular expression for a basic URL validation
			const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
			return urlPattern.test( text );
		},
		addNewSlideModal() {
			if ( this.selectedBucket.endsWith( ')' ) ) {
				this.message = 'Bucket is deactivated.';
				this.updated = 'error';
				return;
			}
			this.modalOpen = true;
			this.insertNewImage = true;
		},
		editSlideModal( item ) {
			this.modalOpen = true;
			this.editCurrentImage = true;
			this.editImageUrl = item.imageSrc;
			this.editCurrentImage = item.imageSrc;
			this.newImageUrl = item.imageSrc;
			this.expirytDate = item.expiryDate;
			this.startDate = item.startDate;
			this.imageAltTag = item.imageAltTag;
			this.linkUrl = item.linkUrl;
			this.openInNewTab = item.openInNewTab;
			this.disclaimerTitle = item.disclaimerTitle;
			this.disclaimerDescription = item.disclaimerDescription;
			this.imageTitle = item.imageTitle;
		},
		async fetchBuckets() {
			if ( !this.selectedOEM ) {
				return;
			}
			const result = await axios.get( `/wpapi/oem/${this.selectedOEM}/buckets/all` );
			this.allBuckets = result.data;
			this.activeBucketNames = this.allBuckets.map( ( bucket ) => {
				if ( bucket.status === 'active' ) {
					return bucket.name;
				}
				return `${bucket.name}(deactive)`;
			} );

			const allBucketsResult = await axios.get( `/wpapi/oem/${this.selectedOEM}/buckets/all/` );
			this.allBuckets = allBucketsResult.data;
			this.allBucketNames = this.allBuckets.map( ( bucket ) => {
				if ( bucket.status === 'active' ) {
					return bucket.name;
				}
				return `${bucket.name}(deactive)`;
			} );
		},
		closeModal() {
			this.imageUrl = '';
			this.modalOpen = false;
			this.editCurrentImage = false;
			this.insertNewImage = false;
			this.newImageSelected = false;
			this.newImageUrl = '';
			this.editImageUrl = '';
			this.expirytDate = '';
			this.startDate = '';
			this.imageAltTag = '';
			this.linkUrl = '';
			this.openInNewTab = false;
			this.disclaimerTitle = '';
			this.disclaimerDescription = '';
			this.imageTitle = '';
			this.showPushToWPDialog = false;
			this.imageUrlChange = false;
		},
		openImagePicker() {
			this.newImageSelected = true;
		},
		async handleImageAdd() {
			let message;
			const result = await axios.post( `/wpapi/buckets/${this.selectedBucket}/slides`, {
				slideUrl: this.newImageUrl,
				startDate: this.startDate,
				expiryDate: this.expirytDate,
				imageAltTag: this.imageAltTag,
				linkUrl: this.linkUrl,
				openInNewTab: this.openInNewTab,
				disclaimerTitle: this.disclaimerTitle,
				disclaimerDescription: this.disclaimerDescription,
				imageTitle: this.imageTitle,
			} );
			this.loading = false;
			if ( result.pass === true ) {
				if ( result.exists === false ) {
					message = 'Slider added successfully to the bucket.';
				} else {
					message = 'Slide has been already added.';
				}
			} else {
				message = 'Error happened when adding slides.';
			}
			this.newImageUrl = '';
			this.closeModal();
			this.displayBucketSlides( message );
			this.insertNewImage = false;
			const domains = await this.getDomainsOfOEM( this.selectedOEM );
			this.bucketDomainNames = domains.data;
		},
		async handleImageChange() {
			this.showUpateDialog = true;
		},
		async updateSlide() {
			let message;
			this.showUpateDialog = false;
			if ( this.selectedBucket.endsWith( ')' ) ) {
				this.message = 'Bucket is deactivated.';
				this.updated = 'error';
				return;
			}
			const result = await axios.post( `/wpapi/buckets/${this.selectedBucket}/slides/update/`, {
				newSlide: {
					slideUrl: this.newImageUrl,
					startDate: this.startDate,
					expiryDate: this.expirytDate,
					imgAltTag: this.imageAltTag,
					linkUrl: this.linkUrl,
					openInNewTab: this.openInNewTab,
					disclaimerTitle: this.disclaimerTitle,
					disclaimerDescription: this.disclaimerDescription,
					imageTitle: this.imageTitle,
				},
				oldSlide: this.editImageUrl,
			} );
			this.loading = false;
			if ( result.pass === true ) {
				if ( result.exists === false ) {
					message = 'Slider changed successfully.';
				} else {
					message = 'Slide has been already added.';
				}
			} else {
				message = 'Error happened when adding slides.';
			}
			this.newImageUrl = '';
			this.closeModal();
			this.displayBucketSlides( message );
			this.insertNewImage = false;
		},
		trimBucketName( bucketName ) {
			if ( bucketName.endsWith( ')' ) ) {
				const parts = bucketName.split( '(' );
				const substring = parts[0];

				return substring;
			}
			return bucketName;
		},
		async displayBucketSlides( message = '' ) {
			if ( this.tab !== 1 ) {
				return;
			}
			this.selectedDomain = {};
			this.displaySlides = true;
			this.displayDomainImages = false;
			this.items = [];
			let slides;
			if ( this.selectedBucket === '' ) {
				return;
			}
			this.loading = true;
			const selectedBucketName = this.trimBucketName( this.selectedBucket );
			try {
				slides = await axios.get( `/wpapi/buckets/${selectedBucketName}/slides` );
			} catch ( e ) {
				this.loading = false;
				this.message = 'Error happened, please try again later.';
				this.updated = 'error';
			}

			slides.data.slides.forEach( ( slide ) => {
				this.items.push(
					{
						imageSrc: slide.slideUrl,
						expiryDate: slide.expiryDate,
						startDate: slide.startDate,
						imageAltTag: slide.imgAltTag,
						imageTitle: slide.imageTitle,
						linkUrl: slide.linkUrl,
						openInNewTab: slide.openInNewTab,
						disclaimerTitle: slide.disclaimerTitle,
						disclaimerDescription: slide.disclaimerDescription,
					},
				);
			} );
			this.editCurrentImage = false;
			this.newImageUrl = '';
			this.expirytDate = '';
			this.startDate = '';
			this.loading = false;
			if ( message === '' ) {
				this.message = 'All slides of buckets successfully displayed';
			} else {
				this.message = message;
			}
			this.updated = '';
		},
		async displayDomainSlides() {
			const messages = [];
			this.displayDomainImages = false;
			this.domainSliderTabs = [];
			this.domainSliderTables = [];

			for ( const selectedDomain of this.selectedDomains ) {
				const domainItems = await this.getSlides( selectedDomain.domain );

				if ( domainItems.length === 0 ) {
					messages.push( `${selectedDomain.domain} has no slider to update.` );
					continue;
				}

				this.domainSliderTabs.push( selectedDomain.domain );

				this.domainSliderTables.push( {
					network: selectedDomain.network,
					domain: selectedDomain.domain,
					domainItems,
				} );
			}
			this.message = messages.join( ', ' );
			this.displayDomainImages = true;
		},
		async getSlides( domain ) {
			const domainItems = [];
			this.displaySlides = false;
			this.displayDomainImages = false;
			const domainSlides = await axios.get( `/wpapi/domains/${domain}/slides` );

			if ( domainSlides.pass === false ) {
				this.message = domainSlides.data;
				return [];
			}

			for ( const bucket of Object.keys( domainSlides ) ) {
				for ( const domainSlide of domainSlides[bucket] ) {
					domainItems.push( {
						imageSrc: domainSlide.slide.slideUrl,
						category: bucket,
						color: this.categoryBackgroundColor,
						bucket: `${bucket}(${domainSlide.bucketName})`,
						slide: domainSlide.slide,
					} );
				}
				this.catNumber += 1;
			}

			return domainItems;
		},
		closeDialog() {
			this.showConfirmationDialog = false;
			this.toDeleteSlide = '';
			this.showUpateDialog = false;
			this.showPushToWPDialog = false;
		},
		closeMigrationDialog() {
			this.showMigrationDialog = false;
		},
		displayMigrationDialog() {
			this.showMigrationDialog = true;
		},
		showDeleteDialog( item ) {
			this.toDeleteSlide = item;
			this.showConfirmationDialog = true;
		},
		showEditDialog( item ) {
			this.toEditSlide = item;
			this.showConfirmationDialog = true;
		},
		async deleteSlide() {
			let message;
			const slidename = this.toDeleteSlide.imageSrc;

			const response = await axios.post( `/wpapi/buckets/${this.selectedBucket}/deleteslides/`, {
				slidename,
			} );

			if ( response.pass !== true ) {
				this.loading = false;
				message = 'Error happened in updating.';
			} else {
				this.loading = false;
				message = 'Slide successfully deleted.';
			}

			this.displayBucketSlides( message );
			this.closeDialog();
		},
		async getDomainsOfOEM( selectedOEM ) {
			const domains = await axios.get( `/wpapi/oem/${selectedOEM}/domains` );
			return domains;
		},
		async oemChanged( selectedOEM ) {
			this.loading = true;

			const domains = await this.getDomainsOfOEM( selectedOEM );
			this.bucketDomainNames = domains.data;

			await this.fetchBuckets();
			this.loading = false;
			this.selectedBucket = '';
			this.displayBucketSlides();
			this.bucketToBroadcast = '';
		},
		async fetchOEMResults( message = undefined ) {
			this.getOEMs().then( ( result ) => {
				this.oemNames = result;
				this.loading = false;
				if ( undefined !== message ) {
					this.message = message;
				}
			} ).catch( ( err ) => {
				this.updated = 'error';
				this.message = 'Error happened, please try again later.';
				this.loading = false;
			} );
			this.selectedRegion = '';
			this.selectedMake = '';
		},
		async migrateSlides() {
			let message;
			this.loading = true;
			const fromBucketName = this.trimBucketName( this.fromBucket );
			const toBucketName = this.trimBucketName( this.toBucket );
			const migratedSlides = await axios.post( `/wpapi/buckets/${fromBucketName}/slides/migrate/${toBucketName}` );

			if ( migratedSlides.pass === true ) {
				this.loading = false;
				message = 'Successfully migrated the slides to destination bucket.';
			} else {
				this.loading = false;
				message = 'Error happened please try again later.';
			}
			this.fromBucket = '';
			this.toBucket = '';
			this.closeMigrationDialog();
			this.selectedBucket = toBucketName;
			this.displayBucketSlides( message );
		},
		fromChanged() {
			this.toBucket = '';
		},
		async pushToWP() {
			this.message = '';
			const messages = [];
			this.showPushToWPDialog = false;
			const data = [];

			for ( const selectedDomain of this.selectedDomains ) {
				const domainItems = await this.getSlides( selectedDomain.domain );

				if ( domainItems.length === 0 ) {
					messages.push( `${selectedDomain.domain} has no slider to update.` );
					continue;
				}

				data.push( {
					network: selectedDomain.network,
					domain: selectedDomain.domain,
					domainItems,
				} );
			}

			this.message = messages.join( ' ' );

			if ( data.length > 0 ) {
				this.message += ' Broadcasting sliders.';
				this.loading = true;
				this.socket.emit( 'update-oem-sliders', data );
			}
		},
		pushToWPDialog() {
			this.showPushToWPDialog = true;
		},
		async connectToSocket() {
			this.socket = io();
			this.socket.on( 'oem-sliders-response', ( response ) => {
				if ( response.pass === false ) {
					this.updated = 'error';
					this.message = response.errors.join( ', ' );
				} else {
					this.updated = 'success';
					this.message = `Successfully updated sliders for ${response.domains.join( ', ' )}.`;
				}
				this.generate = false;
				this.loading = false;
			} );
		},
	},
};
</script>

<style scoped>
.image-card {
  background-color: white;
  /*display: inline-block; !* Keeps the card size based on the image's aspect ratio *!*/
  padding: 8px; /* Adjust padding as needed */
  border-radius: 8px; /* Optional: Rounded corners */
}

.card-label {
  display: block;
  text-align: center;
}

.image-label {
  margin-top: 10px;
  width: 50%;
}

.input-label {
  width: 90px;
  display: inline-block;
}

</style>
