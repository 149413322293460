import Home from '../components/Home.vue';
import Login from '../components/Login.vue';

export default [
	{
		path: '/',
		name: 'home',
		components: {
			default: Home,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/redirect-me',
		redirect: {
			name: '/',
		},
	},
	{
		path: '*',
		redirect: '/',
	},
];
