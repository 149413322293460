import serialize from 'php-serialize';

export const recursiveReplace = ( oldVal, newVal, regex ) => {
	let val = oldVal;
	if ( val === null ) {
		return val;
	}
	switch ( val.constructor ) {
	case Array:
		for ( let i = 0; i < val.length; i += 1 ) {
			val[i] = recursiveReplace( val[i], newVal, regex );
		}
		break;

	case Object:
		for ( const key in val ) {
			val[key] = recursiveReplace( val[key], newVal, regex );
		}
		break;

	case String:
		val = val.replace( regex, newVal );
		break;

	default:
		break;
	}

	// Numbers or Booleans if not caught in switch.
	return val;
};

/**
 * Runs string replaces for all regexes passed on the value given.
 *
 * @param {*} value 	Value to have regex do the string replaces.
 * @param {Array} regex	Array of all regex to run on the value.
 *
 * @return				Returns the modified value.
 */
export const replaceVals = ( oldVal, newVal, regExp ) => {
	let val = oldVal;
	const isSerial = oldVal.match( /s:[^:]*:"[^;]*;/g );
	if ( isSerial !== null ) {
		try {
			val = serialize.unserialize( oldVal );
			val = recursiveReplace( val, newVal, regExp );
			val = serialize.serialize( val );
		} catch ( e ) {
			console.log( e );
			if ( oldVal.constructor === String ) {
				console.log( 'Could not unserialize string.' );
				val.replace( regExp, newVal );
			} else {
				console.log( 'Could not re-serialize object due to empty items.' );
				val = oldVal;
			}
		}
	} else {
		val = val.replace( regExp, newVal );
	}
	return val;
};
