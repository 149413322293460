<template>
  <div class="ap__item">
    <ap-loading :loading="loading" />
    <div class="ap__title--sub">
      Buckets
    </div>
    <div
      class="ap__notification"
      :class="updated"
    />{{ message }}
    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Oem</label>
        <v-select
          v-model="selectedOEM"
          :options="oemNames"
          label="OEM"
          @input="oemChanged(selectedOEM)"
        />
        <div class="ap__input__desc">
          Select the OEM.
        </div>
      </div>

      <div class="ap__input">
        <label>New OEM</label>
        <input
          v-model="newOEM"
          type="text"
        >
        <div class="ap__input__desc">
          New OEM value.
        </div>
      </div>

      <div class="ap__input">
        <label>Regions</label>
        <v-select
          v-model="selectedRegion"
          :options="displayableRegions"
          label="Region"
        />
        <div class="ap__input__desc">
          Select the region.
        </div>
      </div>

      <div class="ap__input">
        <label>New Region</label>
        <input
          v-model="newRegion"
          :disabled="disableTextBoxes"
          type="text"
        >
        <div class="ap__input__desc">
          New Region value.
        </div>
      </div>
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disableAddOEM"
        value="Add new OEM"
        @clicked="addOEM"
      />

      <ap-button
        :disabled="disableAddRegion"
        value="Add new region to OEM"
        @clicked="addRegion"
      />

      <ap-button
        :disabled="disableDeleteRegion"
        value="Delete region"
        @clicked="deleteRegionDialog"
      />
    </div>

    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Makes</label>
        <v-select
          v-model="selectedMake"
          :options="displayableMakes"
          label="Make"
        />
        <div class="ap__input__desc">
          Select the Make.
        </div>
      </div>

      <div class="ap__input">
        <label>New Make</label>
        <input
          v-model="newMake"
          :disabled="disableTextBoxes"
          type="text"
        >
        <div class="ap__input__desc">
          New Make value.
        </div>
      </div>

      <div class="ap__input">
        <label>Order Makes</label>
        <v-select
          v-model="makesOrder"
          :options="displayableMakes"
          label="Select domains to add"
          multiple
          @input="makesOrderChange"
        />
        <div class="ap__input__desc">
          Specify the order of the makes in the OEM.
        </div>
      </div>
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disableAddMake"
        value="Add new make to OEM"
        @clicked="addMake"
      />

      <ap-button
        :disabled="disableDeleteMake"
        value="Delete Make"
        @clicked="deleteMakeDialog"
      />

      <ap-button
        :disabled="makesOrderChanged"
        value="Re-Order Makes"
        @clicked="reOrderMakes"
      />
    </div>

    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Language: </label>
        <v-select
          v-model="selectedLanguage"
          :options="languages"
          label="Language"
        />
        <div class="ap__input__desc">
          Select the language.
        </div>
      </div>

      <div class="ap__input">
        <label>Size: </label>
        <v-select
          v-model="selectedSize"
          :options="sizes"
          label="size"
        />
        <div class="ap__input__desc">
          Select the size.
        </div>
      </div>

      <div class="ap__input">
        <label>Domains</label>
        <v-select
          v-model="selectedDomains"
          :options="domains"
          label="domain"
          multiple
        />
        <div class="ap__input__desc">
          Select the domain.
        </div>
      </div>
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disableAddBucket"
        value="Add new Bucket"
        @clicked="addBucket"
      />
    </div>

    <div class="ap__title--sub">
      Edit Buckets Domains
    </div>

    <div class="ap__input__wrap">
      <div class="ap__input">
        <label>Select Buckets: </label>
        <v-select
          v-model="selectedBucket"
          :options="bucketNames "
          label="Language"
          @input="bucketSelected"
        />
        <div class="ap__input__desc">
          Select the bucket.
        </div>
      </div>

      <div class="ap__input">
        <label>To Remove Domains</label>
        <v-select
          v-model="removedDomains"
          :options="bucketDomains"
          label="Select domains to remove."
          multiple
        />
        <div class="ap__input__desc">
          Selected domains will be removed from the bucket.
        </div>
      </div>

      <div class="ap__input">
        <label>Add Domains</label>
        <v-select
          v-model="newDomains"
          :options="notAddedDomian"
          label="Select domains to add"
          multiple
        />
        <div class="ap__input__desc">
          Selected domains will be added to the bucket.
        </div>
      </div>
    </div>

    <div class="ap__wrap--btn">
      <ap-button
        :disabled="disableUpdateBucketDomains"
        value="Update bucket domains"
        @clicked="updateBucketDomain"
      />
      <ap-button
        :disabled="disableDelete"
        value="Deactivate bucket"
        @clicked="deleteBucketDialog"
      />
    </div>

    <v-dialog
      v-model="showConfirmationDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Delete/Deactivate Item</v-card-title>
        <v-card-text>
          Are you sure you want to delete this item?
        </v-card-text>
        <div>
          <v-card-actions>
            <v-btn
              v-if="deleteRegionSelected"
              color="primary"
              @click="deleteRegion"
            >
              Yes, Delete
            </v-btn>
            <v-btn
              v-if="deleteMakeSelected"
              color="primary"
              @click="deleteMake"
            >
              Yes, Delete
            </v-btn>
            <v-btn
              v-if="deleteBucketSelected"
              color="primary"
              @click="deleteBucket"
            >
              Yes, Delete
            </v-btn>
            <v-btn
              color="secondary"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Buckets',
	data() {
		return {
			loading: false,
			search: '',
			message: '',
			updated: '',
			results: [],
			newOEM: '',
			selectedOEM: '',
			oemsList: [],
			oemNames: [],
			selectedRegion: '',
			oemRegions: [],
			newRegion: '',
			selectedMake: '',
			newMake: '',
			updatedRegion: '',
			selectedLanguage: '',
			languages: ['EN', 'FR'],
			selectedSize: '',
			sizes: ['1920', '1580'],
			domains: [],
			selectedDomains: '',
			buckets: [],
			bucketNames: [],
			selectedBucket: '',
			removedDomains: '',
			bucketDomains: [],
			notAddedDomian: [],
			delimiter: '-',
			newDomains: [],
			showConfirmationDialog: false,
			deleteRegionSelected: false,
			deleteMakeSelected: false,
			deleteBucketSelected: false,
			makesOrder: [],
			regionsOrder: [],
			regionsOrderChanged: true,
			makesOrderChanged: true,
		};
	},
	computed: {
		disableAddOEM() {
			return ( this.newOEM.length === 0 );
		},
		disableAddRegion( ) {
			return ( this.newRegion.length === 0 );
		},
		disableDeleteRegion( ) {
			return ( this.selectedRegion.length === 0 );
		},
		disableDeleteMake( ) {
			return ( this.selectedMake.length === 0 );
		},
		disableTextBoxes( ) {
			return ( this.selectedOEM.length === 0 );
		},
		diableUpdateRegionText( ) {
			return ( this.selectedOEM.length === 0 || this.selectedRegion.length === 0 );
		},
		disableAddMake( ) {
			return ( this.newMake.length === 0 );
		},
		disableDelete( ) {
			return ( this.selectedBucket.length === 0 );
		},
		displayableRegions( ) {
			if ( this.selectedOEM === '' ) {
				return [];
			}
			let selectedOEMDetails;
			this.oemsList.forEach( ( oem ) => {
				if ( oem.name === this.selectedOEM ) {
					selectedOEMDetails = oem;
				}
			} );
			this.regionsOrder = selectedOEMDetails.regions;
			return selectedOEMDetails.regions;
		},
		disableUpdateBucketDomains() {
			if ( this.newDomains.length === 0 && this.removedDomains.length === 0 ) {
				return true;
			}
			return false;
		},
		disableAddBucket( ) {
			let disable = false;
			if ( this.selectedOEM.length === 0 || this.selectedRegion.length === 0 ) {
				disable = true;
			}
			if ( this.selectedMake.length === 0 || this.selectedLanguage.length === 0 || this.selectedSize.length === 0 ) {
				disable = true;
			}
			return disable;
		},
		displayableMakes( ) {
			if ( this.selectedOEM === '' ) {
				return [];
			}
			let selectedOEMDetails;
			this.oemsList.forEach( ( oem ) => {
				if ( oem.name === this.selectedOEM ) {
					selectedOEMDetails = oem;
				}
			} );
			this.makesOrder = selectedOEMDetails.makes;
			return selectedOEMDetails.makes;
		},
	},
	mounted() {
		this.fetchOEMResults();
	},
	deactivated() {
		this.selectedOEM = '';
		this.regionsOrder = [];
		this.makesOrder = [];
		this.selectedLanguage = '';
		this.selectedBucket = '';
		this.removedDomains = '';
		this.newDomains = '';
		this.message = '';
		this.updated = '';
	},
	methods: {
		async fetchBuckets() {
			const result = await axios.get( `/wpapi/oem/${this.selectedOEM}/buckets/` );
			this.buckets = result.data;
			this.bucketNames = this.buckets.map( ( bucket ) => bucket.name );
		},
		async getOEMs() {
			let response;
			const result = [];
			try {
				this.loading = true;
				response = await axios.get( '/wpapi/oem/' );
			} catch ( e ) {
				this.updated = 'error';
				this.message = `${this.$store.state.axiosError} or restful routes not integrated.`;
				this.loading = false;
			}
			if ( response.pass === false ) {
				this.updated = 'error';
				this.message = 'Error happened, please try again later.';
				this.loading = false;
			} else {
				this.updated = 'success';
				this.message = 'Successfully loaded all OEMs.';
				this.loading = false;

				this.oemsList = response.data;
				response.data.forEach( ( oem ) => {
					result.push( oem.name );
				} );
			}
			return result;
		},
		async addOEM() {
			this.loading = true;

			const response = await axios.post( '/wpapi/oem/', {
				newOEM: this.newOEM,
			} );

			if ( response.exist === true ) {
				this.loading = false;
				this.message = 'OEM already inserted.';
			} else {
				this.fetchOEMResults( 'OEM added successfully.' );
			}
		},
		async addRegion() {
			this.loading = true;
			const response = await axios.post( '/wpapi/oem/regions', {
				oem: this.selectedOEM,
				region: this.newRegion,
			} );

			if ( response.exists === true ) {
				this.loading = false;
				this.updated = 'error';
				this.message = 'Region already exists.';
			} else {
				this.fetchOEMResults( 'Successfully added new Region.' );
			}
		},
		async deleteRegionDialog() {
			this.deleteRegionSelected = true;
			this.showConfirmationDialog = true;
		},
		async deleteMakeDialog() {
			this.deleteMakeSelected = true;
			this.showConfirmationDialog = true;
		},
		async deleteBucketDialog() {
			this.deleteBucketSelected = true;
			this.showConfirmationDialog = true;
		},
		closeDialog() {
			this.deleteRegionSelected = false;
			this.deleteMakeSelected = false;
			this.deleteBucketSelected = false;
			this.showConfirmationDialog = false;
		},
		async deleteRegion() {
			this.loading = true;

			const response = await axios.delete( `/wpapi/oem/${this.selectedOEM}/regions/${this.selectedRegion}` );
			this.selectedBucket = '';

			if ( response.pass !== true ) {
				this.loading = false;
				this.message = 'Error happened in updating.';
			} else {
				this.fetchOEMResults( 'The region successfully updated.' );
			}

			this.fetchBuckets();
			this.closeDialog();
		},
		async deleteMake() {
			this.loading = true;

			const response = await axios.delete( `/wpapi/oem/${this.selectedOEM}/makes/${this.selectedMake}` );
			this.selectedBucket = '';
			if ( response.pass !== true ) {
				this.loading = false;
				this.message = 'Error happened in updating.';
			} else {
				this.fetchOEMResults( 'The region successfully updated.' );
			}

			this.fetchBuckets();
			this.closeDialog();
		},
		async addMake() {
			this.loading = true;
			const response = await axios.post( '/wpapi/oem/makes', {
				oem: this.selectedOEM,
				make: this.newMake,
			} );

			if ( response.exists === true ) {
				this.loading = false;
				this.updated = 'error';
				this.message = 'Make already exists.';
			} else {
				this.fetchOEMResults( 'Make successfully added to the OEM.' );
			}
		},
		emptyAllFields() {
			this.newMake = '';
			this.newRegion = '';
			this.newOEM = '';
			this.newMake = '';
			this.newDomains = [];
			this.selectedDomains = [];

			this.selectedOEM = '';
			this.selectedRegion = '';
			this.selectedSize = '';
			this.selectedBucket = '';
			this.selectedMake = '';
			this.bucketDomains = [];
			this.notAddedDomian = [];
		},
		async addBucket() {
			this.loading = true;
			const tempSelectdOEM = this.selectedOEM;

			const response = await axios.post( '/wpapi/buckets', {
				domains: this.selectedDomains,
				oem: this.selectedOEM,
				region: this.selectedRegion,
				make: this.selectedMake,
				language: this.selectedLanguage,
				size: this.selectedSize,
			} );

			this.emptyAllFields();
			if ( response.pass ) {
				this.loading = false;
				if ( response.exists === true ) {
					this.message = 'Bucket already added.';
				} else {
					this.message = 'Successfully new bucket added.';
				}
			} else {
				this.loading = false;
				this.message = 'Error happened during adding new bucket.';
			}
			this.selectedOEM = tempSelectdOEM;
			await this.fetchBuckets();
		},
		async oemChanged( selectedOEM ) {
			this.loading = true;
			this.selectedRegion = '';
			this.selectedMake = '';
			this.domains = await this.getDomainsOfOEM( selectedOEM );
			await this.fetchBuckets();
			this.loading = false;
		},
		async getDomainsOfOEM( oem ) {
			const response = await axios.get( `/wpapi/oem/${oem}/domains` );
			const domains = response.data.map( ( domain ) => domain.domain );
			return domains;
		},
		async fetchOEMResults( message = undefined ) {
			this.getOEMs().then( ( result ) => {
				this.oemNames = result;
				this.loading = false;
				if ( undefined !== message ) {
					this.message = message;
				}
			} ).catch( ( err ) => {
				this.updated = 'error';
				this.message = 'Error happened, please try again later.';
				this.loading = false;
			} );
			this.selectedRegion = '';
			this.selectedMake = '';
			this.newRegion = '';
			this.newMake = '';
		},
		async getDomainsOfSelectedBucket() {
			const domains = await axios.get( `/wpapi/buckets/${this.selectedBucket}/domains` );
			return domains;
		},
		async bucketSelected() {
			const domains = await this.getDomainsOfSelectedBucket();
			this.bucketDomains = [];
			domains.data.forEach( ( domain ) => {
				this.bucketDomains.push( domain );
			} );

			const oemOfSelectedBucket = this.selectedBucket.split( this.delimiter )[0];
			const wholeDomains = await this.getDomainsOfOEM( oemOfSelectedBucket );

			const remainedDomains = wholeDomains.filter( ( domain ) => !this.bucketDomains.includes( domain ) );

			this.notAddedDomian = remainedDomains;
		},
		async updateBucketDomain() {
			this.loading = true;
			const result = await axios.post( `/wpapi/buckets/${this.selectedBucket}/domains`, {
				selectedBucket: this.selectedBucket,
				toRemove: this.removedDomains,
				toAdd: this.newDomains,
			} );
			this.selectedBucket = result.data.name;
			await this.bucketSelected();
			if ( result.pass === true ) {
				this.message = 'Domains of the selected bucket updated successfully.';
			} else {
				this.message = 'Error happened please try again later.';
			}
			this.loading = false;
			this.newDomains = [];
			this.removedDomains = [];
		},
		async deleteBucket() {
			this.loading = true;
			const tempSelectedOEM = this.selectedOEM;
			const response = await axios.delete( `/wpapi/oem/${this.selectedOEM}/buckets/${this.selectedBucket}` );
			this.selectedBucket = '';

			if ( response.pass !== true ) {
				this.loading = false;
				this.message = 'Error happened in updating.';
			} else {
				this.fetchOEMResults( 'The bucket successfully deactivated.' );
			}
			this.selectedOEM = tempSelectedOEM;
			this.fetchBuckets();
			this.closeDialog();
		},
		async reOrderMakes() {
			this.loading = true;
			if ( this.makesOrder.length !== this.displayableMakes.length ) {
				const alertMessage = 'All Makes should be selected.';
				this.updated = 'error';
				this.message = alertMessage;
				this.loading = false;
				return;
			}
			const updatedOEM = await axios.post( `/wpapi/oem/${this.selectedOEM}/makes/order`, {
				makes: this.makesOrder,
			} );

			this.makesOrderChanged = true;
			await this.getOEMs();
			if ( updatedOEM.pass === true ) {
				this.message = 'Makes successfully re ordered.';
				this.loading = false;
			} else {
				this.updated = 'error';
				this.message = 'Error happened, please try again.';
				this.loading = false;
			}
		},
		async reOrderRegions() {
			this.loading = true;
			if ( this.regionsOrder.length !== this.displayableRegions.length ) {
				const alertMessage = 'All Regions should be selected.';
				this.updated = 'error';
				this.message = alertMessage;
				this.loading = false;
				return;
			}
			const updatedOEM = await axios.post( `/wpapi/oem/${this.selectedOEM}/regions/order`, {
				regions: this.regionsOrder,
			} );

			this.regionsOrderChanged = true;
			await this.getOEMs();
			if ( updatedOEM.pass === true ) {
				this.message = 'Regions successfully re ordered.';
				this.loading = false;
			} else {
				this.updated = 'error';
				this.message = 'Error happened, please try again.';
				this.loading = false;
			}
		},
		regionsOrderChange() {
			this.regionsOrderChanged = false;
		},
		makesOrderChange() {
			this.makesOrderChanged = false;
		},
	},
};
</script>

<style scoped>

</style>
