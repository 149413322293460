<template>
  <div class="ap__section">
    <div class="ap__title">
      Updates
    </div>
    <v-tabs color="white">
      <v-tab
        v-for="tab in tabs"
        :key="tab.label"
        ripple
        @click="changeActiveTab(tab)"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="ap__tab__content">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import Crons from './updates/Crons.vue';
import Sites from './updates/Sites.vue';
import Syncing from './updates/Syncing.vue';
import LandingPage from './updates/LandingPage.vue';

export default {
	components: {
		Crons, Sites, Syncing, LandingPage,
	},
	data() {
		return {
			tabs: [{
				label: 'Handle Crons',
				component: 'Crons',
			}, {
				label: 'Achille Sites',
				component: 'Sites',
			}, {
				label: 'Syncing',
				component: 'Syncing',
			}, {
				label: 'Migrating',
				component: 'LandingPage',
			}],
			activeTab: 'Crons',
		};
	},
	methods: {
		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
	},
};
</script>
