<template>
  <div id="home">
    <convertus-sidebar />
    <div class="content-wrap">
      <div class="nav">
        <div class="nav__wrap">
          <div
            class="nav__item"
            @click="logout"
          >
            Logout
          </div>
        </div>
      </div>
      <keep-alive>
        <component :is="$store.state.activePanel" />
      </keep-alive>
      <a
        id="download"
        href="#"
        style="display:none;"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Networks from './panels/Networks.vue';
import Domains from './panels/Domains.vue';
import DNS from './panels/DNS.vue';
import CustomQuery from './panels/CustomQuery.vue';
import Users from './panels/Users.vue';
import Logging from './panels/Logging.vue';
import Generate from './panels/GenerateSite.vue';
import Migrate from './panels/Migrate.vue';
import Selenium from './panels/Selenium.vue';
import Update from './panels/Update.vue';
import Query from './panels/Query.vue';
import SSL from './panels/SSL.vue';
import SSLCloudFlare from './panels/SSLCloudFlare.vue';
import convertusSidebar from './Sidebar.vue';
import SliderBroadcast from './panels/Sliderbroadcast.vue';
import BuildAndPrice from './panels/BuildAndPrice.vue';


export default {
	components: {
		Networks, Domains, DNS, CustomQuery, Migrate, Selenium, Update, SSL, SSLCloudFlare, convertusSidebar, Users, Query, Logging, Generate, SliderBroadcast, BuildAndPrice,
	},
	computed: mapGetters( ['activePanel'] ),
	mounted() {
		this.$store.dispatch( 'updateGenerate' );
		this.$store.dispatch( 'updateALBs' );
		this.$store.dispatch( 'updateAllDNS' );
		this.$store.dispatch( 'updateGroups' );
		this.$store.dispatch( 'updateDomains' );
		this.$store.dispatch( 'updateNewDomains' );
		this.$store.dispatch( 'updateLogs' );
		this.$store.dispatch( 'updateCloudflareLogs' );
		this.$store.dispatch( 'updatePossibleOptions' );
		this.$store.dispatch( 'updateCustomizerParams' );
		this.$store.dispatch( 'updateIssues' );
		this.$store.dispatch( 'updateNodePingProfiles' );
	},
	methods: {
		async logout() {
			await axios.post( '/user/logout' );
			this.$router.push( 'login' );
		},
	},
};
</script>
<style lang="scss">
#home {
	display: flex;
}
.nav {
	padding: 15px;
    font-size: 14px;
    background: #f7f7f7;
	box-shadow: 1px 1px 1px #d8d8d8;
	&__wrap {
		max-width: 1140px;
		text-align: right;
	}
	&__item {
		display: inline-block;
		cursor: pointer;
		transition: all 0.2s;
		&:hover {
			opacity: 0.75;
		}
	}
}
.content-wrap {
	width: calc(100% - 200px);
}
</style>
