import axios from 'axios';

const state = {
	issues: [],
};
const getters = {
	allIssues( s ) {
		return s.issues;
	},
};
const mutations = {
	setAllIssues( s, issues ) {
		if (issues.error) {
			s.issues = [];
		} else {
			s.issues = issues;
		}
	},
};
const actions = {
	async updateIssues( { commit } ) {
		const response = await axios.get( '/achilles/issues' );
		commit( 'setAllIssues', response );
	},
};
export default {
	getters, mutations, actions, state,
};
