<template>
  <div class="ap__section">
    <div class="ap__title">
      Mass Query
    </div>
    <v-tabs color="white">
      <v-tab
        v-for="tab in tabs"
        :key="tab.label"
        ripple
        @click="changeActiveTab(tab)"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="ap__tab__content">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import Networks from './queries/Networks.vue';
import Sites from './queries/Sites.vue';
import CustomizerSites from './queries/CustomizerSites.vue';
import CustomizerNetworks from './queries/CustomizerNetworks.vue';
import CustomCSSSites from './queries/CustomCSSSites.vue';
import CustomCSSNetworks from './queries/CustomCSSNetworks.vue';

export default {
	components: {
		Networks, Sites, CustomizerSites, CustomizerNetworks, CustomCSSSites, CustomCSSNetworks,
	},
	data() {
		return {
			tabs: [{
				label: 'Sites',
				component: 'Sites',
			}, {
				label: 'Networks',
				component: 'Networks',
			}, {
				label: 'Customizer - Sites',
				component: 'CustomizerSites',
			}, {
				label: 'Customizer - Networks',
				component: 'CustomizerNetworks',
			}, {
				label: 'Custom CSS - Sites',
				component: 'CustomCSSSites',
			}, {
				label: 'Custom CSS - Networks',
				component: 'CustomCSSNetworks',
			}],
			activeTab: 'Sites',
		};
	},
	methods: {
		changeActiveTab( tab ) {
			this.activeTab = tab.component;
		},
	},
};
</script>
