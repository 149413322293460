<template>
  <div
    class="output"
    :class="{disabled : section.disabled}"
  >
    <div
      v-if="section.disabled"
      class="lds-dual-ring"
    />
    <div v-if="Object.keys(section.output).length === 0">
      <i>N/A ( Hit the Validate File(s) button above! )</i>
    </div>
    <div v-else>
      <div class="output-tabs">
        <!-- <div class="output-tab" :class="[{hidden : activeTable !== key}, {active : activeTable === key}]" v-for="( value, key ) in section.output"
                :key="key" v-if="value.values.length !== 0" @click="activeTable = key">
                {{key}}
            </div> -->
      </div>
      <div class="output-tables">
        <!-- <table v-for="( value, key ) in section.output" :key="key" :class="{hidden : activeTable !== key}" v-if="value.values.length !== 0">
                <tbody>
					<tr>
						<td v-for="header in value.headers" :key="header">{{ header }}</td>
					</tr>
                    <tr v-for="(row, index) in value.values" :key="index">
                        <td v-for="(col, i) in row" :key="i">{{col}}</td>
                    </tr>
                </tbody>
            </table> -->
        <div
          v-if="Object.keys(section.results).length !== 0"
          class="overview"
        >
          <div class="ap__label">
            Overview
          </div>
          <div
            v-for="(value, key) in section.results"
            :key="key"
            class="overview"
          >
            <b>{{ key }}</b> : {{ value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: {
		section: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			activeTable: '',
		};
	},
	watch: {
		// Set first tab every time this data changes ( ajax calls )
		section() {
			for ( const key in this.section.output ) {
				if ( this.section.output[key].values.length !== 0 ) {
					this.activeTable = key;
					break;
				}
			}
		},
	},
	methods: {},
};
</script>
<style lang="scss">
.output {
	min-height: 320px;
	position: relative;
	.hidden {
		display: none;
	}
	.output-tabs {
		margin-left: -5px;
		.output-tab {
			display: inline-block;
			padding: 5px;
			margin: 5px;
			background: #78be20;
			color: white;
			border: 1px solid darken(#78be20, 15%);
			transition: all 0.2s;
			cursor: pointer;
			&:hover {
				background: darken(#78be20, 15%);
			}
			&.active {
				background: green;
			}
		}
	}
	.output-tables {
		margin-left: -5px;
		table {
			margin: 5px;
			width: 100%;
			max-width: 1024px;
			tr:first-child {
				font-weight: bold;
				padding: 5px;
			}
		}
	}
}
</style>
